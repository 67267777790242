<div class="p-1">
    <app-card [customHeader]="true" [options]="false">
        <div class="app-card-header">
            <h5>Estado de carga
                <button *ngIf="showButtons" type="button" class="close basic-close ml-2" style="top: -5%;" data-dismiss="modal"
                    aria-label="Close" (click)="closeNotifier()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <button *ngIf="showButtons" type="button" class="close basic-close ml-5" style="top: -5%;" data-dismiss="modal"
                    aria-label="Close" (click)="hideNotifier()">
                    <span aria-hidden="true">&minus;</span>
                </button>
            </h5>
        </div>
        <div *ngIf="!notifierHidden">
            <div *ngFor="let pendingUpload of pendingUploads" class="mb-4">
                <p>{{pendingUpload.title}}</p>
                <ngb-progressbar type="success" [value]="pendingUpload.progress" [striped]="true" [animated]="true">
                </ngb-progressbar>
            </div>
            <app-card [customHeader]="true" [options]="false">
                <div class="app-card-header">
                    <h5>Errores encontrados</h5>
                </div>
                <div *ngIf="uploadErrors?.length > 0" style="overflow: overlay; max-height: 40%;">
                    <div *ngFor="let error of uploadErrors" class="mb-2">
                        <span class="font-weight-bold">
                            {{error.msg}}
                        </span>
                        <hr>
                    </div>
                </div>
            </app-card>
        </div>
    </app-card>
</div>