<div class="h-list-body">
    <div class="msg-user-chat scroll-div message-content">
        <perfect-scrollbar ngClass="datta-scroll" [style.max-width]="'100%'" [style.max-height]="'100%'"
            [usePSClass]="'datta'" [disabled]="null" [autoPropagation]="true">
            <div class="main-friend-chat">
                <div class="message-content-body" *ngIf="selectedMessage != null">
                    <span class="message-from-label"> Mensaje de {{selectedMessage.sender.name}} </span>
                    <div class="row from-information-spacer">
                        <div class="col-1">
                            <img class="media-object img-radius" src="{{selectedMessage.sender.avatar.href}}"
                                alt="usuario" width="50" height="50">
                        </div>
                        <div class="col">
                            <div class="vertical-center">
                                <h6 class="chat-header">{{selectedMessage.sender.name}}
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div class="row to-spacer">
                        <div class="col-12">
                            <span class="message-subject-label">{{selectedMessage.subject}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <small class="d-block email-date-label">{{selectedMessage.created_at}}</small>
                        </div>
                    </div>
                    <div class="row to-spacer">
                        <div class="col-12">
                            <span class="message-content-text">
                                <div [innerHtml]="selectedMessage.content"></div>
                            </span>
                        </div>
                    </div>
                    <div class="row to-spacer attachment-container"
                        *ngFor="let attachment of selectedMessage.attachments" (click)="downloadAttachment(attachment)">
                        <div class="col-12">
                            <img src="../../assets/images/messages/attach-outline.svg" alt="attachment" />
                            <span class="message-content-text">{{attachment.file_name || 'archivo'}}</span>
                            <i class="text-c-blue f-20 float-right fas fa-eye"
                                (click)="openFilePreview(attachment)"></i>
                        </div>
                    </div>
                </div>
            </div>
        </perfect-scrollbar>
    </div>
</div>