import { Injectable } from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  hasAccess?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  name?: string,
  grantType?: string;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const navigationItems = [
  {
    id: 'Menu',
    title: 'Menu',
    type: 'group',
    icon: 'icon-navigation',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        url: 'dashboard',
        classes: 'nav-item',
        icon: 'feather icon-pie-chart',
        hasAccess: false,
        name: 'DASHBOARD',
        grantType: 'MODULE',
      },
      {
        id: 'calendario',
        title: 'Calendario',
        type: 'item',
        url: 'calendar',
        classes: 'nav-item',
        icon: 'feather icon-calendar',
        hasAccess: false,
        name: 'CALENDAR',
        grantType: 'MODULE',
      },
      {
        id: 'contactos',
        title: 'Contactos',
        type: 'item',
        url: 'contacts',
        classes: 'nav-item',
        icon: 'feather icon-users',
        name: 'CONTACTS',
        grantType: 'MODULE',
      },
      {
        id: 'menu-content',
        title: 'Contenido',
        type: 'collapse',
        icon: 'icon-box',
        name: 'CONTENT',
        grantType: 'MODULE',
        children: [
          {
            id: 'mis-cursos',
            title: 'Mis Cursos',
            type: 'item',
            url: 'content/my-courses',
            classes: 'nav-item',
            icon: 'feather icon-star',
            name: 'MI_CONTENIDO',
            grantType: 'ACTION'
          },
          {
            id: 'all-courses',
            title: 'Todos los cursos',
            type: 'item',
            url: 'content/all-courses',
            classes: 'nav-item',
            icon: 'feather icon-book',
            name: 'TODO_CONTENIDO',
            grantType: 'ACTION'
          }
        ]
      },
      {
        id: 'mensaje',
        title: 'Mensajes',
        type: 'item',
        url: 'messages',
        classes: 'nav-item',
        icon: 'feather icon-message-square',
        name: 'MESSAGES',
        grantType: 'MODULE',
      },
      {
        id: 'ciclos',
        title: 'Ciclos Escolares',
        type: 'item',
        url: 'school-cycles',
        classes: 'nav-item',
        icon: 'feather icon-refresh-cw',
        name: 'SCHOLAR_CYCLES',
        grantType: 'MODULE',
      },
      {
        id: 'alertas-de-contenido',
        title: 'Alertas de Contenido',
        type: 'item',
        url: 'content-alerts',
        classes: 'nav-item',
        icon: 'feather icon-alert-triangle',
        name: 'ALERTS',
        grantType: 'MODULE',
      },
      {
        id: 'configuraciones',
        title: 'Configuraciones',
        type: 'item',
        url: 'settings',
        classes: 'nav-item',
        icon: 'feather icon-settings',
        name: 'SETTINGS',
        grantType: 'MODULE',
      },
      {
        id: 'security-roles',
        title: 'Roles de Seguridad',
        type: 'item',
        url: 'security-roles',
        classes: 'nav-item',
        icon: 'feather icon-user-plus',
        name: 'SECURITY',
        grantType: 'MODULE',
      },
      {
        id: 'settingsSchool',
        title: 'Configuracion Escuela',
        type: 'item',
        url: 'settings-school',
        classes: 'nav-item',
        icon: 'feather icon-edit',
        name: 'SETTINGS',
        grantType: 'MODULE',
      },
      {
        id: 'form-content',
        title: 'Cursos',
        type: 'group',
        url: 'form-content',
        classes: 'nav-item',
        icon: 'feather icon-settings',
        hidden: true
      },
      {
        id: 'form-content-activity',
        title: 'Actividades',
        type: 'group',
        url: 'form-content/activity',
        classes: 'nav-item',
        icon: 'feather icon-settings',
        hidden: true
      }
    ]
  }
];

@Injectable()
export class NavigationItem {
  get() {
    return navigationItems;
  }
}
