<ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last">
  <div class="page-header" *ngIf="last">
    <div class="page-block">
      <div class="row align-items-title">
        <div class="ml-3">
          <div class="page-header-title">
            <h5 class="m-b-10">
              {{ breadcrumb.label }}
            </h5>
          </div>
          <ul class="breadcrumb">
            <li class="breadcrumb-item">
              <a [routerLink]="['/menu/calendar']" *ngIf="type === 'theme2'"><i class="feather icon-home"></i></a>
              <a [routerLink]="['/menu/calendar']" *ngIf="type === 'theme1'">Home</a>
            </li>
            <li class="breadcrumb-item" *ngFor="let breadcrumb of breadcrumbs;">
              <a [title]="breadcrumb.title ? breadcrumb.title : ''" (click)="navigate(breadcrumb)">{{ breadcrumb.label }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-container>