import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../theme/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { DataTablesModule } from "angular-datatables";
import { CustomFormsModule } from "ngx-custom-validators";
import { NgbButtonLabel, NgbButtonsModule, NgbDropdownModule, NgbModule, NgbRadioGroup, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ArchwizardModule } from "angular-archwizard";
import { ToastyModule } from "ng2-toasty";
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SchoolCyclesComponent } from './school-cycles.component';
import { SchoolCyclesRoutingModule } from "./school-cycles-routing.module";
import { SchoolCycleManagerComponent } from './school-cycle-manager/school-cycle-manager.component';
import { StatusChangeConfirmationModalComponent } from './status-change-confirmation-modal/status-change-confirmation-modal.component';

@NgModule({
  imports: [
    CommonModule,
    SchoolCyclesRoutingModule,
    SharedModule,
    FormsModule,
    DataTablesModule,
    CustomFormsModule,
    ArchwizardModule,
    ToastyModule,
    NgbModule,
    NgbButtonsModule,
    MatDialogModule,
    NgbTooltipModule,
    NgbDropdownModule,
  ],
  declarations: [
    SchoolCyclesComponent,
    SchoolCycleManagerComponent,
    StatusChangeConfirmationModalComponent,
  ],
  providers: [
    SchoolCyclesComponent,
    MatDialog,
    {
      provide: MAT_DIALOG_DATA,
      useValue: {}
    },
    {
      provide: MatDialogRef,
      useValue: {}
    },
    NgbRadioGroup,
    NgbButtonLabel,
  ],
})
export class SchoolCyclesModule { }