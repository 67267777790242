<div>
    <div [ngSwitch]="selectedResource.link.type">
        <div *ngSwitchCase="'IMAGE'" class="d-flex flex-column align-items-center justify-content-center">
            <div id="pdfApp" style="width: 100%; height: 73vh;"></div>
        </div>
        <div *ngSwitchCase="'PDF'" class="d-flex flex-column align-items-center justify-content-center">
            <div id="pdfApp" style="width: 100%; height: 73vh;"></div>
        </div>
        <div *ngSwitchCase="'VIDEO'">
            <div id="video-div"
                class="angled-img pull-left d-flex flex-column align-items-center justify-content-center">
                <video #video1 class="img" muted autoplay controls
                    [ngClass]="{'landscape': landscapeView, 'portrait': !landscapeView}">
                    <source [src]="selectedResource.link.href" type='video/mp4' />
                </video>
            </div>
        </div>
    </div>
</div>