import { Injectable } from '@angular/core';
import { Session } from "../model/Session";
import { Router } from "@angular/router";
import { UserInfo } from "../client/data-contracts";

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private localStorageService;
  private currentSession: Session = null;

  constructor(public router: Router) {
    this.localStorageService = localStorage;
    this.currentSession = this.loadSessionData();
  }

  get(name: string) {
    this.localStorageService.getItem(name);
  }

  set(name: string, data) {
    this.localStorageService.setItem(name, data);
  }

  setCurrentSession(session: Session): void {
    this.currentSession = session;
    this.localStorageService.setItem('currentUser', JSON.stringify(session));
  }

  loadSessionData(): Session {
    var sessionStr = this.localStorageService.getItem('currentUser');
    return (sessionStr) ? <Session>JSON.parse(sessionStr) : null;
  }

  getCurrentSession(): Session {
    return this.currentSession;
  }

  removeCurrentSession(): void {
    this.localStorageService.removeItem('currentUser');
    this.currentSession = null;
  }

  getCurrentUser(): UserInfo {
    var session: Session = this.getCurrentSession();
    return (session && session.user) ? session.user : null;
  };

  isAuthenticated(): boolean {
    return (this.getCurrentToken() != null) ? true : false;
  };

  getCurrentToken(): string {
    var session = this.getCurrentSession();
    return (session && session.token) ? session.token : null;
  };

  updateLoggedUser(updatedUser) {
    const sessionWithAvatarUpdate = {
      ...this.currentSession,
    };
    sessionWithAvatarUpdate.user.avatar = updatedUser.avatar;
    this.setCurrentSession(sessionWithAvatarUpdate);
  }

  logout(): void {
    this.removeCurrentSession();
    this.router.navigate(['']);
  }
}
