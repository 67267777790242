<ul class="navbar-nav">
  <li>
    <div class="dropdown" ngbDropdown placement="auto">
      <a ngbDropdownToggle href="javascript:" data-toggle="dropdown" style="font-size: 1.5rem;">
        <i class="icon feather icon-bell"></i>
        <span *ngIf="unreadFeeds > 0" class="badge m-r-5 badge-pill badge-success count-notif">
          {{unreadFeeds}}
        </span>
      </a>
      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right notification">
        <div class="noti-head">
          <h6 class="d-inline-block m-b-0">Notificaciones</h6>
          <div class="float-right">
            <!-- <a href="javascript:" class="m-r-5">Marcar todas le&iacute;das</a> -->
            <!-- <a href="javascript:">Limpiar notificaciones</a> -->
          </div>
        </div>
        <ul class="noti-body" style="max-height: 30rem; overflow: overlay;">
          <!-- <li class="n-title">
            <p class="m-b-0">NEW</p>
          </li> -->
          <li ngbDropdownItem class="notification pointer" *ngFor="let feed of contactFeeds | slice: 0 : feedsLimit ">
            <div class="media" (click)="goToFeed(feed)">
              <img [src]="feed.publisher.icon.href" class="img-radius" alt="Notificatin-Owner"
                style="width:40px; height: 40px;">
              <div class="media-body" style="overflow: hidden;">
                <div class="d-flex flex-row justify-content-between">
                  <p><strong>{{feed.publisher.name}}</strong></p>
                  <div class="d-flex flex-row align-items-center mr-2">
                    <span class="n-time text-muted"><i
                        class="icon feather icon-clock mr-1"></i>{{feed.timeSinceCreated}}</span>
                    <div *ngIf="!feed.read" class="not-read-circle m-0 ml-2 btn-glow-info btn-info"></div>
                  </div>
                </div>
                <p>{{feed.title}}</p>
                <p class="ml-2" [innerHTML]="feed.description"></p>
              </div>
            </div>
          </li>
          <li class="d-flex justify-content-center notification" *ngIf="contactFeeds?.length === 0">
            <div class="media">
              <div class="media-body">
                <p><strong>No hay notificaciones</strong></p>
              </div>
            </div>
          </li>
          <!-- <li class="n-title">
            <p class="m-b-0">EARLIER</p>
          </li> -->
        </ul>
        <div class="noti-footer" *ngIf="contactFeeds?.length > 0 && feedsLimit < contactFeeds?.length">
          <a href="javascript:" (click)="showMoreFeeds()">Mostrar m&aacute;s</a>
        </div>
        <div class="noti-footer" *ngIf="!(contactFeeds?.length > 0 && feedsLimit < contactFeeds?.length)">
          <a href="javascript:">No hay m&aacute;s notificaciones</a>
        </div>
      </div>
    </div>
  </li>
  <!-- <li (click)="this.visibleUserList = !this.visibleUserList;"><a href="javascript:" class="displayChatbox"><i class="icon feather icon-mail"></i></a></li> -->
  <li>
    <div class="dropdown drp-user mt-2" ngbDropdown placement='auto'>
      <a href="javascript:" ngbDropdownToggle data-toggle="dropdown" style="font-size: 1.5rem;" class="mr-1">
        <i class="icon feather icon-settings"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-right profile-notification" ngbDropdownMenu>
        <div class="pro-head">
          <img src="{{avatar}}" class="img-radius" alt="User-Profile-Image" style="width:40px; height: 40px;">
          <span>{{name}}</span>
          <a href="javascript:" class="dud-logout" title="Logout" (click)="logout()">
            <i class="feather icon-log-out"></i>
          </a>
        </div>
        <ul class="pro-body">
          <li><a href="javascript:" class="dropdown-item" (click)="openProfileModal()"><i class="feather icon-user"></i>
              Mi Perfil</a></li>
        </ul>
      </div>
    </div>
  </li>
</ul>
<section class="header-user-list" *ngIf="visibleUserList && !this.dattaConfig['rtl-layout']"
  [ngClass]="{'open': visibleUserList}" [@slideInOutLeft]>
  <app-chat-user-list (onChatToggle)="onChatToggle($event)"
    (onChatCollapse)="this.visibleUserList = !this.visibleUserList; this.chatMessage = false;"></app-chat-user-list>
</section>

<section class="header-chat" *ngIf="chatMessage && !this.dattaConfig['rtl-layout']" [ngClass]="{'open': chatMessage}"
  [@slideInOutLeft]>
  <app-chat-msg (onChatToggle)="this.chatMessage = !this.chatMessage" [friendId]="friendId"></app-chat-msg>
</section>

<section class="header-user-list" *ngIf="visibleUserList && this.dattaConfig['rtl-layout']"
  [ngClass]="{'open': visibleUserList}" [@slideInOutRight]>
  <app-chat-user-list (onChatToggle)="onChatToggle($event)"
    (onChatCollapse)="this.visibleUserList = !this.visibleUserList; this.chatMessage = false;"></app-chat-user-list>
</section>

<section class="header-chat" *ngIf="chatMessage && this.dattaConfig['rtl-layout']" [ngClass]="{'open': chatMessage}"
  [@slideInOutRight]>
  <app-chat-msg (onChatToggle)="this.chatMessage = !this.chatMessage" [friendId]="friendId"></app-chat-msg>
</section>
<ng2-toasty [position]="position"></ng2-toasty>