import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContentAlertResponse } from 'src/api/client/data-contracts';
import { Service } from 'src/api/service/Service';
import { StorageService } from 'src/api/service/storage.service';
import { AlertInfoComponent } from './alert-info/alert-info.component';
import { ContentInfoComponent } from './content-info/content-info.component';

@Component({
  selector: 'app-content-alerts',
  templateUrl: './content-alerts.component.html',
  styleUrls: ['./content-alerts.component.scss']
})
export class ContentAlertsComponent implements OnInit {

  position = 'top-center';
  contentAlerts: ContentAlertResponse[] = [];
  _page: number = 1;

  constructor(
    private storageService: StorageService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getContentAlerts();
  }

  getContentAlerts() {
    const service = new Service(this.storageService);
    this._page = 1;
    service.getAllContentAlerts(this.page).then(contentAlerts => {
      this.contentAlerts = contentAlerts;
    }).catch(err => {
      console.log("Err:", err);
    });
  }

  update(alert: ContentAlertResponse) {
    this.dialog.open(AlertInfoComponent, {
      data: alert,
      height: '90%',
      width: '90%'
    }).afterClosed().subscribe(updated => {
      updated && this.getContentAlerts();
    });
  }

  openContactModal(alert: ContentAlertResponse) {
    this.dialog.open(ContentInfoComponent, {
      data: alert,
      height: '90%',
      width: '90%'
    });
  }

  get page() {
    return this._page;
  }

  set page(page: number) {
    this._page = page;
  }

}