<mat-card class="example-card">
  <div class="icon-buttons">
    <button class="btn btn-primary btn-glow-primary btn-sm" *ngIf="(type === 'CLASSROOM') && grantsService.validateByNameAndType('EDITAR', 'ACTION')" (click)="update()">
      <i class="fas fa-pencil-alt f-10 mr-0"></i>
    </button>
    <button class="btn btn-danger btn-glow-danger btn-sm" *ngIf="(type === 'CLASSROOM') && grantsService.validateByNameAndType('ELIMINAR', 'ACTION')" (click)="deleteSpace()">
      <i class="fas fa-trash-alt f-10 mr-0"></i>
    </button>
  </div>

  <mat-card-content (click)="selected()">
    <img mat-card-image [src]="avatar" [alt]="name" *ngIf="avatar;else iconOpt">
    <ng-template #iconOpt>
      <mat-icon style="margin: 49px auto;font-size: 50px;width: 49px;height: 50px;display: block;">
        {{ getIcon(type) }}
      </mat-icon>
    </ng-template>

    <mat-card-title>{{ name }}</mat-card-title>
    <p>
      {{ description }}
    </p>
  </mat-card-content>
  <mat-card-actions align="center">
    <button class="btn btn-info btn-sm" *ngIf="(type !== 'GRADE') && grantsService.validateByNameAndType('ASOCIAR_ESTUD', 'ACTION')"
      (click)="assign('STUDENT')">
      Asociar Alumnos <i class="fas fa-user-edit f-10 mr-0"></i>
    </button>
    <button class="btn btn-info btn-sm" *ngIf="grantsService.validateByNameAndType('ASOC_STAFF', 'ACTION')"
      (click)="assign('STAFF,OWNER')">
      Asociar Catedráticos <i class="fas fa-chalkboard-teacher f-10 mr-0"></i>
    </button>
  </mat-card-actions>
  <mat-card-footer>
    <p>{{ branch }}
    </p>
  </mat-card-footer>
</mat-card>
