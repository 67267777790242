import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Activity, MediaAsset } from 'src/api/client/data-contracts';
import { Service } from 'src/api/service/Service';
import { StorageService } from 'src/api/service/storage.service';
import { MediaPreviewModalComponent } from 'src/app/theme/shared/components/media-preview-modal/media-preview-modal.component';
import {FILES_NOT_PREVEW} from '../../../../api/utils/Constants';

@Component({
  selector: 'app-activity-preview',
  templateUrl: './activity-preview.component.html',
  styleUrls: ['./activity-preview.component.scss']
})
export class ActivityPreviewComponent implements OnInit {

  @Input() selectedActivity: Activity;

  contentCommentsLog: any = [];
  activityAttachments: MediaAsset[] = [];
  attachment: MediaAsset;

  @ViewChild('modalConfirmationDownload', { static: true }) modalConfirmationDownload: ElementRef;

  constructor(
    private storageService: StorageService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.renderContentCommentsLog();
    this.activityAttachments = this.selectedActivity.resources;
  }

  async renderContentCommentsLog() {
    if (this.selectedActivity.comments?.length > 0) {
      const service = new Service(this.storageService);
      this.contentCommentsLog = await Promise.all(
        this.selectedActivity.comments.map(async comment => {
          const owner = await service.getOneUser(comment.created_by as string);
          return {
            ...comment,
            //@ts-ignore
            created_by: owner.full_name,
          }
        })
      );
      this.contentCommentsLog.reverse();
    } else {
      this.contentCommentsLog = [];
    }
    return;
  }

  openFilePreview(attachment: MediaAsset) {
    if( FILES_NOT_PREVEW[attachment.type]){
      this.attachment = attachment;
      // @ts-ignore
      this.modalConfirmationDownload.show();
    }else {
      this.dialog.open(MediaPreviewModalComponent, {
        data: attachment,
        height: '85%',
        width: '75%',
        autoFocus: false,
      }).afterClosed().subscribe(() => {
        //TODO If action needed after closed
      });
    }
  }

  downloadAttachment(attachment: MediaAsset, close: boolean) {
    if ( close ){
      // @ts-ignore
      this.modalConfirmationDownload.hide();
    }
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', attachment.href);
    link.setAttribute('download', `${attachment.file_name == null ? 'archivo' : attachment.file_name}`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

}
