<div class="row">
  <div class="col-md-4" *ngIf="!myCourses">
    <div class="card content pl-3">
      <div class="col-xl-12" *ngIf="items.length !== 0">
        <div class="expand-collapse-buttons">
          <button mat-icon-button class="button-icon expand-collpase" (click)="toggleTree()"
            [title]="isExpanded ? 'Contraer' : 'Expandir'">
            <mat-icon>{{ isExpanded ? 'unfold_less' : 'unfold_more' }} </mat-icon>
          </button>
          <button mat-icon-button class="button-icon expand-collpase" (click)="goToRoot()" title="Seleccionar Raíz">
            <mat-icon>last_page</mat-icon>
          </button>
        </div>
        <div class="form-group ml-3 contentTree" >

          <app-tree-eduk2
                  [dataSource]="dataSource"
                  [treeControl]="treeControl"
                  (onSelectedChangeObsOut)="onSelectedChangeObs($event)"
                  (openClassOut)="openClass($event)"></app-tree-eduk2>
         </div>
      </div>
      <ngx-spinner bdColor = "rgba(44,130,201,0.8)" size = "medium" color = "#ffff" type = "timer" [fullScreen] = "false">
        <p style="color: white" > Cargando... </p>
      </ngx-spinner>
    </div>
  </div>
  <div [ngClass]="{'col-md-8': !myCourses, 'col-md-12': myCourses}">
      <div class="row" *ngIf="myCourses">
        <div class="col" style="margin-left: 42px">
          <h5>{{ titleSelected }}</h5>
        </div>
      </div>
      <div class="row">
        <div class="col" *ngIf="!myCourses">
          <div class="d-flex flex-row justify-content-between">
            <div>
              <h5>{{ titleSelected }}</h5>
            </div>
            <div>
              <div class="add-edit-buttons">
                <button class="btn btn-primary add-btn" (click)="openContentUploadModal(idSelected)"
                  *ngIf="grantsService.validateByNameAndType('CARGA_MASIVA', 'ACTION')">
                  Carga Masiva <i class="fas fa-plus f-14 mr-0 ml-2"></i>
                </button>
                <button class="btn btn-primary" (click)="createUpdate('create', null)"
                  *ngIf="grantsService.validateByNameAndType('CREAR', 'ACTION')">
                  Agregar Espacio <i class="fas fa-plus f-14 mr-0"></i>
                </button>
                <button class="btn btn-primary"
                  *ngIf="(idSelected !== null && idSelected !== '') && grantsService.validateByNameAndType('EDITAR', 'ACTION')"
                  (click)="createUpdate('update', null)">
                  Editar Actual <i class="fas fa-pencil-alt f-14 mr-0"></i>
                </button>
                <button class="btn btn-primary"
                  *ngIf="(idSelected !== null && idSelected !== '') && grantsService.validateByNameAndType('ELIMINAR', 'ACTION')"
                  (click)="deleteSpaceShow(idSelected)">
                  Eliminar Actual <i class="fas fa-trash-alt f-14 mr-0"></i>
                </button>
                <button class="btn btn-primary"
                  *ngIf="(idSelected !== null && idSelected !== '') && (typeSelected==='GRADE' || typeSelected==='SECTION')"
                   (click)="showScores(idSelected)">
                  Notas <i class="mr-1 fas fa-file-alt"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    <br>
    <ngb-tabset *ngIf="spaces && spaces.length > 0">
      <ngb-tab [title]="spaceTabTitle">
        <ng-template ngbTabContent>
          <div class="spaces-container">
            <ng-container *ngFor="let space of spaces; trackBy: trackSpace">
              <app-card-space class="space-card" [ngClass]="{'my-courses': myCourses}" [name]="space.name"
                [description]="space.description" [avatar]="space.avatar" [type]="space.type" [branch]="space.branch?space.branch.name:''" [myCourses]="myCourses"
                (updateSpace)="createUpdate('update-card', space)" (asign)="assign(space, $event)"
                (select)="openOrSelect(space)" (delete)="deleteSpaceShow(space.id)">
              </app-card-space>
            </ng-container>
          </div>
        </ng-template>
      </ngb-tab>
      <ngb-tab *ngIf="(spaceAllInfo && (spaceAllInfo.type === 'GRADE' || spaceAllInfo.type === 'SECTION'))" title="Alumnos">
        <ng-template ngbTabContent>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Nombres y Apellidos</th>
                  <th>Correo Electr&oacute;nico</th>
                  <th>Fecha Nacimiento</th>
                  <th>Sexo</th>
                  <th *ngIf="grantsService.validateByNameAndType('ASOCIAR_ESTUD', 'ACTION')">Acci&oacute;n</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let student of sectionStudents | slice: (studentsPage-1) * 10 : studentsPage * 10"
                  class="table-row">
                  <td>
                    <h6 class="m-0">
                      <img class="rounded-circle m-r-10" style="width:40px; height: 40px;"
                        src="{{student.avatar.href ==null ? 'assets/images/user/avatar-1.jpg' : student.avatar.href }}"
                        alt="activity-user">
                      {{student.names}} {{student.last_names}}
                    </h6>
                  </td>
                  <td>
                    <h6 class="m-0">{{student.email}}</h6>
                  </td>
                  <td>
                    <h6 class="m-0">{{student.birth_date}}</h6>
                  </td>
                  <td>
                    <h6 *ngIf="student.gender?.toLowerCase()==='male'" class="m-0">Masculino</h6>
                    <h6 *ngIf="student.gender?.toLowerCase()==='female'" class="m-0">Femenino</h6>
                    <h6 *ngIf="student.gender?.toLowerCase()==='n/a'" class="m-0">N/A</h6>
                  </td>
                  <td class="pt-1 pb-1 pr-0 pl-0 text-center">
                    <ng-template #scoresActions>
                      <span class="mt-2 dropdown-button dropdown-item btn-glow-warning btn-warning pointer pl-2 pr-2"
                            (click)="studentScores(student)">
                        <i class="mr-1 fas fa-eye" [placement]="'top'" ngbTooltip="Ver" (click)="studentScores(student)"></i>
                        Ver notas
                      </span>
                      <span class="dropdown-button dropdown-item btn-glow-info btn-info pointer pl-2 pr-2 mt-2"
                            (click)="selectAsociation(student)">
                        <i class="mr-1 fas fa-users" [placement]="'top'" ngbTooltip="Ver padres asociados" (click)="selectAsociation(student)"></i>
                        Ver padres asociados
                      </span>
                    </ng-template>
                    <button type="button" class="btn drp-icon btn-outline-primary" [placement]="'bottom'"
                      [ngbPopover]="scoresActions">
                      <i class="fas fa-cog"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex justify-content-center">
              <h5 *ngIf="sectionStudents?.length === 0">No hay estudiantes asignados.</h5>
            </div>
            <div class="d-flex justify-content-center p-2 mb-3">
              <ngb-pagination *ngIf="sectionStudents?.length > 0" [collectionSize]="sectionStudents.length"
                [(page)]="studentsPage" [pageSize]="10">
              </ngb-pagination>
            </div>
          </div>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>
  </div>
  <app-ui-modal #modalCreate [dialogClass]="'modal-lg'">
    <div class="app-modal-header">
      <h5 class="modal-title">{{ createUpdateTitle }} Espacio {{titleSelected === '' ? "" : " - " + titleSelected}}</h5>
      <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
        (click)="modalCreate.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="app-modal-body">
      <div class="col-sm-12 ">
        <app-form-space #modalForm id="modalForm" [parentId]="idSelected" [parentNode]="parentNodeSelected" (spacesChanged)="addSpaceChanged($event)"
          (close)="closeModalForm()"></app-form-space>
      </div>
    </div>
  </app-ui-modal>
  <app-ui-modal #modalDelete [dialogClass]="'modal-lg'">
    <div class="app-modal-header">
      <h5 class="modal-title">Eliminar Espacio</h5>
      <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
        (click)="modalDelete.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
      <p>¿Está seguro que desea eliminar el espacio?</p>
    </div>
    <div class="app-modal-footer">
      <button type="button" class="btn btn-secondary" data-dismiss="modal"
        (click)="modalDelete.hide(); spaceToDelete = null">Cancelar</button>
      <button type="button" class="btn btn-primary" (click)="deleteSpace()">Aceptar</button>
    </div>
  </app-ui-modal>
</div>
<ng2-toasty [position]="position"></ng2-toasty>
