<header>
  <div *ngIf="alertNotifications && alertNotifications.length" class="alerts-div">
    <div *ngFor="let alert of alertNotifications" class="{{alert.colorClass}}">
      <span class="text-white" [ngClass]="{ 'pointer': alert.url }" (click)="goToAlert(alert.url)">
        <i  [ngClass]="alert.faIcon ? alert.faIcon : 'fas fa-exclamation-triangle'"
          aria-hidden="true"></i>
        <span *ngIf="alert.boldText" class="font-weight-bold">
          {{ alert.boldText }}
        </span>
        {{ alert.text }}
      </span>
      <button type="button" class="text-white close basic-close mr-3" style="top: -5%;" data-dismiss="modal" aria-label="Close"
        (click)="deleteAlert(alert)">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="m-header" [style.display]="this.headerStyle">
    <a class="mobile-menu" id="mobile-collapse1" href="javascript:"
      (click)="this.onNavCollapsedMob.emit();"><span></span></a>
    <a [routerLink]="['/dashboard/default']" class="b-brand">
      <div class="b-bg">
        <img width="55px" src="assets/images/icon-app.svg">
      </div>
      <span class="b-title">Edukcare</span>
    </a>
  </div>
  <a class="mobile-menu" [ngClass]="{'on' : this.menuClass}" id="mobile-header" href="javascript:"
    (click)="toggleMobOption()"><i class="feather icon-more-horizontal"></i></a>
  <div class="collapse navbar-collapse" [style.display]="this.collapseStyle">
    <app-nav-left class="mr-auto" [style.display]="this.headerStyle"></app-nav-left>
    <app-nav-right class="ml-auto"></app-nav-right>
  </div>
</header>