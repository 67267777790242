import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastOptions, ToastyService } from 'ng2-toasty';
import { ContactResponse, ContactsRequest, Phone, UserInfo } from 'src/api/client/data-contracts';
import { Service } from 'src/api/service/Service';
import { StorageService } from 'src/api/service/storage.service';
import countriesArr from '../../../../../../../assets/data/countries-phone-codes';

const permittedFiles = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png',
];

@Component({
  selector: 'app-profile-manager',
  templateUrl: './profile-manager.component.html',
  styleUrls: ['./profile-manager.component.scss']
})
export class ProfileManagerComponent implements OnInit {

  form: FormGroup;
  newPassType = 'password';
  currentPassType = 'password';
  countries = countriesArr;
  newPassVisible = false;
  currentPassVisible = false;
  avatarUpdated: boolean = false;
  profilePicture: any;
  loggedContact: ContactResponse;
  userPhones: Phone[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public user: UserInfo,
    private dialogRef: MatDialogRef<ProfileManagerComponent>,
    private storageService: StorageService,
    private toastyService: ToastyService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.obtainContactInfo();
  }

  obtainContactInfo() {
    const service = new Service(this.storageService);
    service.getOneContact(this.user.contact_id).then((user: ContactResponse) => {
      if (!this.avatarUpdated) {
        this.loggedContact = user;
        this.profilePicture = user.avatar;
        this.userPhones = user.phones || [];
        this.patchForm();
      } else {
        this.loggedContact = user;
        this.profilePicture = user.avatar;
        this.avatarUpdated = false;
      }
    });
  }

  buildForm() {
    this.form = this.fb.group({
      firstNames: ['', [Validators.required]],
      lastNames: ['', [Validators.required]],
      birthDate: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      newPass: [null],
      phone_code: ['+(502)'],
      phoneType: ['CELLPHONE'],
      phoneNumber: [''],
    });
  }

  patchForm() {
    let birthDate;
    if (this.loggedContact.birth_date.indexOf('-') < 0){
      const splitDate = this.loggedContact.birth_date ? this.loggedContact.birth_date.split('/') : [];
      birthDate = splitDate.length > 0 ? `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}` : '';
    }else{
      birthDate = this.loggedContact.birth_date;
    }

    this.form.get('firstNames').patchValue(this.loggedContact.names || '');
    this.form.get('lastNames').patchValue(this.loggedContact.last_names || '');
    this.form.get('birthDate').patchValue(birthDate);
    this.form.get('gender').patchValue(this.loggedContact.gender || '');
    this.form.markAsTouched();
  }

  updateUser() {
    this.toastyService.clearAll();
    var toastOptions: ToastOptions = {
      title: "Espere",
      msg: "Actualizando usuario.",
      timeout: 5000,
      theme: "default"
    };
    if (this.form.invalid) {
      this.toastyService.clearAll();
      var toastOptions: ToastOptions = {
        title: "Error",
        msg: 'Por favor llenar todos los campos.',
        timeout: 5000,
        theme: "default"
      };
      this.toastyService.error(toastOptions);
      return;
    }
    this.toastyService.wait(toastOptions);
    const service = new Service(this.storageService);
    const updatedContact = this.renderContactToSave();
    service.updateContact(updatedContact).then(() => {
      this.toastyService.clearAll();
      var toastOptions: ToastOptions = {
        title: "Exito",
        msg: "Usuario actualizado exitosamente.",
        timeout: 3000,
        theme: "default"
      };
      this.toastyService.info(toastOptions);
      this.obtainContactInfo();
    }).catch(reason => {
      this.toastyService.clearAll();
      var toastOptions: ToastOptions = {
        title: "Error",
        msg: reason.message,
        timeout: 5000,
        theme: "default"
      };
      this.toastyService.error(toastOptions);
    });
  }

  renderContactToSave() {
    const newPass = this.form.get('newPass').value;
    const birthDate = this.form.get('birthDate').value;
    const splitDate = birthDate.split('-') || [];
    const contact: ContactsRequest = {
      ...this.loggedContact,
      phones: this.userPhones,
      birth_date: `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`,
      names: this.form.get('firstNames').value,
      last_names: this.form.get('lastNames').value,
      gender: this.form.get('gender').value,
      password: newPass ? this.form.get('newPass').value : '',
      password_confirm: newPass ? this.form.get('newPass').value : '',
    }
    return contact;
  }

  changeProfilePicture($event) {
    this.toastyService.clearAll();
    var toastOptions: ToastOptions = {
      title: "Espere",
      msg: "Guardando...",
      timeout: 5000,
      theme: "default"
    };
    const file = $event.target.files[0];
    if (permittedFiles.includes(file.type)) {
      const service = new Service(this.storageService);
      service.updateContactAvatar(this.loggedContact.id, file)
        .then(() => {
          this.toastyService.clearAll();
          var toastOptions: ToastOptions = {
            title: "Exito",
            msg: "Avatar actualizado exitosamente.",
            timeout: 3000,
            theme: "default"
          };
          this.toastyService.info(toastOptions);
          this.avatarUpdated = true;
          this.obtainContactInfo();
        })
        .catch(reason => {
          this.toastyService.clearAll();
          var toastOptions: ToastOptions = {
            title: "Error",
            msg: reason.message,
            timeout: 5000,
            theme: "default"
          };
          this.toastyService.error(toastOptions);
        });
    } else {
      this.toastyService.clearAll();
      var toastOptions: ToastOptions = {
        title: "Error",
        msg: 'Tipo de archivo no permitido.',
        timeout: 5000,
        theme: "default"
      };
      this.toastyService.error(toastOptions);
    }
  }

  addPhone() {
    const phoneAsString = this.form.get('phoneNumber').value.toString();
    if (Number(phoneAsString)) {
      const phoneType = this.form.get('phoneType');
      const phoneNumber = this.form.get('phoneNumber');
      if (phoneType.valid && phoneNumber.valid) {
        this.userPhones.push({
          number: phoneNumber.value,
          type: phoneType.value,
        });
        phoneType.reset();
        phoneNumber.reset();
      }
    }else{
      const toastOptions: ToastOptions = {
        title: "Numero invalido.",
        msg: "Ingresar un numero valido!",
        timeout: 3000,
        theme: "default"
      };
      this.toastyService.error(toastOptions);
    }
  }

  deletePhone(p: Phone) {
    this.userPhones.splice(this.userPhones.findIndex(item => item.type === p.number), 1);
  }

  toggleVisibility() {
    if (this.newPassVisible) {
      this.newPassType = 'password';
      this.newPassVisible = false;
    } else {
      this.newPassType = 'text';
      this.newPassVisible = true;
    }
  }

  closeModal(updated: boolean = false) {
    this.dialogRef.close(updated);
  }

}
