import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ToastOptions, ToastyService } from 'ng2-toasty';
import { combineLatest } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { CycleUpdateRequest, SpaceRequest, UnitsInput } from 'src/api/client/data-contracts';
import { DatesService } from 'src/api/service/dates.service';
import { Service } from 'src/api/service/Service';
import { StorageService } from 'src/api/service/storage.service';
import { GrantsService } from 'src/app/services/grants.service';

@Component({
  selector: 'app-school-cycle-manager',
  templateUrl: './school-cycle-manager.component.html',
  styleUrls: ['./school-cycle-manager.component.scss']
})
export class SchoolCycleManagerComponent implements OnInit, OnDestroy {

  form: FormGroup;
  title: string = '';
  mode: 'update' | 'create' = 'create';
  cycleRequest: CycleUpdateRequest;
  maxDate: string;
  loading: boolean = false;
  saveButtonText: 'Solo Guardar' | 'Solo Actualizar' = 'Solo Guardar';
  saveAndActivateButtonText: 'Guardar y Activar' | 'Actualizar y Activar' = 'Guardar y Activar';
  minStartDate: NgbDateStruct;
  minEndDate: NgbDateStruct;
  cycleUnits: UnitsInput[] = [];
  units: FormArray;
  prevUnitsAmount: number = 1;
  allBranchs: SpaceRequest[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public selectedCycle: CycleUpdateRequest,
    private dialogRef: MatDialogRef<SchoolCycleManagerComponent>,
    private storageService: StorageService,
    private toastyService: ToastyService,
    private fb: FormBuilder,
    private datesService: DatesService,
    public grantsService: GrantsService,
  ) { }

  ngOnInit(): void {
    const service = new Service(this.storageService);
    const today = new Date();
    this.minStartDate = this.datesService.getDateAfterDayDifference({
      day: today.getDate(),
      month: today.getMonth() + 1,
      year: today.getFullYear(),
    }, { days: 550 }, 'down');
    if (this.selectedCycle && this.selectedCycle.id !== null) {
      service.getCycleUnits(this.selectedCycle.id)
        .then((units: UnitsInput[]) => {
          this.mode = 'update';
          this.cycleUnits = units.sort((a, b) => a.unit - b.unit);
          this.buildForm();
          this.title = 'Actualizar Ciclo';
        });
    } else {
      this.buildForm();
      this.mode = 'create';
      this.title = 'Crear Ciclo';
    }
    service.getAllSpacesBySpaceObs(null, false)
      .pipe(
        filter((space) => space.type === 'INSTITUTION'),
      ).subscribe((spaces) => {
        this.allBranchs.push(spaces);
      });
  }

  ngOnDestroy(): void {
    this.form.reset();
  }

  buildForm() {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      start_at: ['', [Validators.required]],
      end_at: ['', [Validators.required]],
      minimum_score: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
      cycle_type: ['', [Validators.required]],
      units_amount: [this.cycleUnits?.length || 1, [Validators.required]],
      score_handling: ['', [Validators.required]],
      branch: [[''], [Validators.required]],
      units: this.fb.array(this.unitsToArrayGroup(this.cycleUnits || [])),
    });
    if (this.cycleUnits && this.cycleUnits.length) {
      this.prevUnitsAmount = this.cycleUnits.length;
      this.units = this.form.get('units') as FormArray;
    } else {
      this.addUnit();
    }
    if (this.mode === 'update') this.initializeUpdate();
    this.form.get('start_at').valueChanges.subscribe(startDate => {
      this.minEndDate = {
        day: 15,
        month: startDate.month + 1,
        year: startDate.year,
      };
    });
    this.form.get('end_at').valueChanges.subscribe(endDate => {
      this.units.markAllAsTouched();
    });
    this.form.get('units_amount').valueChanges
      .pipe(debounceTime(1000))
      .subscribe(amount => {
        if (amount > this.prevUnitsAmount) {
          for (let i = this.prevUnitsAmount; i < amount; i++) {
            this.addUnit();
          }
        } else if (amount < this.prevUnitsAmount) {
          for (let i = this.prevUnitsAmount; i > amount; i--) {
            this.deleteUnit(i - 1);
          }
        }
        this.prevUnitsAmount = amount;
      });

    combineLatest([
      this.form.get('start_at').valueChanges,
      this.form.get('end_at').valueChanges,
    ])
      .pipe(filter(([startAt, endAt]) => startAt && endAt))
      .subscribe(([startAt, endAt]) => {
        if (this.mode === 'create') {
          const startConverted = new Date(startAt.year, (startAt.month - 1), startAt.day).toDateString();
          const endConverted = new Date(endAt.year, (endAt.month - 1), endAt.day).toDateString();
          const datesDiff = this.datesService.getDifferenceBetweenDates(startConverted, endConverted);
          let nextUnitMonth: number = startAt.month;
          let nextUnitYear: number = startAt.year;
          if (datesDiff.months < 6) {
            this.form.get('units_amount').patchValue(datesDiff.months);
            setTimeout(() => {
              for (let i = 0; i < datesDiff.months; i++) {
                nextUnitMonth++;
                if (nextUnitMonth === 13) {
                  nextUnitMonth = 1;
                  nextUnitYear++;
                }
                this.getUnitOption(i).get('end_date').patchValue({ day: 15, month: nextUnitMonth, year: nextUnitYear })
              }
            }, 1250);
          } else {
            const unitsAmount = datesDiff.months / 2;
            this.form.get('units_amount').patchValue(unitsAmount);
            setTimeout(() => {
              for (let i = 0; i < unitsAmount; i++) {
                nextUnitMonth = nextUnitMonth + 2;
                if (nextUnitMonth > 12) {
                  nextUnitMonth = 1;
                  nextUnitYear++;
                }
                this.getUnitOption(i).get('end_date').patchValue({ day: 15, month: nextUnitMonth, year: nextUnitYear })
              }
            }, 1250);
          }
        }
      });
  }

  unitsToArrayGroup = units => units.map(u => this.toUnitGroup(u));

  toUnitGroup(unit) {
    const endDateArr = unit?.end_date?.split('-');
    let endDate;
    if (Boolean(endDateArr && endDateArr.length)) {
      endDate = {
        day: +endDateArr[0],
        month: +endDateArr[1],
        year: +endDateArr[2].split(' ')[0],
      };
    }
    return this.fb.group({
      id: [unit.id || null],
      unit: [unit.unit || null],
      end_date: [endDate || '', [Validators.required]],
    });
  }

  initializeUpdate() {
    this.saveButtonText = 'Solo Actualizar';
    this.saveAndActivateButtonText = 'Actualizar y Activar';
    this.cycleRequest = { ...this.selectedCycle };
    const startDateArr = this.cycleRequest?.start_at?.split('-');
    const endDateArr = this.cycleRequest?.end_at?.split('-');
    let startDate;
    let endDate;
    if (Boolean(startDateArr?.length && endDateArr?.length)) {
      startDate = {
        day: +startDateArr[0],
        month: +startDateArr[1],
        year: +startDateArr[2].split(' ')[0],
      };
      endDate = {
        day: +endDateArr[0],
        month: +endDateArr[1],
        year: +endDateArr[2].split(' ')[0],
      };
    }
    this.form.get('name').patchValue(this.cycleRequest.name || '');
    this.form.get('cycle_type').patchValue(this.cycleRequest.cycle_type || '');
    this.form.get('minimum_score').patchValue(this.cycleRequest.minimum_score || 0);
    this.form.get('start_at').patchValue(startDate || '');
    this.form.get('end_at').patchValue(endDate || '');
    this.form.get('score_handling').patchValue(this.cycleRequest.score_handling.toUpperCase() || '');
    this.form.get('units_amount').patchValue(this.cycleUnits && this.cycleUnits?.length ? this.cycleUnits?.length : 1);
    this.form.get('branch').patchValue(this.selectedCycle?.branch?.id || '');
    this.form.get('units').patchValue(this.unitsToArrayGroup(this.cycleUnits || []));
  }


  submitCycleAndActivate() {
    const service = new Service(this.storageService);
    this.loading = true;
    this.toastyService.clearAll();
    var toastOptions: ToastOptions = {
      title: "Espere",
      msg: "Guardando registro",
      timeout: 5000,
      theme: "default"
    };
    this.toastyService.wait(toastOptions);
    let cycleToSubmit: CycleUpdateRequest = {
      ...this.renderCycle(),
      enabled: true,
      units: this.cycleUnits,
    };
    let msg;
    if (this.mode === 'update') {
      msg = "Ciclo actualizado y activado exitosamente.";
    } else {
      msg = "Ciclo creado y activado exitosamente.";
    }
    switch (this.mode) {
      case 'create':
        service.createAndActivateCycle(cycleToSubmit)
          .then(() => {
            this.toastyService.clearAll();
            var toastOptions: ToastOptions = {
              title: "Exito",
              msg,
              timeout: 3000,
              theme: "default"
            };
            this.toastyService.info(toastOptions);
            this.closeModal(true);
          })
          .catch(reason => {
            this.toastyService.clearAll();
            var toastOptions: ToastOptions = {
              title: "Error",
              msg: reason.message,
              timeout: 5000,
              theme: "default"
            };
            this.toastyService.error(toastOptions);
            this.loading = false;
          });
        break;
      case 'update':
        service.updateCycle(cycleToSubmit.id, cycleToSubmit)
          .then(() => {
            this.toastyService.clearAll();
            var toastOptions: ToastOptions = {
              title: "Exito",
              msg,
              timeout: 3000,
              theme: "default"
            };
            this.toastyService.info(toastOptions);
            this.closeModal(true);
          })
          .catch(reason => {
            this.toastyService.clearAll();
            var toastOptions: ToastOptions = {
              title: "Error",
              msg: reason.message,
              timeout: 5000,
              theme: "default"
            };
            this.toastyService.error(toastOptions);
            this.loading = false;
          });
        break;
      default:
        break;
    }
  }

  submitCycle() {
    this.loading = true;
    this.toastyService.clearAll();
    var toastOptions: ToastOptions = {
      title: "Espere",
      msg: "Guardando registro",
      timeout: 5000,
      theme: "default"
    };
    this.toastyService.wait(toastOptions);
    const service = new Service(this.storageService);
    let cycleToSubmit: CycleUpdateRequest = {
      enabled: false,
      ...this.renderCycle(),
    };
    let msg;
    if (this.mode === 'update') {
      msg = "Ciclo actualizado exitosamente.";
    } else {
      msg = "Ciclo creado exitosamente.";
    }
    switch (this.mode) {
      case 'create':
        service.createCycle(cycleToSubmit)
          .then(() => {
            this.toastyService.clearAll();
            var toastOptions: ToastOptions = {
              title: "Exito",
              msg,
              timeout: 3000,
              theme: "default"
            };
            this.toastyService.info(toastOptions);
            this.closeModal(true);
          })
          .catch(reason => {
            this.toastyService.clearAll();
            var toastOptions: ToastOptions = {
              title: "Error",
              msg: reason.message,
              timeout: 5000,
              theme: "default"
            };
            this.toastyService.error(toastOptions);
            this.loading = false;
          });
        break;
      case 'update':
        service.updateCycle(cycleToSubmit.id, cycleToSubmit)
          .then(() => {
            this.toastyService.clearAll();
            var toastOptions: ToastOptions = {
              title: "Exito",
              msg,
              timeout: 3000,
              theme: "default"
            };
            this.toastyService.info(toastOptions);
            this.closeModal(true);
          })
          .catch(reason => {
            this.toastyService.clearAll();
            var toastOptions: ToastOptions = {
              title: "Error",
              msg: reason.message,
              timeout: 5000,
              theme: "default"
            };
            this.toastyService.error(toastOptions);
            this.loading = false;
          });
        break;
      default:
        break;
    }
  }

  renderCycle() {
    const { start_at, end_at } = this.form.value;
    const startDateMonth: string = start_at.month > 9 ? start_at.month.toString() : `0${start_at.month}`
    const startDateDay: string = start_at.day > 9 ? start_at.day.toString() : `0${start_at.day}`
    const endDateMonth: string = end_at.month > 9 ? end_at.month.toString() : `0${end_at.month}`
    const endDateDay: string = end_at.day > 9 ? end_at.day.toString() : `0${end_at.day}`
    const cycle: CycleUpdateRequest = {
      ...this.selectedCycle,
      name: this.form.get('name').value,
      minimum_score: this.form.get('minimum_score').value,
      cycle_type: this.form.get('cycle_type').value,
      start_at: `${startDateDay}-${startDateMonth}-${start_at.year} 11:59:59`,
      end_at: `${endDateDay}-${endDateMonth}-${end_at.year} 11:59:59`,
      score_handling: this.form.get('score_handling').value,
      branch_id: this.form.get('branch').value,
      units: this.renderUnits(),
    }
    return cycle;
  }

  addUnit() {
    this.units = this.form.get('units') as FormArray;
    const newUnit = {};
    this.units.push(this.toUnitGroup(newUnit));
  }

  deleteUnit(index: number) {
    this.units = this.form.get('units') as FormArray;
    this.units.removeAt(index);
  }

  getUnitOption(index) {
    const unit = this.units.at(index);
    return unit;
  }

  getUnitMinDate(index) {
    const today = new Date();
    let minDate = { year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() };
    if (index > 0) {
      minDate = this.getUnitOption(index - 1).get('end_date').value ?
        this.datesService.getDateAfterDayDifference({
          day: this.getUnitOption(index - 1).get('end_date').value.day,
          month: this.getUnitOption(index - 1).get('end_date').value.month - 1,
          year: this.getUnitOption(index - 1).get('end_date').value.year,
        }, { days: 7 })
        : minDate;
    } else if (index === 0) {
      minDate = this.form.get('start_at').value;
    }
    return minDate;
  }

  renderUnits() {
    const { units }: { units: any[] } = this.form.value;
    this.cycleUnits = units.map((unit, i) => {
      const endDateMonth: string = unit.end_date.month > 9 ? unit.end_date.month.toString() : `0${unit.end_date.month}`
      const endDateDay: string = unit.end_date.day > 9 ? unit.end_date.day.toString() : `0${unit.end_date.day}`
      let renderedUnit: UnitsInput = {
        end_date: `${endDateDay}-${endDateMonth}-${unit.end_date.year} 11:59:59`,
      }
      if (Boolean(unit.id)) {
        renderedUnit = {
          ...renderedUnit,
          unit: unit.unit,
          id: unit.id,
        }
      } else {
        renderedUnit = {
          ...renderedUnit,
          unit: i + 1,
        }
      }
      return renderedUnit;
    });
    return this.cycleUnits;
  }

  closeModal(updated: boolean = false) {
    this.dialogRef.close({ updated });
  }

}
