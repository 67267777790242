import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentAlertsComponent } from './content-alerts.component';
import { AlertInfoComponent } from './alert-info/alert-info.component';
import { ContentAlertsRoutingModule } from './content-alerts-routing.module';
import { SharedModule } from '../theme/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { DataTablesModule } from "angular-datatables";
import { CustomFormsModule } from "ngx-custom-validators";
import { NgbButtonLabel, NgbButtonsModule, NgbModule, NgbRadioGroup } from '@ng-bootstrap/ng-bootstrap';
import { ArchwizardModule } from "angular-archwizard";
import { ToastyModule } from "ng2-toasty";
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContentInfoComponent } from './content-info/content-info.component';
import { MessagePreviewComponent } from './content-info/message-preview/message-preview.component';
import { ActivityPreviewComponent } from './content-info/activity-preview/activity-preview.component';
import { ResourcePreviewComponent } from './content-info/resource-preview/resource-preview.component';
import {ContentModule} from '../content/content.module';

@NgModule({
    imports: [
        CommonModule,
        ContentAlertsRoutingModule,
        SharedModule,
        FormsModule,
        DataTablesModule,
        CustomFormsModule,
        ArchwizardModule,
        ToastyModule,
        NgbModule,
        NgbButtonsModule,
        MatDialogModule,
        ContentModule,
    ],
  declarations: [
    ContentAlertsComponent,
    AlertInfoComponent,
    ContentInfoComponent,
    MessagePreviewComponent,
    ActivityPreviewComponent,
    ResourcePreviewComponent,
  ],
  providers: [
    ContentAlertsComponent,
    AlertInfoComponent,
    ContentInfoComponent,
    MessagePreviewComponent,
    ActivityPreviewComponent,
    ResourcePreviewComponent,
    MatDialog,
    {
      provide: MAT_DIALOG_DATA,
      useValue: {}
    },
    {
      provide: MatDialogRef,
      useValue: {}
    },
    NgbRadioGroup,
    NgbButtonLabel,
  ],
})
export class ContentAlertsModule { }
