import { Injectable } from '@angular/core'
import { StorageService } from '../../api/service/storage.service'

@Injectable({
  providedIn: 'root',
})
export class GrantsService {
  constructor(private storageService: StorageService) { }

  mapGrantToObject(grants: any[], update: boolean) {
    const uigrants = grants
      .map((grant) => ({
        name: `${grant.name} - ${grant.type} ${!update ? `(${grant.id.slice(grant.id.length - 4)})` : grant.id
          }`,
        grants: this.mapInnerGrant(grant.grants, update),
      }))
      .reduce((target, key, index) => {
        target[key.name] =
          key.grants && key.grants.length === 0 ? null : key.grants
        return target
      }, {})
    return Object.assign({}, uigrants)
  }

  mapInnerGrant(grants, update) {
    if (grants && grants.length >= 1) {
      return grants
        .map((grant) => ({
          name: `${grant.name} - ${grant.type} ${!update ? `(${grant.id.slice(grant.id.length - 4)})` : grant.id
            }`,
          grants: this.mapInnerGrant(grant.grants, update),
        }))
        .reduce((target, key, index) => {
          target[key.name] =
            key.grants && key.grants.length === 0 ? null : key.grants
          return target
        }, {})
    } else {
      return []
    }
  }

  mapReverseInnerGrant(grants) {
    if (grants && grants.length >= 1) {
      return grants.map((grant) => ({
        name: grant.name,
        type: grant.type,
        grants: this.mapReverseInnerGrant(grant.grants),
        id: grant.id,
      }))
    } else {
      return []
    }
  }

  createGrantArray(nodes): any {
    let lastParentNode = 0
    let lastParentNodeArray = [];
    for (let index = 0; index < nodes.length; index++) {
      const nextNode = nodes[index + 1]
      if (nextNode) {
        if (nodes[index].level < nextNode.level) {
          lastParentNode = index
          lastParentNodeArray.push(index);
          nodes[index].grants.push(nextNode)
        }
        if (nodes[index].level === nextNode.level) {
          nodes[lastParentNodeArray[lastParentNodeArray.length - 1]].grants.push(nextNode)
        }
        if (nodes[index].level > nextNode.level) {
          let parentLevel = lastParentNodeArray[lastParentNodeArray.length - 2];
          if (parentLevel === nextNode.level) {
            parentLevel--;
          }
          nodes[parentLevel].grants.push(nextNode);
        }
      }
    }
    return nodes;
  }

  validateByNameAndType(nameGrant: string, grantType: string, grantObject?): boolean {
    const isOwner: boolean = this.storageService.getCurrentSession().user.role === 'OWNER' ? true : false;
    if (nameGrant && grantType) {
      if (grantObject === undefined) {
        grantObject = this.storageService.getCurrentSession().grantsObject;
      }
      return Object.keys(grantObject).some((key) => {
        const { name, type } = this.getGrantOBject(key);
        if (name === 'MI_CONTENIDO' && isOwner) {
          return false;
        }
        if (name === nameGrant && type === grantType) {
          return true;
        }
        if (typeof grantObject[key] === 'object' && grantObject[key] !== null) {
          return this.validateByNameAndType(nameGrant, grantType, grantObject[key]);
        }
      })
    }
    return false;
  }

  validateGrants(grants: any[], toLevel: number) {
    const grantObject = this.storageService.getCurrentSession().grantsObject;
    const root = grants[0];
    return Object.keys(grantObject).some((key) => {
      const { name, type } = this.getGrantOBject(key);
      if (root.name === name && root.type === type) {
        const children = grantObject[key];
        if (typeof children === 'object' && children !== null) {
          return this.validateChildren(grants, children, 1, toLevel);
        }
      }
    })
  }

  validateChildren(grants: any[], children: any, level: number, toLevel: number) {
    const item = grants[level];
    return Object.keys(children).some((key) => {
      const { name, type } = this.getGrantOBject(key);
      const childs = children[key];
      if (item.name === name && item.type === type) {
        if (toLevel === level) {
          return true;
        }
        if (typeof childs === 'object' && childs !== null) {
          return this.validateChildren(grants, childs, ++level, toLevel);
        }
      }
    });
  }

  getGrantOBject(key: string) {
    const values = key.split('-');
    const name = values[0].trim();
    const type = values[1].substring(0, values[1].indexOf('(')).trim();

    return { name, type };
  }


}
