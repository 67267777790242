import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContentAlertResponse } from 'src/api/client/data-contracts';
import { Service } from 'src/api/service/Service';
import { StorageService } from 'src/api/service/storage.service';

@Component({
  selector: 'app-content-info',
  templateUrl: './content-info.component.html',
  styleUrls: ['./content-info.component.scss']
})
export class ContentInfoComponent implements OnInit {

  contentAlert: {
    alert?: any,
    contentType?: string,
  } = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public alert: ContentAlertResponse,
    private dialogRef: MatDialogRef<ContentInfoComponent>,
    private storageService: StorageService,
  ) { }

  ngOnInit(): void {
    this.getContentInfo();
  }

  getContentInfo() {
    const service = new Service(this.storageService);
    switch (this.alert.content_type) {
      case 'ACTIVITY':
        //@ts-ignore
        service.getActivity(this.alert.content.id, this.alert.created_by.id).then(async activity => {
          this.contentAlert.alert = activity;
          this.contentAlert.contentType = 'ACTIVITY';
        });
        break;
      case 'MESSAGE':
        //@ts-ignore
        service.getMessageById(this.alert.content.id).then(message => {
          this.contentAlert.alert = message;
          this.contentAlert.contentType = 'MESSAGE';
        });
        break;
      case 'RESOURCE':
        //@ts-ignore
        service.getOneResource(this.alert.content.id).then(async resource => {
          this.contentAlert.alert = resource;
          this.contentAlert.contentType = 'RESOURCE';
        });
        break;
    }
  }

  closeModal(updated: boolean = false) {
    this.dialogRef.close(updated);
  }

}