<app-card [hidHeader]="true" [options]="false" cardClass="msg-card mb-0" blockClass="card-body msg-block">
    <div style="overflow: overlay;">
        <table class="table">
            <thead>
                <tr>
                    <th class="table-title">Tipo de Alerta</th>
                    <th class="table-title">Usuario que Reporta</th>
                    <th class="table-title">Tipo de Contenido</th>
                    <!-- <th class="table-title">Responsable del Contenido</th> -->
                    <th class="table-title">Fecha del Reporte</th>
                    <th class="table-title">Estado</th>
                    <th class="table-title">Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let alert of contentAlerts | slice: (page-1) * 10 : page * 10" class="table-row">
                    <td>
                        <h6 *ngIf="alert.type==='ABUSE'" class="m-0 inactive">
                            <i class="fa fa-bullhorn mr-2" aria-hidden="true"></i>
                            Acoso
                        </h6>
                        <h6 *ngIf="alert.type==='OFFENSIVE'" class="m-0 warn">
                            <i class="fa fa-exclamation mr-2" aria-hidden="true"></i>
                            Ofensivo
                        </h6>
                        <h6 *ngIf="alert.type==='VIOLENCE'" class="m-0 inactive">
                            <i class="fa fa-exclamation-triangle mr-2" aria-hidden="true"></i>
                            Violencia
                        </h6>
                        <h6 *ngIf="alert.type==='SPAM'" class="m-0 warn">
                            <i class="fa fa-flag mr-2" aria-hidden="true"></i>
                            Spam
                        </h6>
                        <h6 *ngIf="alert.type==='OTHERS'" class="m-0 other">
                            <i class="fa fa-bell mr-2" aria-hidden="true"></i>
                            Otros
                        </h6>
                    </td>
                    <td>
                        <h6 class="m-0">
                            {{alert.created_by.name}}
                        </h6>
                    </td>
                    <td class="d-flex flex-row">
                        <h6 *ngIf="alert.content_type==='ACTIVITY'" class="m-0">Actividad</h6>
                        <h6 *ngIf="alert.content_type==='MESSAGE'" class="m-0">Mensaje</h6>
                        <h6 *ngIf="alert.content_type==='RESOURCE'" class="m-0">Recurso</h6>
                        <i class="fa fa-eye ml-3 pointer" aria-hidden="true" data-toggle="tooltip" data-placement="top"
                            title="Ver contenido" (click)="openContactModal(alert)"></i>
                    </td>
                    <!-- HERE GOES CONTENT RESPONSIBLE -->
                    <!-- <td>
                        <h6 class="m-0">{{alert.content.created_by}}</h6>
                    </td> -->
                    <td>
                        <h6 class="m-0">
                            <app-formatted-date inputDate="{{alert.created_at}}"></app-formatted-date>
                        </h6>
                    </td>
                    <td>
                        <div *ngIf="alert.status==='CREATED'">
                            <h6 class="m-0 other">Iniciado</h6>
                            <div class="other-circle m-0 ml-2"></div>
                        </div>
                        <div *ngIf="alert.status==='PENDING'">
                            <h6 class="m-0 inactive">Pendiente</h6>
                            <div class="inactive-circle m-0 ml-2"></div>
                        </div>
                        <div *ngIf="alert.status==='REVIEW'">
                            <h6 class="m-0 warn">En Revisi&oacute;n</h6>
                            <div class="warn-circle m-0 ml-2"></div>
                        </div>
                        <div *ngIf="alert.status==='RESOLVED'">
                            <h6 class="m-0 active">Resuelto</h6>
                            <div class="active-circle m-0 ml-2"></div>
                        </div>
                        <div *ngIf="alert.status==='VOIDED'">
                            <h6 class="m-0 other">Desestimado</h6>
                            <div class="other-circle m-0 ml-2"></div>
                        </div>
                    </td>
                    <td>
                        <button class="btn btn-sm btn-glow-success btn-success f-12"
                            (click)="update(alert)">Actualizar</button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="d-flex justify-content-center">
            <h5 *ngIf="contentAlerts?.length === 0">No hay contenido reportado.</h5>
        </div>
        <div class="d-flex justify-content-center p-2 mb-3">
            <ngb-pagination *ngIf="contentAlerts?.length > 0" [collectionSize]="contentAlerts.length" [(page)]="page" [pageSize]="10">
            </ngb-pagination>
        </div>
    </div>
</app-card>
<ng2-toasty [position]="position"></ng2-toasty>
