<div class="app-modal-body d-flex flex-row mt-2" style="max-height: 95%;">
    <div class="col-6">
        <app-card [hidHeader]="true" [options]="false">
            <div class="m-b-5">
                <h5>T&iacute;tulo:</h5>
                <hr>
                <p class="m-l-25">{{selectedActivity?.title}}</p>
            </div>
            <div class="m-b-5">
                <h5>Creada:</h5>
                <hr>
                <p class="m-l-25">{{selectedActivity?.start_at}}</p>
            </div>
            <div class="m-b-5">
                <h5>Descripci&oacute;n:</h5>
                <hr>
                <p class="m-l-25">{{selectedActivity?.description ? selectedActivity?.description :
                    'Sin descripci&oacute;n'}}</p>
            </div>
            <div class="m-b-5">
                <h5>Comentarios</h5>
                <hr>
                <div class="ml-5" *ngIf="contentCommentsLog?.length > 0">
                    <div *ngFor="let comment of contentCommentsLog" class="mb-2">
                        <p class="font-weight-bold">
                            {{comment.created_by}}:
                        </p>
                        <br>
                        <p>
                            {{comment.comment}}
                        </p>
                        <br>
                        <p class="date-text align-self-end">
                            {{comment.created_at}}
                        </p>
                    </div>
                </div>
                <div class="ml-5" *ngIf="contentCommentsLog?.length === 0">
                    <p>
                        Actividad sin comentarios.
                    </p>
                </div>
            </div>
        </app-card>
    </div>
    <div class="col-6" style="align-self: center;">
        <app-card [customHeader]="true" [options]="false">
            <div class="app-card-header">
                <h4>Documentos Adjuntos</h4>
            </div>
            <div style="overflow: overlay; max-height: 40%;">
                <div *ngFor="let attachment of activityAttachments" class="d-flex flex-row justify-content-center mb-2">
                    <div class="pointer d-flex flex-row align-items-center" (click)="downloadAttachment(attachment,false)">
                        <img src="../../assets/images/messages/attach-outline.svg" class="m-r-10" alt="attachment" />
                        <p style="margin-bottom: 0 !important;">{{attachment.file_name || 'Archivo'}}</p>
                    </div>
                    <i class="text-c-blue f-20 float-right fas fa-eye m-l-10" (click)="openFilePreview(attachment)"></i>
                </div>
                <span *ngIf="activityAttachments?.length === 0">
                    Esta actividad no tiene documentos adjuntos.
                </span>
            </div>
        </app-card>
    </div>
</div>
<app-ui-modal #modalConfirmationDownload [dialogClass]="'modal-lg'">
    <div class="app-modal-header">
        <h5 class="modal-title">Este tipo de archivo no puede ser visualizado!</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
                (click)="modalConfirmationDownload.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <p>¿Desea descargar el archivo?</p>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal"
                (click)="modalConfirmationDownload.hide()">Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="downloadAttachment(attachment,true)">Aceptar</button>
    </div>
</app-ui-modal>
