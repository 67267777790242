<div class="rectangle">
  <div class="vector-3"></div>
  <div class="vector-2"></div>
  <div class="vector-1"></div>
  <div class="auth-wrapper">
    <div class="auth-content">
      <div class="card">
        <form [formGroup]="form">
          <div class="card-body text-center">
            <div class="mb-4">
              <i class="feather icon-mail auth-icon"></i>
            </div>
            <h3 class="mb-4">Solicita reinicio de contraseña</h3>
            <div class="input-group mb-3">
              <input type="email" class="form-control" formControlName="email" placeholder="Correo electrónico"
                required>
            </div>
            <button class="btn btn-primary mb-4" (click)="sendEmail()">
              Reiniciar contraseña
            </button>
          </div>
        </form>
      </div>
    </div>
    <ng2-toasty [position]="position"></ng2-toasty>
  </div>
</div>