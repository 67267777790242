import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs"

interface spaceURL {
  title: string;
  selected: string;
};

@Injectable({
  providedIn: 'root'
})
export class ContentTypeResolverService implements Resolve<spaceURL> {

  constructor() {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<spaceURL> {
    const type = route.params['type'];
    const selected = route.queryParams['selected'];
    const title = type === 'my-courses' ? 'Mis Cursos' : 'Todos los Cursos';
    const space: spaceURL = { title, selected };
    return of(space);
  }

}