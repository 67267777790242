import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-screen-loading',
  templateUrl: './screenLoading.component.html',
  styleUrls: ['./screenLoading.component.scss']
})
export class ScreenLoadingComponent implements OnInit {
  @Input() message: string;
  show: boolean;

  constructor( ) { }
  ngOnInit(): void {}

  off(): void {
    this.show = false;
  }

  on(): void {
    this.show = true;
  }

}
