import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-content-courses',
  templateUrl: './form-content-courses.component.html',
  styleUrls: ['./form-content-courses.component.scss']
})
export class FormContentCoursesComponent implements OnInit {

  position = 'top-center';

  constructor() { }

  ngOnInit(): void {
  }

}
