<aw-wizard #wizard class="arc-wizard" navBarLayout="large-empty-symbols">
    <aw-wizard-step stepTitle="General" [navigationSymbol]="{ symbol: '&#xe8e9;', fontFamily: 'feather' }">
        <!-- <div class="row"> -->
            <form #validationForm="ngForm" class="editForm" novalidate>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">Nombre</label>
                            <input type="text" class="form-control" id="names" name="names"
                                   placeholder="Ingresar Título" #names="ngModel" [(ngModel)]="formInput.name"
                                   [ngClass]="{'is-invalid': (!names.valid && names.touched) && (names.dirty || names.touched)}"
                                   required>
                            <label *ngIf="!names.valid && (names.dirty || names.touched)"
                                   id="validation-names-error"
                                   class="error jquery-validation-error small form-text invalid-feedback">Ingrese un
                                t&iacute;tulo valido.</label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">Código</label>
                            <input type="text" class="form-control" name="keyword" id="keyword" #keyword="ngModel"
                                   [(ngModel)]="formInput.keyword" maxlength="25" [pattern]="codePattern"
                                   [ngClass]="{'is-invalid': (!keyword.valid && keyword.touched) && (keyword.dirty || keyword.touched)}"
                                   placeholder="Ingresar Código" required>
                            <label *ngIf="!keyword.valid && (keyword.dirty || keyword.touched)"
                                   id="validation-apellidos-error"
                                   class="error jquery-validation-error small form-text invalid-feedback">Ingrese una código valida.</label>
                        </div>
                    </div>
                    <div class="col-md-12 m-b-30 m-t-10">
                        <div class="tree-node">
                            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
                                <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
                                <button class="button-icon" mat-icon-button disabled></button>
                                <mat-checkbox class="checklist-leaf-node"
                                                [checked]="checklistSelection.isSelected(node)"
                                                (change)="todoItemSelectionToggle(node)">{{node.item}}</mat-checkbox>
                                </mat-tree-node>
                            
                                <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
                                <button class="button-icon" mat-icon-button matTreeNodeToggle
                                        [attr.aria-label]="'toggle ' + node.filename">
                                    <mat-icon class="mat-icon-rtl-mirror">
                                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                    </mat-icon>
                                </button>
                                <mat-checkbox [checked]="descendantsAllSelected(node)"
                                                [indeterminate]="descendantsPartiallySelected(node)"
                                              (change)="todoItemSelectionToggle(node)">{{node.item}}
                                </mat-checkbox>                            </mat-tree-node>
                            </mat-tree>
                        </div>
                    </div>
                    
                </div>
            </form>
            <div class="col-sm-12 centered-content">
                <div class="btn-group mt-10">
                    <button type="button" class="btn btn-primary btn-sm"
                    [disabled]="!names.valid || !keyword.valid" 
                    (click)="save(validationForm)" >Guardar</button>
                </div>
                <p *ngIf="!names.valid || !keyword.valid" style="margin-top: 5px;">Por favor, llena todas las casillas</p>
            </div>
        <!-- </div> -->
    </aw-wizard-step>
</aw-wizard>
