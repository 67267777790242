import { NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthSigninRoutingModule } from './auth-signin-routing.module';
import { AuthSigninComponent, DialogPoliciesDialog } from './auth-signin.component';
import { ToastyModule } from "ng2-toasty";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import {MatDialogModule} from '@angular/material/dialog';

@NgModule({
    imports: [
        MatDialogModule,
        CommonModule,
        AuthSigninRoutingModule,
        ToastyModule,
        FormsModule,
        ReactiveFormsModule,
    ],
  declarations: [
    AuthSigninComponent,
    DialogPoliciesDialog,
    ResetPasswordComponent,
    ChangePasswordComponent,
  ],
  bootstrap: [DialogPoliciesDialog],
})

export class AuthSigninModule { }
