import { Component, NgZone, OnInit } from '@angular/core';
import { DattaConfig } from 'src/app/app-config';
import { Location } from '@angular/common';
import { StorageService } from "src/api/service/storage.service";
import { Router } from "@angular/router";
import { OneSignal  } from "onesignal-ngx";
import { UploadsNotifierService } from '../../shared/services/uploads-notifier.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  public dattaConfig: any;
  public navCollapsed: boolean;
  public navCollapsedMob: boolean;
  public windowWidth: number;

  public showNotifier: boolean = false;

  constructor(
    private storageService: StorageService,
    private router: Router,
    private zone: NgZone,
    private location: Location,
    private oneSignal: OneSignal ,
    private uploadsNotifierService: UploadsNotifierService,
    ) {
    this.dattaConfig = DattaConfig.config;

    let current_url = this.location.path();
    if (this.location['_baseHref']) {
      current_url = this.location['_baseHref'] + this.location.path();
    }

    if (current_url === this.location['_baseHref'] + '/layout/collapse-menu' || current_url === this.location['_baseHref'] + '/layout/box') {
      this.dattaConfig['collapse-menu'] = true;
    }

    this.windowWidth = window.innerWidth;
    this.navCollapsed = (this.windowWidth >= 992) ? this.dattaConfig['collapse-menu'] : false;
    this.navCollapsedMob = false;

  }

  ngOnInit() {
    if (!this.storageService.isAuthenticated()) {
      this.router.navigate([''])
    } else {
      this.oneSignal.sendTag("user_id", this.storageService.getCurrentUser().id);
    }
    this.uploadsNotifierService.showNotifierChange$.subscribe(value => {
      this.showNotifier = value;
    });
  }

  navMobClick() {
    if (this.navCollapsedMob && !(document.querySelector('app-navigation.pcoded-navbar').classList.contains('mob-open'))) {
      this.navCollapsedMob = !this.navCollapsedMob;
      setTimeout(() => {
        this.navCollapsedMob = !this.navCollapsedMob;
      }, 100);
    } else {
      this.navCollapsedMob = !this.navCollapsedMob;
    }
  }

}
