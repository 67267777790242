import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-reroute-modal',
  templateUrl: './loading-reroute-modal.component.html',
  styleUrls: ['./loading-reroute-modal.component.scss']
})
export class LoadingRerouteModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}