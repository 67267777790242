import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastOptions, ToastyService } from 'ng2-toasty';
import { ContactResponse, CycleUpdateRequest, SpaceResponse, UnitsInput } from 'src/api/client/data-contracts';
import { Service } from 'src/api/service/Service';
import { StorageService } from 'src/api/service/storage.service';

@Component({
  selector: 'app-student-scores-modal',
  templateUrl: './student-scores-modal.component.html',
  styleUrls: ['./student-scores-modal.component.scss']
})
export class StudentScoresModalComponent implements OnInit {

  title: string = 'Notas';
  tabTitle: string = '';
  form: FormGroup;
  studentScores: any[] = [];
  noCycleError: boolean = false;
  currentCycle: CycleUpdateRequest;
  cycleUnits: UnitsInput[] = [];
  spaceIds: string[] = [];
  selectedUnit: number;
  compute: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public incomingData: {
      selectedStudent?: ContactResponse,
      space?: Partial<SpaceResponse>,
      selectUnit?: boolean,
      showSpace?: boolean,
    },
    private dialogRef: MatDialogRef<StudentScoresModalComponent>,
    private storageService: StorageService,
    private toastyService: ToastyService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    if (this.incomingData.selectUnit) {
      this.getActiveCycleAndUnits();
      this.getStudentsSpaces();
      this.buildForm();
    } else {
      this.tabTitle = `Notas para alumno`;
      this.spaceIds.push(this.incomingData.space.id);
      this.getStudentScore(null, true);
    }
  }

  buildForm() {
    this.form = this.fb.group({
      unit: ['', [Validators.required]],
    });
    this.form.get('unit').valueChanges.subscribe(unitId => {
      if (unitId !== 'total') {
        this.compute = false;
        this.selectedUnit = this.cycleUnits.find(unit => unit.id === unitId).unit;
        this.getStudentScore(unitId, false);
        this.tabTitle = `Notas de Unidad ${this.selectedUnit} de ${this.incomingData.selectedStudent.names} ${this.incomingData.selectedStudent.last_names}`;
      } else {
        this.compute = true;
        this.getStudentScore(null, false);
        this.tabTitle = `Notas totales de ${this.incomingData.selectedStudent.names} ${this.incomingData.selectedStudent.last_names}`;
      }
    });
  }

  async getStudentsSpaces() {
    const service = new Service(this.storageService);
    const studentSpaces: SpaceResponse[] = await service.getAllSpacesByContactId(this.incomingData.selectedStudent.id) as SpaceResponse[];
    studentSpaces.forEach(space => {
      if (!this.spaceIds.includes(space.parent_id)) {
        this.spaceIds.push(space.parent_id);
      }
    });
  }

  getActiveCycleAndUnits() {
    const service = new Service(this.storageService);
    // @ts-ignore
    service.getActiveCycleBySpaceOrAssigment(this.incomingData.selectedStudent.id).then((cycle: CycleUpdateRequest) => {
      if (Boolean(cycle)) {
        this.currentCycle = cycle[0];
        service.getCycleUnits(this.currentCycle.id)
          .then((units: UnitsInput[]) => {
            this.cycleUnits = units.sort((a, b) => a.unit - b.unit);
          });
      } else {
        this.noCycleError = true;
      }
    }).catch(err => {
      this.noCycleError = true;
      this.toastyService.clearAll();
      var toastOptions: ToastOptions = {
        title: "Espere",
        msg: "Guardando registro",
        timeout: 5000,
        theme: "default"
      };
      this.toastyService.error(toastOptions);
      console.log("Err:", err);
    });
  }

  getStudentScore(unitId?: string, compute: boolean = false) {
    const service = new Service(this.storageService);
    let params: {
      contact_id?: string,
      unit_id?: string,
      compute?: boolean,
    } = { contact_id: this.incomingData.selectedStudent.id };
    if (this.incomingData.selectUnit && unitId) params.unit_id = unitId;
    if (compute) {
      params.compute = compute;
    }
    this.spaceIds.forEach(async spaceId => {
      const spaceScores = await service.getSpaceScoreInfo(spaceId, params) as any[];
      this.studentScores = spaceScores;
    });
  }

  closeModal() {
    this.dialogRef.close();
  }

}
