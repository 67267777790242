import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastOptions, ToastyService } from 'ng2-toasty';
import { CycleUpdateRequest } from 'src/api/client/data-contracts';
import { Service } from 'src/api/service/Service';
import { StorageService } from 'src/api/service/storage.service';

@Component({
  selector: 'app-status-change-confirmation-modal',
  templateUrl: './status-change-confirmation-modal.component.html',
  styleUrls: ['./status-change-confirmation-modal.component.scss']
})
export class StatusChangeConfirmationModalComponent implements OnInit {

  title: string = '';
  acceptButtonText: 'Activar' | 'Desactivar';
  activeCycle: CycleUpdateRequest;
  loading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public cycleAndRequest: {
      cycle: CycleUpdateRequest,
      statusChangeRequest: 'activate' | 'deactivate'
    },
    private dialogRef: MatDialogRef<StatusChangeConfirmationModalComponent>,
    private storageService: StorageService,
    private toastyService: ToastyService,
  ) { }

  ngOnInit(): void {
    switch (this.cycleAndRequest.statusChangeRequest) {
      case 'activate':
        const service = new Service(this.storageService);
        service.getActiveCycle().then(cycle => {
          this.activeCycle = cycle as CycleUpdateRequest;
        }).catch(err => {
          console.log("Err:", err);
        });
        this.title = '¿Está seguro que desea activar este ciclo?';
        this.acceptButtonText = 'Activar';
        break;
      case 'deactivate':
        this.title = '¿Está seguro que desea desactivar este ciclo?';
        this.acceptButtonText = 'Desactivar';
        break;
      default:
        break;
    }
  }

  changeCycleStatus() {
    this.loading = true;
    const msg = this.cycleAndRequest.statusChangeRequest === 'activate' ? 'Activando ciclo' : 'Desactivando ciclo';
    this.toastyService.clearAll();
    var toastOptions: ToastOptions = {
      title: "Espere",
      msg,
      timeout: 5000,
      theme: "default"
    };
    this.toastyService.wait(toastOptions);
    const service = new Service(this.storageService);
    service.updateCycle(this.cycleAndRequest.cycle.id, {
      ...this.cycleAndRequest.cycle,
      enabled: this.cycleAndRequest.statusChangeRequest === 'activate' ? true : false,
      branch_id: this.cycleAndRequest.cycle.branch.id
    }).then(() => {
      this.toastyService.clearAll();
      var toastOptions: ToastOptions = {
        title: "Exito",
        msg: this.cycleAndRequest.statusChangeRequest === 'activate' ? 'Ciclo activado exitosamente' : 'Ciclo desactivado exitosamente',
        timeout: 3000,
        theme: "default"
      };
      this.toastyService.info(toastOptions);
      this.closeModal(true);
    }).catch(err => {
      this.toastyService.clearAll();
      var toastOptions: ToastOptions = {
        title: "Error",
        msg: err.message,
        timeout: 5000,
        theme: "default"
      };
      this.toastyService.error(toastOptions);
      this.loading = false;
      console.log("Err:", err);
    });
  }

  closeModal(accept: boolean = false) {
    this.dialogRef.close(accept);
  }

}