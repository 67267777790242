<aw-wizard #wizard class="arc-wizard" navBarLayout="large-empty-symbols">
    <aw-wizard-step stepTitle="General" [navigationSymbol]="{ symbol: '&#xe8e9;', fontFamily: 'feather' }"
        [canExit]="validateContact(validationForm)">
        <div class="row">
            <form #validationForm="ngForm" class="editForm" novalidate>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">Nombre</label>
                            <input type="text" class="form-control" id="names" name="names"
                                placeholder="Ingresar nombre" #names="ngModel" [(ngModel)]="formInput.name"
                                [ngClass]="{'is-invalid': (!names.valid && names.touched) && (names.dirty || names.touched || isSubmit)}"
                                required>
                            <label *ngIf="!names.valid && (names.dirty || names.touched || isSubmit)"
                                id="validation-names-error"
                                class="error jquery-validation-error small form-text invalid-feedback">Ingrese un
                                nombre valido.</label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">Descripcion</label>
                            <input type="text" class="form-control" name="description" id="description"
                                #description="ngModel" [(ngModel)]="formInput.description"
                                [ngClass]="{'is-invalid': (!description.valid && description.touched) && (description.dirty || description.touched || isSubmit)}"
                                placeholder="Ingresar descripcion" required>
                            <label *ngIf="!description.valid && (description.dirty || description.touched || isSubmit)"
                                id="validation-apellidos-error"
                                class="error jquery-validation-error small form-text invalid-feedback">Ingrese una
                                descripci&oacute;n valida.</label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">Archivo</label>
                            <input type="file" class="form-control" id="file" name="file" #avatar="ngModel"
                                [(ngModel)]="formInput.file" (change)="fileChangeEvent($event)">
                        </div>
                    </div>

                </div>
            </form>
            <div *ngIf="!loading" class="col-sm-12 centered-content">
                <div class="btn-group mt-10">
                    <button type="button" class="btn btn-primary btn-sm" (click)="save(validationForm)">Guardar</button>
                </div>
            </div>
            <div *ngIf="loading" class="card-body d-flex flex-row justify-content-center mt-5">
                <div class="spinner-border text-info" role="status">
                    <span class="sr-only"></span>
                </div>
            </div>
        </div>
    </aw-wizard-step>
</aw-wizard>