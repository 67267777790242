import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { StorageService } from "../../api/service/storage.service";
import { Service } from "../../api/service/Service";
import { SpaceResponse } from "../../api/client/data-contracts"

interface SpaceResolve {
  space: SpaceResponse,
  parents: string[]
}

@Injectable({
  providedIn: 'root'
})
export class SpaceResolverService implements Resolve<SpaceResolve> {

  constructor(
    private storageService: StorageService,
  ) {
  }

  getParentsNode(node, spaces: any[], parents: string[]) {
    if (node.parent_id) {
      const parent = spaces.find((space) => space.id === node.parent_id);
      parents.push(parent.title);
      this.getParentsNode(parent, spaces, parents);
    }
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<SpaceResolve> {
    const id = route.queryParams['id'];
    const service = new Service(this.storageService);
    const spaces = await service.getSpaces();
    let space = spaces.find((space) => space.id === id);
    if (!space) {
      space = await service.getActivity(id);
    }
    let parents: string[] = [];
    this.getParentsNode(space, spaces, parents);
    parents = parents.reverse()

    return { space, parents };
  }
  
}