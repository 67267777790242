import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Service} from '../../api/service/Service';
import {StorageService} from '../../api/service/storage.service';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {ActivatedRoute, Router} from '@angular/router';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {map, tap} from 'rxjs/operators';
import {SpaceExpandableFlatNode, SpaceNodeFlat} from '../../util/space';
import {ContactResponse, ContactsRequest, SpaceRequest, SpaceResponse} from 'src/api/client/data-contracts';
import {MatDialog} from '@angular/material/dialog';
import {CoursesUploadComponent} from './courses-upload/courses-upload.component';
import {ContactAssignManagerComponent} from './contact-assign-manager/contact-assign-manager.component';
import {GrantsService} from '../services/grants.service';
import {RelateManagerComponent} from '../contacts/relate-manager/relate-manager.component';
import {StudentScoresModalComponent} from '../theme/shared/components/student-scores-modal/student-scores-modal.component';
import {SpaceScoresModalComponent} from '../theme/shared/components/space-scores-modal/space-scores-modal.component';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {

  private transformer = (node: SpaceNodeFlat, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level,
      id: node.id,
      avatar: node.avatar,
      type: node.type,
      description: node.description,
      parentId: node.parentId,
      branch: node.branch
    };
  }

  treeControl = new FlatTreeControl<SpaceExpandableFlatNode>(node => node.level, node => node.expandable);
  treeFlattener = new MatTreeFlattener(this.transformer, node => node.level, node => node.expandable, node => node.children);
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  createUpdateTitle = 'Crear';
  noSpacesMessage = 'No hay espacios disponibles';
  position = 'top-center';
  isCompleteStatus = false;
  items: SpaceNodeFlat[] = [];
  spaces: SpaceNodeFlat[] = [];
  rows: string[];
  titleSelected = '';
  selectedSpace = '';
  isExpanded = false;
  type = '';
  idSelected = '';
  parentNodeSelected: SpaceNodeFlat = null;
  typeSelected = '';
  spaceIdAssign = '';
  spaceToDelete = '';
  associateTitle = '';
  selected: SpaceNodeFlat;
  selectedUser = new Array<string>();
  allUsers: Array<ContactResponse> = new Array<ContactResponse>();
  users: Array<ContactResponse> = new Array<ContactResponse>();
  selectedLevel: number;
  myCourses: boolean = false;
  spaceTabTitle: string = 'Secciones';
  sectionStudents: ContactsRequest[] = [];
  _studentsPage: number = 1;
  spaceAllInfo: SpaceResponse;
  selectedStudent: ContactResponse;

  @ViewChild('modalCreate', { static: true }) modalCreate: ElementRef;
  @ViewChild('modalForm', { static: true }) modalForm: ElementRef;
  @ViewChild('modalDelete', { static: true }) modalDelete: ElementRef;

  constructor(
      private spinner: NgxSpinnerService,
    private storageService: StorageService,
    private toastyService: ToastyService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private grantsService: GrantsService,
  ) { }

  ngOnInit(): void {
    const isOwner: boolean = this.storageService.getCurrentSession().user.role === 'OWNER' ? true : false;
    this.route.queryParams.subscribe(params => {
      this.selectedSpace = params.id;
      if (this.selectedSpace) {
        this.isExpanded = true;
      }
    });
    this.route.params.subscribe((param) => {
      this.type = param.type;
      this.myCourses = param.type === 'my-courses' ? true : false;
      if (this.myCourses && isOwner) {
        this.router.navigate(['menu/content/all-courses']);
        return;
      }
      this.items = [];
      this.spaces = [];
      this.refreshTree(null);

      this.titleSelected = '';
      this.idSelected = '';
      this.parentNodeSelected = null;
      this.typeSelected = '';
      this.selected = {
        name: '',
        id: '',
        type: '',
        description: ''
      };
      this.selectedLevel = 0;
    });
  }

  private trackSpace(index: number, space: SpaceNodeFlat): string {
    return space.id;
  }

  getIcon(type: string) {
    switch (type) {
      case 'CLASSROOM':
        return 'import_contacts';
      case 'CATEGORY':
        return 'grid_on';
      case 'GRADE':
        return 'class';
      case 'CAREER':
        return 'school';
      case 'SECTION':
        return 'view_headline';
      case 'INSTITUTION':
        return 'account_balance';
    }
  }

  openContentUploadModal(id): void {
    this.dialog.open(CoursesUploadComponent, {
      data: id,
      height: '65%',
      width: '75%',
    });
  }

  async refreshTree(parentId: string): Promise<boolean> {
    const service = new Service(this.storageService);
    this.spinner.show();
    if (this.myCourses) {
      service.getAllSpacesBySpaceObs(parentId, this.myCourses)
          .pipe(
              map((space) => ({
                name: space.title, id: space.id, parentId: space.parent_id,
                type: space.type, description: space.description, avatar: space.avatar.href, branch: space.branch
              })),
              tap((space) => this.items.push(space))
          ).subscribe(() => {
        this.spaces = this.items.slice();
      });
    } else {
      this.items = await service.getAllSpacesBySpaceAsync(parentId, this.myCourses);
      this.dataSource.data = this.items.slice();
      this.spinner.hide();
      if (this.selectedSpace && this.selectedSpace !== '') {
        const treeNode: SpaceExpandableFlatNode = this.treeControl.dataNodes
            .find((node: SpaceExpandableFlatNode) => node.id === this.selectedSpace);
        if (treeNode) {
          this.expandTreeControl(treeNode);
          this.onSelectedChangeObs(treeNode);
        }
      }
    }
    return true;
  }

  updateDataStudents(spaceId: string): void {
    const service = new Service(this.storageService);
    service.getStudentsBySpace(spaceId).then(value => this.sectionStudents = value);
  }

  selectAsociation(contact: ContactResponse): void {
    this.selectedStudent = contact;
    this.dialog.open(RelateManagerComponent, {
      data: this.selectedStudent,
      height: '85%',
      width: '75%',
      autoFocus: false,
    }).afterClosed().subscribe(() => {
      this.updateDataStudents(this.spaceAllInfo.id);
    });
  }

  studentScores(student: ContactResponse): void {
    this.selectedStudent = student;
    this.dialog.open(StudentScoresModalComponent, {
      data: {
        selectedStudent: this.selectedStudent,
        space: { id: this.spaceAllInfo.id },
        selectUnit: false,
        showSpace: true,
      },
      height: '75%',
      width: '55%',
      autoFocus: false,
    }).afterClosed().subscribe(() => {
      this.updateDataStudents(this.spaceAllInfo.id);
    });
  }

  expandTreeControl(expandNode): void {
    this.treeControl.expand(expandNode);
    if (expandNode.parentId) {
      const parentNode = this.treeControl.dataNodes.find((node) => node.id === expandNode.parentId);
      this.expandTreeControl(parentNode);
    }
  }

  deleteSpaceShow(id) {
    this.spaceToDelete = id;
    // @ts-ignore
    this.modalDelete.show();
  }

  showScores(idSpace) {
    this.dialog.open(SpaceScoresModalComponent, {
      data: {
        space: {id: idSpace},
        selectUnit: true,
        showSpace: true,
      },
      height: '75%',
      width: '55%',
      autoFocus: false,
    });
  }

  deleteSpace() {
    const service = new Service(this.storageService);
    service.removeSpace(this.spaceToDelete).then(() => {
      // @ts-ignore
      this.modalDelete.hide();
      this.toastyService.clearAll();
      var toastOptions: ToastOptions = {
        title: "Exito",
        msg: "Espacio eliminado exitosamente.",
        timeout: 3000,
        theme: "default"
      };
      this.toastyService.error(toastOptions);
      this.items = this.items.filter((item) => item.id !== this.spaceToDelete);
      this.spaces = this.spaces.filter((item) => item.id !== this.spaceToDelete);
      this.dataSource.data = this.items;
      this.spaceToDelete = null;
    });
  }

  addSpaceChanged(space: SpaceNodeFlat): void {
    this.refreshTree(null);
  }

  closeModalForm(): void {
    // @ts-ignore
    this.modalCreate.hide();
  }

  onSelectedChangeObs(node: SpaceExpandableFlatNode): void {
    this.spaceAllInfo = node as SpaceResponse;
    switch (this.spaceAllInfo.type) {
      case 'CAREER':
        this.spaceTabTitle = 'Grados';
        break;
      case 'GRADE':
        this.spaceTabTitle = 'Secciones';
        this.updateDataStudents(this.spaceAllInfo.id);
        break;
      default:
        break;
    }
    const currentNode = this.findParentNode(node.id);
    this.spaces = currentNode.children ? currentNode.children.filter(child => child.type === 'CLASSROOM'
        || child.type === 'GRADE'
        || child.type === 'SECTION').slice() : [];
    this.titleSelected = node.name;
    this.idSelected = node.id;
    this.parentNodeSelected = node;
    this.typeSelected = node.type;
    this.selectedLevel = node.level;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        id: node.id
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      skipLocationChange: false
      // do not trigger navigation
    });
    this.storageService.set('selectedSpace', node.id);
  }

  private findParentNode(targetNodeId: string): SpaceNodeFlat {
    const result = this.items.map(item => this.findNode(targetNodeId, item))
        .filter(item => item);
    return result ? result[0] : null;
  }

  private findNode(targetNodeId: string, currentNode: SpaceNodeFlat): SpaceNodeFlat {
    if (targetNodeId === currentNode.id) {
      return currentNode;
    } else if (currentNode.children && currentNode.children.length > 0) {
      const matches = currentNode.children.map(child => this.findNode(targetNodeId, child))
          .filter(item => item);
      return matches[0];
    } else {
      return null;
    }
  }

  toggleTree(): void {
    if (this.isExpanded) {
      this.treeControl.collapseAll();
      this.isExpanded = false;
    } else {
      this.treeControl.expandAll();
      this.isExpanded = true;
    }
  }

  goToRoot(): void {
    this.titleSelected = '';
    this.idSelected = '';
    this.selected = {
      name: '',
      id: '',
      type: '',
      description: ''
    };
    this.selectedLevel = 0;
    this.spaces = [];
  }

  openClass(node): void {
    this.router.navigate([`menu/content/${this.type}/form-content`], { queryParams: { id: node.id } });
  }

  createUpdate(action: string, space: SpaceNodeFlat): void {
    let id = '';
    if (action === 'create') {
      // @ts-ignore
      this.modalForm.initialize(id, null);
      this.createUpdateTitle = 'Crear';
    } else if (action === 'update-card') {
      id = space.id;
    } else {
      id = this.idSelected;
    }
    if (id !== '') {
      let node;
      if (this.myCourses) {
        node = this.spaces.find(node => node.id === id);
      } else {
        node = this.treeControl.dataNodes.find(node => node.id === id);
      }
      const formInput: SpaceRequest = {
        description: node.description,
        parent_space_id: node.parentId,
        title: node.name,
        type: node.type,
        avatar: node.avatar,
        branch: node.branch
      };

      // @ts-ignore
      this.modalForm.initialize(node.id, formInput);
      this.createUpdateTitle = 'Actualizar';
    }

    // @ts-ignore
    this.modalCreate.show();
    this.modalForm['awGoToStep'] = '{ stepIndex: 2 }';
  }

  assign(space: SpaceNodeFlat, type: string): void {
    const service = new Service(this.storageService);
    this.selected = space;
    this.spaceIdAssign = space.id;
    this.selectedUser = [];
    this.allUsers = [];
    this.associateTitle = type === 'STUDENT' ? 'estudiante' : 'catedrático';
    service.getAllUsersByRole('', 1, type).then((value:any) => {
      this.allUsers = value.data;
      if (type === 'STUDENT') {
        service.getStudentsBySpace(this.spaceIdAssign).then(students => {
          const assignedStudentsIds = students.map((user) => user.id);
          this.openAssignModal({
            allContacts: this.allUsers,
            assignedContacts: assignedStudentsIds,
            selectedSpace: this.selected,
            title: this.associateTitle
          });
        });
      } else {
        service.getStaffsBySpace(this.spaceIdAssign).then(staffs => {
          const assignedStaffIds = staffs.map((staff) => staff.id);
          this.openAssignModal({
            allContacts: this.allUsers,
            assignedContacts: assignedStaffIds,
            selectedSpace: this.selected,
            title: this.associateTitle
          });
        });
      }
    });
  }

  openAssignModal(data: { allContacts: any[], assignedContacts: string[], selectedSpace: SpaceNodeFlat, title: string }): void {
    this.dialog.open(ContactAssignManagerComponent, {
      data,
      height: '75%',
      width: '55%',
      autoFocus: false,
    });
  }

  search(keyword): void {
    if (keyword !== '') {
      this.users = this.allUsers.filter(
        student =>
          student.names.toLowerCase().includes(keyword.toLowerCase())
          || student.last_names.toLowerCase().includes(keyword.toLowerCase()));
    } else {
      this.users = this.allUsers;
    }
  }

  changeCheckbox(student: ContactResponse, checked: boolean): void {
    if (checked) {
      this.selectedUser.push(student.id);
    } else {
      const index = this.selectedUser.indexOf(student.id);

      if (index >= 0) {
        this.selectedUser.splice(index, 1);
      }
    }
  }

  openOrSelect(item): void {
    if (item.type === 'CLASSROOM') {
      this.openClass(item);
    } else {
      const node = this.treeControl.dataNodes.find((node) => node.id === item.id);
      this.treeControl.expand(node);
      this.onSelectedChangeObs(node);
    }
  }


  get studentsPage(): number {
    return this._studentsPage;
  }

  set studentsPage(page: number) {
    this._studentsPage = page;
  }

}
