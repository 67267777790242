export default [
    {
        "code": "+(54)",
        "name": "Argentina",
        "countryCode": "AR",
    },
    {
        "code": "+(501)",
        "name": "Belice",
        "countryCode": "BZ",
    },
    {
        "code": "+(591)",
        "name": "Bolivia",
        "countryCode": "BO",
    },
    {
        "code": "+(55)",
        "name": "Brazil",
        "countryCode": "BR",
    },
    {
        "code": "+(1)",
        "name": "Canada",
        "countryCode": "CA",
    },
    {
        "code": "+(56)",
        "name": "Chile",
        "countryCode": "CL",
    },
    {
        "code": "+(86)",
        "name": "China",
        "countryCode": "CN",
    },
    {
        "code": "+(57)",
        "name": "Colombia",
        "countryCode": "CO",
    },
    {
        "code": "+(506)",
        "name": "Costa Rica",
        "countryCode": "CR",
    },
    {
        "code": "+(53)",
        "name": "Cuba",
        "countryCode": "CU",
    },
    {
        "code": "+1 (809)",
        "name": "Dominican Republic",
        "countryCode": "DO",
    },
    {
        "code": "+(593)",
        "name": "Ecuador",
        "countryCode": "EC",
    },
    {
        "code": "+(503)",
        "name": "El Salvador",
        "countryCode": "SV",
    },
    {
        "code": "+(49)",
        "name": "Germany",
        "countryCode": "DE",
    },
    {
        "code": "+(299)",
        "name": "Greenland",
        "countryCode": "GL",
    },
    {
        "code": "+(502)",
        "name": "Guatemala",
        "countryCode": "GT",
    },
    {
        "code": "+(504)",
        "name": "Honduras",
        "countryCode": "HN",
    },
    {
        "code": "+(52)",
        "name": "Mexico",
        "countryCode": "MX",
    },
    {
        "code": "+(505)",
        "name": "Nicaragua",
        "countryCode": "NI",
    },
    {
        "code": "+(507)",
        "name": "Panama",
        "countryCode": "PA",
    },
    {
        "code": "+(595)",
        "name": "Paraguay",
        "countryCode": "PY",
    },
    {
        "code": "+(51)",
        "name": "Peru",
        "countryCode": "PE",
    },
    {
        "code": "+1 (787)",
        "name": "Puerto Rico",
        "countryCode": "PR",
    },
    {
        "code": "+(34)",
        "name": "Spain",
        "countryCode": "ES",
    },
    {
        "code": "+(46)",
        "name": "Sweden",
        "countryCode": "SE",
    },
    {
        "code": "+(41)",
        "name": "Switzerland",
        "countryCode": "CH",
    },
    {
        "code": "+(44)",
        "name": "United Kingdom",
        "countryCode": "UK",
    },
    {
        "code": "+(1)",
        "name": "United States",
        "countryCode": "US",
    },
    {
        "code": "+(598)",
        "name": "Uruguay",
        "countryCode": "UY",
    },
    {
        "code": "+(58)",
        "name": "Venezuela",
        "countryCode": "VE",
    },
]