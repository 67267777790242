import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { formatDate } from '@angular/common';
import { CalendarOptions, EventSourceInput, FullCalendarComponent } from '@fullcalendar/angular';
import { Service } from 'src/api/service/Service';
import { StorageService } from 'src/api/service/storage.service';
import { ActivityShortResponse } from 'src/api/client/data-contracts';
import * as moment from 'moment';



@Component({
  selector: 'app-event-calendar',
  templateUrl: './event-calendar.component.html',
  styleUrls: ['./event-calendar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EventCalendarComponent implements OnInit {

  @ViewChild('fullCalendar') calendarComponent: FullCalendarComponent;

  activitesAsEvents: EventSourceInput;
  dateObj = new Date();
  yearMonth = this.dateObj.getUTCFullYear() + '-' + (this.dateObj.getUTCMonth() + 1);
  monthInCalendar = this.dateObj.getUTCMonth() + 1;
  yearInCalendar = this.dateObj.getUTCFullYear();
  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    locale: 'es',
    buttonText: {
      today: 'Hoy'
    },
    customButtons: {
      prev: {
        click: this.handleClick.bind(this, 'prev'),
      },
      next: {
        click: this.handleClick.bind(this, 'next'),
      },
    }
  };

  constructor(
    private storageService: StorageService,
  ) { }

  ngOnInit() {
    const service = new Service(this.storageService);
    service.getAllActivities('MONTH').then((activities: any[]) => {
      this.calendarOptions.events = this.parseActivitiesToEvents(activities);
    });
  }

  parseStartDate(startDate: string, startTime: string | null): string {
    let formattedDateTime: string;
    if (startTime) {
      formattedDateTime = `${startDate}T${startTime}`;
    } else {
      formattedDateTime = `${startDate}`;
    }
    return formattedDateTime;
  }

  parseActivitiesToEvents(activities: any[]) {
    let convertedEvents = [];
    activities.forEach((activity: ActivityShortResponse) => {
      const activityUrl = `menu/content/my-courses/form-content/activity?id=${activity.id}`;
      var start=this.parseStartDate(activity.start_at, activity.start_time)
      debugger;
      const actToEvent = {
        title: activity.title,
        start: start,
        url: activityUrl,
        borderColor: '#a389d4',
        backgroundColor: '#a389d4',
        textColor: '#fff',
      };
      convertedEvents.push(actToEvent);
    });
    return convertedEvents;
  }

  handleClick(goingTo: string, event) {
    const calendarApi = this.calendarComponent.getApi();
    const service = new Service(this.storageService);
    const currentMonth = this.dateObj.getUTCMonth() + 1;
    let dateRange: {
      from?: string,
      to?: string,
    } = {};
    let period = 'MONTH';
    switch (goingTo) {
      case 'next':
        this.monthInCalendar++;
        if (this.monthInCalendar === currentMonth) {
          dateRange = null;
        } else {
          switch (this.monthInCalendar) {
            case 12:
              dateRange.from = `${this.monthInCalendar}-01-${this.yearInCalendar}`;
              dateRange.to = `01-01-${this.yearInCalendar + 1}`;
              break;
            case 13:
              this.monthInCalendar = 1;
              this.yearInCalendar++;
              dateRange.from = `${this.monthInCalendar}-01-${this.yearInCalendar}`;
              dateRange.to = `${this.monthInCalendar + 1}01-${this.yearInCalendar}`;
              break;
            default:
              dateRange.from = `${this.monthInCalendar}-01-${this.yearInCalendar}`;
              dateRange.to = `${this.monthInCalendar + 1}01-${this.yearInCalendar}`;
              break;
          }
          period = null;
        }
        service.getAllActivities(period, dateRange).then((activities: any[]) => {
          this.calendarOptions.events = this.parseActivitiesToEvents(activities);
        });
        calendarApi.next();
        break;
      case 'prev':
        this.monthInCalendar--;
        if (this.monthInCalendar === 0) {
          this.monthInCalendar = 12;
          this.yearInCalendar--;
        }
        if (this.monthInCalendar === currentMonth) {
          dateRange = null;
        } else {
          switch (this.monthInCalendar) {
            case 12:
              dateRange.from = `${this.monthInCalendar}-01-${this.yearInCalendar}`;
              dateRange.to = `01-01-${this.yearInCalendar + 1}`;
              break;
            default:
              dateRange.from = `${this.monthInCalendar}-01-${this.yearInCalendar}`;
              dateRange.to = `${this.monthInCalendar + 1}-01-${this.yearInCalendar}`;
              break;
          }
          period = null;
        }
        service.getAllActivities(period, dateRange).then((activities: any[]) => {
          this.calendarOptions.events = this.parseActivitiesToEvents(activities);
        });
        calendarApi.prev();
        break;
      default:
        service.getAllActivities('MONTH').then((activities: any[]) => {
          this.calendarOptions.events = this.parseActivitiesToEvents(activities);
        });
        break;
    }
  }

}
