import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecurityRolesComponent } from './security-roles/security-roles.component';
import { SharedModule } from '../theme/shared/shared.module';
import { FormsModule } from '@angular/forms';
import { SecurityRolesRoutingModule } from './security-roles-routing.module';
import { DataTablesModule } from "angular-datatables";
import { CustomFormsModule } from "ngx-custom-validators";
import { NgbButtonLabel, NgbButtonsModule, NgbModule, NgbRadioGroup } from '@ng-bootstrap/ng-bootstrap';
import { ArchwizardModule } from "angular-archwizard";
import { ToastyModule } from "ng2-toasty";
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormSecurityRoleComponent } from './form-security-role/form-security-role.component';
import { MatTree, MatTreeModule } from '@angular/material/tree'
import { MatIconModule } from '@angular/material/icon'
import { MatCheckboxModule } from '@angular/material/checkbox'


@NgModule({
  imports: [
    CommonModule,
    SecurityRolesRoutingModule,
    SharedModule,
    FormsModule,
    DataTablesModule,
    CustomFormsModule,
    ArchwizardModule,
    ToastyModule,
    NgbModule,
    NgbButtonsModule,
    MatDialogModule,
    MatTreeModule,
    MatIconModule,
    MatCheckboxModule,
  ],
  declarations: [
    SecurityRolesComponent,
    FormSecurityRoleComponent,
  ],
  providers: [
    MatDialog,
    {
      provide: MAT_DIALOG_DATA,
      useValue: {}
    },
    {
      provide: MatDialogRef,
      useValue: {}
    },
    NgbRadioGroup,
    NgbButtonLabel,
  ],
})
export class SecurityRolesModule { }
