import { Component, DoCheck, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { animate, style, transition, trigger } from '@angular/animations';
import { DattaConfig } from '../../../../../app-config';
import { StorageService } from '../../../../../../api/service/storage.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProfileManagerComponent } from './profile-manager/profile-manager.component';
import { Service } from 'src/api/service/Service';
import { FeedTarget, FeesResponse } from 'src/api/client/data-contracts';
import { Router } from '@angular/router';
import { LoadingRerouteModalComponent } from 'src/app/theme/shared/components/loading-reroute-modal/loading-reroute-modal.component';

export interface feedViewModel {
  id?: string,
  title?: string,
  description?: string,
  publisher?: {
    id?: string,
    name?: string,
    icon?: {
      href?: string,
      type?: string,
      description?: string,
      id?: string,
    }
  },
  read?: boolean,
  target?: {
    id?: string,
    type?: string,
    child?: FeedTarget,
  },
  timeSinceCreated?: string,
  createdAt?: string,
}

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig],
  animations: [
    trigger('slideInOutLeft', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(100%)' }))
      ])
    ]),
    trigger('slideInOutRight', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class NavRightComponent implements OnInit, DoCheck {

  position = 'top-center';
  public visibleUserList: boolean;
  public chatMessage: boolean;
  public friendId: boolean;
  public dattaConfig: any;
  public name: String;
  public avatar: String;
  contactFeeds: feedViewModel[] = [];
  unreadFeeds: number = 0;
  months = [
    'Ene',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sep',
    'Oct',
    'Nov',
    'Dic',
  ];
  feedsLimit: number = 5;
  loadingModal: MatDialogRef<any, any>;

  constructor(
    private storageService: StorageService,
    private dialog: MatDialog,
    private router: Router,
    config: NgbDropdownConfig,
  ) {
    config.placement = 'bottom-right';
    this.visibleUserList = false;
    this.chatMessage = false;
    this.dattaConfig = DattaConfig.config;
  }

  public ngOnInit() {
    const service = new Service(this.storageService);
    this.name = this.storageService.getCurrentUser().full_name;
    this.avatar = this.storageService.getCurrentUser().avatar.href;
    service.getContactFeeds(this.storageService.getCurrentUser().contact_id)
      .then(feeds => {
        this.renderFeedsToShow(feeds as FeesResponse[]);
      });
  }

  onChatToggle(friend_id) {
    this.friendId = friend_id;
    this.chatMessage = !this.chatMessage;
  }

  ngDoCheck() {
    if (document.querySelector('body').classList.contains('datta-rtl')) {
      this.dattaConfig['rtl-layout'] = true;
    } else {
      this.dattaConfig['rtl-layout'] = false;
    }
  }

  renderFeedsToShow(feeds: FeesResponse[]) {
    this.contactFeeds = [];
    this.feedsLimit = 5;
    this.unreadFeeds = 0;
    feeds = feeds.sort((a, b) => {
      if (new Date(b.createdAt) < new Date(a.createdAt)) return -1;
      else if (new Date(b.createdAt) > new Date(a.createdAt)) return 1;
      else return 0;
    });
    feeds.forEach(feed => {
      !feed.read && this.unreadFeeds++;
      const timeSinceCreated = this.getTimeSinceCreated(feed.createdAt);
      this.contactFeeds.push({
        ...feed,
        timeSinceCreated,
      })
    });
    if (this.feedsLimit > this.contactFeeds.length) {
      this.feedsLimit = this.contactFeeds.length
    }
  }

  public getTimeSinceCreated(createdAt: string): string {
    const moment = require('moment');
    // TODO: enable later to switch based on list https://gist.github.com/diogocapela/12c6617fc87607d11fd62d2a4f42b02a
    const from = moment(createdAt).add(-7, 'hours');
    const now = moment(new Date());
    const diff = now.diff(from, 'hours');
    if (diff <= 0) { // less than  1 hour
      const minsDiff = now.diff(from, 'minutes');
      return `${minsDiff} mins`;
    }
    if (diff <= 24) { // less than  1 hour
      const hoursDiff = now.diff(from, 'hours');
      return `${hoursDiff} hrs`;
    }

    const daysDiff = now.diff(from, 'days');
    return `${daysDiff} dias`;
  }

  async goToFeed(feed: feedViewModel) {
    const service = new Service(this.storageService);
    switch (feed.target.type) {
      case 'NOTHING':
        await service.markFeedAsRead(feed.id, this.storageService.getCurrentUser().contact_id);
        break;
      case 'MESSAGE':
        this.loadingModal = this.dialog.open(LoadingRerouteModalComponent, {
          height: '30%',
          width: '25%'
        });
        await service.markFeedAsRead(feed.id, this.storageService.getCurrentUser().contact_id);
        const messagesUrl = `menu/messages`;
        this.router.navigate([messagesUrl, feed.target.id]);
        setTimeout(() => {
          this.loadingModal.close();
        }, 1500);
        break;
      case 'ACTIVITY':
        this.loadingModal = this.dialog.open(LoadingRerouteModalComponent, {
          height: '30%',
          width: '25%'
        });
        await service.markFeedAsRead(feed.id, this.storageService.getCurrentUser().contact_id);
        const activitiesUrl = `menu/content/my-courses/form-content/activity`;
        this.router.navigate([activitiesUrl], { queryParams: { id: feed.target.id } });
        setTimeout(() => {
          this.loadingModal.close();
        }, 1500);
        break;
      case 'RESOURCE':
        this.loadingModal = this.dialog.open(LoadingRerouteModalComponent, {
          height: '30%',
          width: '25%'
        });
        await service.markFeedAsRead(feed.id, this.storageService.getCurrentUser().contact_id);
        const resourceUrl = `menu/content/my-courses/form-content`;
        this.router.navigate([resourceUrl], { queryParams: { id: feed.target.id } });
        setTimeout(() => {
          this.loadingModal.close();
        }, 1500);
        break;
      default:
        break;
    }
    service.getContactFeeds(this.storageService.getCurrentUser().contact_id)
      .then(feeds => {
        this.renderFeedsToShow(feeds as FeesResponse[]);
      });
  }

  showMoreFeeds() {
    if (this.feedsLimit + 5 < this.contactFeeds.length) {
      this.feedsLimit += 5;
    } else {
      this.feedsLimit = this.contactFeeds.length;
    }
  }

  openProfileModal() {
    const loggedUser = this.storageService.getCurrentUser();
    this.dialog.open(ProfileManagerComponent, {
      data: loggedUser,
      height: '85%',
      width: '70%'
    }).afterClosed()
      .subscribe(updated => {
        if (updated) {
          const service = new Service(this.storageService);
          service.getOneContact(loggedUser.contact_id).then(contact => {
            this.storageService.updateLoggedUser(contact);
            this.name = this.storageService.getCurrentUser().full_name;
            this.avatar = this.storageService.getCurrentUser().avatar.href;
          });
        }
      });
  }

  logout() {
    this.storageService.logout();
  }

}
