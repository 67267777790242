import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../theme/shared/shared.module';
import { NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { FormsModule } from '@angular/forms';
import { CustomFormsModule } from 'ngx-custom-validators';
import { ArchwizardModule } from 'angular-archwizard';
import { ToastyModule } from "ng2-toasty";
import { ContentRoutingModule } from './content-routing.module';
import { ContentComponent } from "./content.component";
import { TreeviewModule } from "ngx-treeview";
import { TreeEdukModule } from '../shared/tree/tree.module';
import { TreeEduk2Module } from '../shared/tree2/tree.module';
import { ScreenLoadingModule } from '../shared/screenLoading/screenLoading.module';
import { QuestionFormModule } from '../shared/questionForm/questionForm.module';
import { NotesFormModule } from '../shared/notesForm/notesForm.module';
import { FormSpaceComponent } from "./form-space/form-space.component";
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { CardSpaceComponent } from './card-space/card-space.component';
import { MatCardModule } from '@angular/material/card';
import { DragDirective } from './drag.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormActivitiesComponent } from "./form-content/form-activities/form-activities.component";
import { FormResourcesComponent } from './form-content/form-resurces/form-resources.component';
import { CardSpaceFormComponent } from './form-content/card-space-form/card-space-form.component';
import { ActivityTaskComponent } from './form-content/activity-task/activity-task.component';
import { FormContentComponent } from './form-content/form-content.component';
import { ContentCoursesComponent } from './content-courses/content-courses.component';
import { FormContentCoursesComponent } from './form-content-courses/form-content-courses.component';
import { CoursesUploadComponent } from './courses-upload/courses-upload.component';
import { ContactAssignManagerComponent } from './contact-assign-manager/contact-assign-manager.component';
import { CardResourceFormComponent } from './form-content/card-resource-form/card-resource-form.component';
import { ScoresManagerComponent } from './form-content/scores-manager/scores-manager.component';
import { DeleteScoreModalComponent } from './form-content/delete-score-modal/delete-score-modal.component';
import {FormattedDateComponent} from '../shared/formatted-date/formatted-date.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import {EnumToArrayPipe} from '../shared/pipes/EnumToArray';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        CommonModule,
        ContentRoutingModule,
        SharedModule,
        FormsModule,
        DataTablesModule,
        CustomFormsModule,
        TreeEdukModule,
        NgbAccordionModule,
        TreeEduk2Module,
        NgxMaterialTimepickerModule,
        ScreenLoadingModule,
        QuestionFormModule,
        NotesFormModule,
        NgbTabsetModule,
        ArchwizardModule,
        ToastyModule,
        TreeviewModule,
        MatTreeModule,
        MatIconModule,
        MatCardModule,
        NgbModule,
        NgxSpinnerModule,
    ],
    exports: [
        FormattedDateComponent
    ],
    declarations: [
        ContentComponent,
        FormSpaceComponent,
        CardSpaceComponent,
        DragDirective,
        FormContentComponent,
        FormActivitiesComponent,
        FormResourcesComponent,
        CardSpaceFormComponent,
        ActivityTaskComponent,
        ContentCoursesComponent,
        FormContentCoursesComponent,
        CoursesUploadComponent,
        ContactAssignManagerComponent,
        CardResourceFormComponent,
        ScoresManagerComponent,
        DeleteScoreModalComponent,
        FormattedDateComponent,
        EnumToArrayPipe
    ]
})
export class ContentModule { }

export interface SelectItem{
    key: string;
    value: string;
}
