import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastOptions, ToastyService } from 'ng2-toasty';
import { ContactResponse } from 'src/api/client/data-contracts';
import { Service } from 'src/api/service/Service';
import { StorageService } from 'src/api/service/storage.service';
import { SpaceNodeFlat } from 'src/util/space';

@Component({
  selector: 'app-contact-assign-manager',
  templateUrl: './contact-assign-manager.component.html',
  styleUrls: ['./contact-assign-manager.component.scss']
})
export class ContactAssignManagerComponent implements OnInit {

  selectedContacts: string[] = [];
  contacts: ContactResponse[];
  contactsToUnassign: string[] = [];
  loading: boolean = false;
  selectedSpace: SpaceNodeFlat;
  spaceIdsToRelate: string[] = [];
  noChildrenError: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public assignInfo: {
      allContacts: ContactResponse[],
      assignedContacts: string[],
      selectedSpace: SpaceNodeFlat,
      title: string,
    },
    private dialogRef: MatDialogRef<ContactAssignManagerComponent>,
    private storageService: StorageService,
    private toastyService: ToastyService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.contacts = this.assignInfo.allContacts;
    this.selectedSpace = this.assignInfo.selectedSpace;
    if (this.selectedSpace.type !== 'CLASSROOM') {
      const service = new Service(this.storageService);
      const newSpaceChildren: SpaceNodeFlat[] = await service.getSpacesForContactsAssign(this.selectedSpace.id) as SpaceNodeFlat[];
      if (newSpaceChildren && newSpaceChildren.length >= 1) {
        newSpaceChildren.forEach(spaceChild => {
          if (spaceChild.type === 'CLASSROOM') this.spaceIdsToRelate.push(spaceChild.id);
        });
        this.selectedSpace.children = await this.constructSpaceAndChildren(newSpaceChildren);
      } else {
        this.noChildrenError = true;
      }
    } else {
      this.spaceIdsToRelate.push(this.selectedSpace.id);
    }
  }

  async constructSpaceAndChildren(spaces: SpaceNodeFlat[]) {
    const children: SpaceNodeFlat[] = await Promise.all(
      spaces.map(async (space) => ({
        ...space,
        children: await this.mapSpaceChildren(space.id),
      }))
    );
    return children;
  }

  async mapSpaceChildren(parentSpaceId: string): Promise<any[]> {
    const service = new Service(this.storageService);
    const newSpaceChildren: SpaceNodeFlat[] = await service.getSpacesForContactsAssign(parentSpaceId) as SpaceNodeFlat[];
    if (newSpaceChildren && newSpaceChildren.length >= 1) {
      const spaceChildrenToReturn: SpaceNodeFlat[] = await Promise.all(
        newSpaceChildren.map(async (space) => ({
          ...space,
          spaceChildren: await this.mapSpaceChildren(space.id),
        }))
      );
      spaceChildrenToReturn.forEach(spaceChild => {
        if (spaceChild.type === 'CLASSROOM') this.spaceIdsToRelate.push(spaceChild.id);
      });
      return spaceChildrenToReturn;
    } else {
      return [];
    }
  }

  async associate() {
    this.loading = true;
    this.toastyService.clearAll();
    var toastOptions: ToastOptions = {
      title: "Espere",
      msg: "Guardando registro",
      timeout: 5000,
      theme: "default"
    };
    this.toastyService.wait(toastOptions);
    const studentAssign = {
      app_id: 'admin-ui',
      data: {
        contacts: this.selectedContacts
      }
    };
    const service = new Service(this.storageService);
    const errors: string[] = [];
    for (let spaceId of this.spaceIdsToRelate) {
      try {
        if (this.selectedContacts && this.selectedContacts.length > 0) {
          await service.assignSpace(spaceId, studentAssign);
        }
      } catch (err) {
        errors.push(err);
      }
      try {
        if (this.contactsToUnassign && this.contactsToUnassign.length > 0) {
          await service.unassignContactsStudent(spaceId, this.contactsToUnassign);
        }
      } catch (err) {
        errors.push(err);
      }
    }
    if (errors.length === 0) {
      this.toastyService.clearAll();
      var toastOptions: ToastOptions = {
        title: "Exito",
        msg: "Asignación realizada exitosamente.",
        timeout: 3000,
        theme: "default"
      };
      this.toastyService.info(toastOptions);
      this.close();
    } else {
      let msg: string;
      errors.forEach(error => {
        msg += `${error}, `;
      });
      this.toastyService.clearAll();
      var toastOptions: ToastOptions = {
        title: "Error",
        msg,
        timeout: 3000,
        theme: "default"
      };
      this.toastyService.error(toastOptions);
      this.loading = false;
    }
  }

  changeCheckbox(student: ContactResponse, checked: boolean) {
    if (checked) {
      const foundInAlreadyAssigned = this.assignInfo.assignedContacts.indexOf(student.id);
      const foundInUnassigned = this.contactsToUnassign.indexOf(student.id);
      if (foundInAlreadyAssigned < 0) this.selectedContacts.push(student.id);
      if (foundInUnassigned >= 0) this.contactsToUnassign.splice(foundInUnassigned, 1);
    } else {
      const index = this.selectedContacts.indexOf(student.id);
      const foundInAlreadyAssigned = this.assignInfo.assignedContacts.indexOf(student.id);
      if (foundInAlreadyAssigned >= 0) this.contactsToUnassign.push(student.id);
      if (index >= 0) this.selectedContacts.splice(index, 1);
    }
  }

  search(searchFor: string) {
    if (searchFor !== '') {
      this.contacts = this.assignInfo.allContacts.filter(contact =>
        contact.names.toLowerCase().includes(searchFor.toLowerCase())
        || contact.last_names.toLowerCase().includes(searchFor.toLowerCase()));
    } else {
      this.contacts = this.assignInfo.allContacts;
    }
  }

  close() {
    this.dialogRef.close();
  }

}