import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SecurityRolesComponent } from './security-roles/security-roles.component'

const routes: Routes = [
  {
    path: '',
    component: SecurityRolesComponent,
    data: {
      breadcrumb: 'Roles de Seguridad'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SecurityRolesRoutingModule { }