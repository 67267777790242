import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-courses',
  templateUrl: './content-courses.component.html',
  styleUrls: ['./content-courses.component.scss']
})
export class ContentCoursesComponent implements OnInit {

  position = 'top-center';


  constructor() { }

  ngOnInit(): void {
  }

}
