import { Component, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { Breadcrumb } from '../breadcrumb/breadcrumb.model';
import { BreadcrumbService } from '../../../../services/breadcrumb.service'
import { NavigationExtras, Router } from '@angular/router'

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {

  breadcrumbs$: Observable<Breadcrumb[]>; 
  breadcrumbs: Breadcrumb[] = [];

  @Input() type: string;

  constructor(private titleService: Title, private readonly breadcrumbService: BreadcrumbService, private router: Router) {
    this.type = 'theme2';
    this.breadcrumbs$ = breadcrumbService.breadcrumbs$;
    this.breadcrumbs$.subscribe(
      (breadcrumbs) => {
        this.breadcrumbs = breadcrumbs;
      }
    )
    this.titleService.setTitle('Plataforma Edukcare');
  }

  navigate(breadcrumb: Breadcrumb) {
    if (breadcrumb.id && breadcrumb.id !== '') {
      const navigationExtras: NavigationExtras = {
        queryParams: { 'id': breadcrumb.id },
      };
      this.router.navigate([breadcrumb.url], navigationExtras);
    } else {
      this.router.navigate([breadcrumb.url]);
    }
  }

}