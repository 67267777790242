import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastOptions, ToastyService } from 'ng2-toasty';
import { ContactResponse, CycleUpdateRequest, ScoreLoadResquest, ScoreResquest, UnitsInput } from 'src/api/client/data-contracts';
import { Service } from 'src/api/service/Service';
import { StorageService } from 'src/api/service/storage.service';

const permittedFiles = [
  'txt',
  'csv',
  'xls',
  'xml',
  'text',
  'xlsm',
  'xlsx',
  'xltx',
  'xltm',
  'text/csv',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

@Component({
  selector: 'app-scores-manager',
  templateUrl: './scores-manager.component.html',
  styleUrls: ['./scores-manager.component.scss']
})
export class ScoresManagerComponent implements OnInit {

  title: string = 'Subiendo notas';
  subTitle = '';
  scoreHandling = '';
  mode: 'create' | 'update' = 'create';
  form: FormGroup;
  loading: boolean = false;
  scoresToSend: ScoreResquest[] = [];
  scores: FormArray;
  fileUploaded: boolean = false;
  noCycleError: boolean = false;
  noStudentsError: boolean = false;
  scoresFileSelected: boolean = false;
  currentCycle: CycleUpdateRequest;
  cycleUnits: UnitsInput[] = [];
  scoresFiles: File[];
  selectedScore: ScoreLoadResquest;

  constructor(
    @Inject(MAT_DIALOG_DATA) public scoreData: {
      spaceId?: string,
      score?: any,
      students?: ContactResponse[],
      existingUnits?: number[],
    },
    private dialogRef: MatDialogRef<ScoresManagerComponent>,
    private storageService: StorageService,
    private toastyService: ToastyService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    const service = new Service(this.storageService);
    service.getSpaceBreadCrumb(this.scoreData.spaceId).then(value => {
      this.subTitle = value.bread_crumb;
    });
    if (Boolean(this.scoreData?.score?.id)) {
      this.mode = 'update';
      this.title = `Notas para la Unidad ${this.scoreData?.score?.unit?.unit}`;
      this.getScoreInfo();
      this.getActiveCycleAndUnits();
    }
    if (this.mode === 'create' && (this.scoreData?.students && this.scoreData?.students.length)) {
      this.buildForm();
      this.getActiveCycleAndUnits();
    } else {
      this.noStudentsError = true;
    }
  }

  getActiveCycleAndUnits() {
    const service = new Service(this.storageService);
    service.getActiveCycleBySpaceOrAssigment(this.scoreData.spaceId).then((cycle: CycleUpdateRequest) => {
      if (Boolean(cycle)) {
        this.currentCycle = cycle[0];
        this.scoreHandling = this.currentCycle.score_handling != null ? 'Base de cálculo: ' + (this.currentCycle.score_handling === 'SUM' ? 'Suma' : 'Promedio') : '';
        service.getCycleUnits(this.currentCycle.id)
          .then((units: UnitsInput[]) => {
            this.cycleUnits = units.sort((a, b) => a.unit - b.unit)
                .filter(unit => !this.scoreData.existingUnits.includes(unit.unit));
          });
      } else {
        this.noCycleError = true;
        this.toastyService.clearAll();
        var toastOptions: ToastOptions = {
          title: "Espere",
          msg: "No hay ciclo activo",
          timeout: 5000,
          theme: "default"
        };
        this.toastyService.error(toastOptions);
      }
    }).catch(err => {
      this.noCycleError = true;
      this.toastyService.clearAll();
      var toastOptions: ToastOptions = {
        title: "Espere",
        msg: "No hay ciclo activo",
        timeout: 5000,
        theme: "default"
      };
      this.toastyService.error(toastOptions);
      console.log("Err:", err);
    });
  }

  getScoreInfo() {
    const service = new Service(this.storageService);
    service.getScoreInfo(this.scoreData.spaceId, this.scoreData.score.id)
      .then(score => {
        this.selectedScore = {
          ...score,
          unit: this.scoreData.score.unit,
        }
      });
  }

  buildForm() {
    this.form = this.fb.group({
      unit: ['', [Validators.required]],
      score_type: ['MANUAL', [Validators.required]],
      students_scores: this.fb.array(this.studentsToArrayGroup(this.scoreData.students || [])),
    });
    this.scores = this.form.get('students_scores') as FormArray;
  }

  studentsToArrayGroup = students => students.map(s => this.toStudentGroup(s));

  toStudentGroup(student: ContactResponse) {
    return this.fb.group({
      contact_id: [student.id || null],
      personal_id: [student.personal_id || null],
      school_id: [student.school_id || null],
      names: [`${student.names} ${student.last_names}` || null],
      score: [0, [Validators.required, Validators.min(0), Validators.max(100)]],
      comment: [''],
    });
  }

  saveScore(isUpload = false) {
    this.loading = true;
    this.toastyService.clearAll();
    var toastOptions: ToastOptions = {
      title: "Espere",
      msg: "Guardando registro",
      timeout: 5000,
      theme: "default"
    };
    this.toastyService.wait(toastOptions);
    if (isUpload) {
      //this.uploadScoresFiles();
    } else {
      const service = new Service(this.storageService);
      const scoreSaveRequest: ScoreLoadResquest & { failed_description?: string } = {
        notes: this.renderScores(),
        status: 'LOADED',
        //failed_description: 'WHY HERE??'
      }
      service.createManualScoreLoad(this.scoreData.spaceId, scoreSaveRequest).then(() => {
        this.toastyService.clearAll();
        var toastOptions: ToastOptions = {
          title: "Exito",
          msg: "Notas subidas exitosamente.",
          timeout: 3000,
          theme: "default"
        };
        this.toastyService.info(toastOptions);
        this.loading = false;
        this.closeModal();
      }).catch(reason => {
        this.loading = false;
        this.toastyService.clearAll();
        var toastOptions: ToastOptions = {
          title: "Error",
          msg: reason.message,
          timeout: 5000,
          theme: "default"
        };
        this.toastyService.error(toastOptions);
        this.loading = false;
      });
    }
  }

  validateScoresFiles($event) {
    this.toastyService.clearAll();
    const toastOptions: ToastOptions = {
      title: 'Espere',
      msg: 'Validando...',
      timeout: 5000,
      theme: 'default'
    };
    this.toastyService.wait(toastOptions);
    const files: File[] = [];
    const fileList: FileList = $event.target.files;
    for (let i = 0; i < fileList.length; i++) {
      files.push(fileList[i]);
    }
    const notPermittedFiles = files.filter(file => !permittedFiles.includes(file.type));
    if (notPermittedFiles.length < 1) {
      this.scoresFileSelected = true;
      this.scoresFiles = files;
      setTimeout(() => {
        this.toastyService.clearAll();
      }, 4000);
    } else {
      let msg: string = 'Tipos de archivo "';
      const retrictedFileTypesArr = notPermittedFiles.map(file => file.type);
      const retrictedFileTypes = retrictedFileTypesArr.join(', ');
      msg += `${retrictedFileTypes}" no permitidos.`
      this.scoresFileSelected = false;
      this.toastyService.clearAll();
      const toastOptions: ToastOptions = {
        title: 'Error',
        msg,
        timeout: 5000,
        theme: 'default'
      };
      this.toastyService.error(toastOptions);
    }
  }

  uploadScoresFiles() {
    const service = new Service(this.storageService);
    this.scoresFiles.forEach(file => {
      //TODO Change this for correct service once implemented
      service.sendFileForUploadAuth(file, '').then(() => {
        this.toastyService.clearAll();
        var toastOptions: ToastOptions = {
          title: "Exito",
          msg: "Notas subidas exitosamente.",
          timeout: 3000,
          theme: "default"
        };
        this.toastyService.info(toastOptions);
        this.loading = false;
        this.closeModal();
      }).catch(reason => {
        this.loading = false;
        this.toastyService.clearAll();
        const toastOptions: ToastOptions = {
          title: 'Error',
          msg: reason.message,
          timeout: 5000,
          theme: 'default'
        };
        this.toastyService.error(toastOptions);
      });
    });
  }

  renderScores() {
    const { students_scores }: { students_scores: any[] } = this.form.value;
    this.scoresToSend = students_scores.map(score => {
      let renderedScore: ScoreResquest & { failed_description?: string } = {
        activity_id: this.scoreData.spaceId,
        contact_id: score.contact_id,
        origin: 'MANUAL',
        scholar_cycle_unit_id: this.form.get('unit').value,
        score: score.score,
        //failed_description: score.comment,
      }
      return renderedScore;
    });
    return this.scoresToSend;
  }

  getScoreOption(index) {
    console.log('getScoreOption',this.scores.at(index).value);
    const unit = this.scores.at(index);
    return unit;
  }

  closeModal() {
    this.dialogRef.close();
  }

}
