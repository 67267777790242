import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GrantsService } from 'src/app/services/grants.service';
import {BranchResponse} from '../../../api/client/data-contracts';

@Component({
  selector: 'app-card-space',
  templateUrl: './card-space.component.html',
  styleUrls: ['./card-space.component.scss']
})
export class CardSpaceComponent implements OnInit {

  @Output() updateSpace: EventEmitter<void> = new EventEmitter();
  @Output() asign: EventEmitter<string> = new EventEmitter();
  @Output() select: EventEmitter<void> = new EventEmitter();
  @Output() delete: EventEmitter<void> = new EventEmitter();
  @Input() name: string;
  @Input() description: string;
  @Input() branch: string;
  @Input() avatar: string;
  @Input() type: string;

  constructor(
    public grantsService: GrantsService,
  ) { }

  ngOnInit(): void {
  }

  update() {
    this.updateSpace.emit();
  }

  selected() {
    this.select.emit();
  }

  getIcon(type: string): string {
    switch (type) {
      case 'CLASSROOM':
        return 'import_contacts';
      case 'CATEGORY':
        return 'grid_on';
      case 'GRADE':
        return 'class';
      case 'CAREER':
        return 'school';
      case 'SECTION':
        return 'view_headline';
      case 'INSTITUTION':
        return 'account_balance';
    }
  }

  assign(type) {
    this.asign.emit(type);
  }

  deleteSpace() {
    this.delete.emit();
  }

}
