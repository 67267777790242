<form #validationQuestion="ngForm" class="editForm" novalidate>
    <div class="row">
        <div class="col-md-6 inputs">
            <label class="form-label">Pregunta:</label>
            <input
                type="text"
                class="form-control"
                name="question"
                id="question"
                #question="ngModel"
                [(ngModel)]="formInputQuestion.title"
                (ngModelChange)="onChange($event)"
                [ngClass]="{'is-invalid': !question.valid && (question.dirty || question.touched )}"
                placeholder="Pregunta"
                required>
            <label
                *ngIf="!question.valid && (question.dirty || question.touched )"
                id="validation-question-error"
                class="error jquery-validation-error small form-text invalid-feedback">
                es necesario describir una pregunta!
            </label>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 inputs">
            <label class="form-label">Nota*</label>
            <input
                    type="number"
                    class="form-control"
                    name="score"
                    id="score"
                    [min]="0"
                    [max]="100"
                    #score="ngModel"
                    [(ngModel)]="formInputQuestion.score"
                    [ngClass]="{'is-invalid': !score.valid && (score.dirty || score.touched )}"
                    placeholder="nota"
                    required>
            <label
                    *ngIf="!score.valid && (score.dirty || score.touched )"
                    id="validation-score-error"
                    class="error jquery-validation-error small form-text invalid-feedback">
                Los puntos deben ser entre 0-100
            </label>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6 inputCheckChoices">
            <input
                    class="form-check-input"
                    type="checkbox"
                    id="multipleChoice"
                    name="multipleChoice"
                    (change)="changeMultipleC()"
                    #choices="ngModel"
                    [(ngModel)]="formInputQuestion.multipleChoice">
            <label class="form-check-label" for="multipleChoice">
                Multiples respuestas:
            </label>
        </div>
    </div>
</form>
<form *ngIf="formInputQuestion.multipleChoice" #validationAnswer="ngForm" novalidate>
    <div class="row">
        <div class="col-md-12 wrapperChoices">
            <section class="headerChoices">
                <p class="addChoiceTxt">Agrega respuestas a tu pregunta.</p>
            </section>
            <div class="row  mt-3">
                <div class="col-md-6">
                    <div>
                        <label class="form-label" for="answer">Respuesta</label>
                        <input
                                type="text"
                                class="form-control"
                                name="answer"
                                id="answer"
                                #answer="ngModel"
                                [(ngModel)]="formAnswer.answer"
                                [ngClass]="{'is-invalid': !answer.valid && (answer.dirty || answer.touched )}"
                                placeholder="Respuesta"
                                required>
                        <label
                                *ngIf="!answer.valid && (answer.dirty || answer.touched )"
                                id="validation-score-error"
                                class="error jquery-validation-error small form-text invalid-feedback">
                            ingresar una respuesta!
                        </label>
                    </div>
                    <div style="padding-left: 21px; margin-top: 10px;">
                        <input
                                class="form-check-input"
                                type="checkbox"
                                id="right"
                                name="right"
                                #right="ngModel"
                                [(ngModel)]="formAnswer.isValid">
                        <label class="form-check-label " for="right">
                            Marcar como, Respuesta valida.
                        </label>
                    </div>
                </div>
                <div class="col-md-6 mt-3" style="padding-left: 23px;">
                    <button
                            type="button"
                            (click)="addAnswer()"
                            class="btn btn-primary active-button float-right mt-5"
                            [disabled]="!formAnswer.answer">
                        Agregar Respuesta!
                    </button>
                </div>
            </div>

            <section class="listChoicesWrapper">
                <p class="listChoices">Listado de respuetas agregadas.</p>
                <ol>
                    <li *ngFor="let a of Answers; let i = index">
                        <div class="choice">{{a.title}}</div>
                        <div>{{a.right ? 'Respuesta valida' : 'Invalida'}}</div>
                        <span (click)="removeAnswer(a)"><i class="feather icon-trash-2"></i></span>
                    </li>
                </ol>
                <div *ngIf="Answers.length <= 0" style="text-align: center">
                    <i class="feather icon-alert-octagon " style="font-size: 40px;"></i>
                    <div class="mb-4">No hay Respuestas!</div>
                </div>
            </section>
        </div>
    </div>
</form>
