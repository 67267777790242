import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentComponent } from "./content.component";
import {  ContentTypeResolverService } from "../services/content-type-resolver.service";
import { FormContentComponent } from "./form-content/form-content.component";
import { SpaceResolverService } from '../services/space-resolver.service';
import { ActivityResolverService } from '../services/activity-resolver.service';
import { ActivityTaskComponent } from './form-content/activity-task/activity-task.component'
import { ContentCoursesComponent } from './content-courses/content-courses.component'
import { FormContentCoursesComponent } from './form-content-courses/form-content-courses.component'

const routes: Routes = [
  {
    path: ':type',
    component: ContentCoursesComponent,
    data: {
      breadcrumb: (data: any) => {
        return data.type.title;
      },
      id: (data: any) => {
        const id = data.type.id;
        if (id) {
          return data.type.selected
        } else {
          return localStorage.getItem('selectedSpace')
        }
      }
    },
    resolve: { type: ContentTypeResolverService },
    children: [
      {
        path: '',
        component: ContentComponent,
        data: {
          breadcrumb: ''
        }
      },
      {
        path: 'form-content',
        component: FormContentCoursesComponent,
        data: {
          breadcrumb: (data: any) => {
            return data.spaceResolver.space ? data.spaceResolver.space.title : '';
          },
          id: (data: any) => {
            return data.spaceResolver.space ? data.spaceResolver.space.id : ''
          }
        },
        resolve: { spaceResolver: SpaceResolverService },
        children: [
          {
            path: '',
            component: FormContentComponent,
            data: {
              breadcrumb: ''
            }
          },
          {
            path: 'activity',
            component: ActivityTaskComponent,
            data: {
              breadcrumb: (data: any) => {
                return data.activity.title;
              },
              id: (data: any) => {
                return data.activity ? data.activity.id : '';
              }
            },
            resolve: { activity: ActivityResolverService },
          }
        ]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContentRoutingModule { }
