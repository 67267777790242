import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthSigninComponent } from './auth-signin.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

const routes: Routes = [
  {
    path: '',
    component: AuthSigninComponent
  },
  {
    path: 'auth/forgot-password',
    component: ResetPasswordComponent
  },
  {
    path: 'auth/reset-password',
    children: [
      {
        path: '',
        component: ChangePasswordComponent,
      },
      {
        path: ':hash_token',
        component: ChangePasswordComponent,
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthSigninRoutingModule { }