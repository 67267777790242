import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Service } from 'src/api/service/Service';
import { DatesService } from 'src/api/service/dates.service';
import { StorageService } from 'src/api/service/storage.service';
import { DattaConfig } from '../../../../app-config';
import { CycleResponse, CycleUpdateRequest, SpaceRequest } from 'src/api/client/data-contracts';
import { GrantsService } from 'src/app/services/grants.service';
import { filter } from 'rxjs/operators';

export interface NotificationsVM {
  text?: string,
  boldText?: string,
  faIcon?: string,
  url?: string,
  relatedEntity?: any,
  colorClass?: string,
}

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {

  @Output() onNavCollapsedMob = new EventEmitter();

  public dattaConfig: any;
  public navCollapsedMob;
  public headerStyle: string;
  public menuClass: boolean;
  public collapseStyle: string;
  public alertNotifications: NotificationsVM[] = [];

  constructor(
    private router: Router,
    private storageService: StorageService
  ) {
    this.dattaConfig = DattaConfig.config;
    this.navCollapsedMob = false;
    this.headerStyle = '';
    this.menuClass = false;
    this.collapseStyle = 'none';
  }

  ngOnInit() {
    this.renderAlerts();
  }

  toggleMobOption() {
    this.menuClass = !this.menuClass;
    this.headerStyle = (this.menuClass) ? 'none' : '';
    this.collapseStyle = (this.menuClass) ? 'block' : 'none';
  }


  renderAlerts(){
    this.storageService.getCurrentSession().alerts?.forEach(value => {
      this.alertNotifications.push({
        relatedEntity: value.url,
        boldText: value.title,
        text: value.message,
        url: value.url,
        faIcon: value.title === 'URGENTE' ? 'fas fa-exclamation-triangle' : '',
        colorClass: value.title === 'URGENTE' ? 'individual-alert-red' : 'individual-alert-yellow'
      });
      console.log(value);
    });
  }

  goToAlert(alertUrl: string) {
    if(alertUrl.startsWith("http")){
      window.open(alertUrl, "_blank");
    }else{
      alertUrl && this.router.navigate([alertUrl]);
    }
  }

  deleteAlert(alert) {
    const alertIndex = this.alertNotifications.indexOf(alert);
    this.alertNotifications.splice(alertIndex, 1);
  }

}
