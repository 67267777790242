<div class="row">
    <div class="col">
        <div class="row">
            <div class="col text-right">
                <div class="add-edit-buttons">
                    <button class="btn btn-primary" (click)="createUpdate()">
                        Agregar  <i class="fas fa-plus f-14 mr-0"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<app-card [hidHeader]="true" [options]="false" cardClass="msg-card mb-0" blockClass="card-body msg-block">
    <div style="overflow: overlay;">
        <table class="table">
            <thead>
                <tr>
                    <th class="table-title">Código</th>
                    <th class="table-title">Nombre</th>
                    <th class="table-title">Estado</th>
                    <th class="table-title">Fecha Creación</th>
                    <th class="table-title">Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let securityRole of securityRoles | slice: (page-1) * 10 : page * 10" class="table-row">
                    <td>
                        <h6 class="m-0">
                            {{securityRole.keyword}}
                        </h6>
                    </td>
                    <td>
                        <h6 class="m-0">
                            {{securityRole.name}}
                        </h6>
                    </td>
                    <td class="d-flex flex-row">
                        <h6 class="m-0" [ngClass]="{ 'inactive': securityRole.disabled, 'active': !securityRole.disabled }">
                            {{ securityRole.disabled ? 'DESHABILITADO' : 'HABILITADO' }}
                        </h6>
                    </td>
                    <td>
                        <h6 class="m-0">
                            {{securityRole.createdAt}}
                        </h6>
                    </td>
                    <td>
                        <button class="btn btn-sm btn-glow-success btn-success f-12"
                            (click)="createUpdate(securityRole)">Actualizar</button>
                        <button class="btn btn-sm btn-glow-danger btn-danger f-12"
                            (click)="deleteSecurityRoleShow(securityRole.id)">Eliminar</button>
                        <button class="btn btn-sm btn-glow-warning btn-warning f-12"
                                (click)="toggleEnabled(securityRole)">{{ securityRole.disabled ? 'Habilitar' : 'Deshabilitar' }}</button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="d-flex justify-content-center">
            <h5 *ngIf="securityRoles?.length === 0">No hay roles de seguridad configurados.</h5>
        </div>
        <div class="d-flex justify-content-center p-2 mb-3">
            <ngb-pagination *ngIf="securityRoles?.length > 0" [collectionSize]="securityRoles.length" [(page)]="page" [pageSize]="10">
            </ngb-pagination>
        </div>
    </div>
</app-card>

<app-ui-modal #modalCreate [dialogClass]="'modal-lg'">
    <div class="app-modal-header">
        <h5 class="modal-title">{{ createUpdateTitle }} Rol de Seguridad {{ titleSelected === '' ? "" : " - " + titleSelected}}</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" 
            aria-label="Close" 
            (click)="modalCreate.hide()">
                <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="app-modal-body">
        <div class="col-sm-12 ">
            <app-form-security-role 
                #modalForm id="modalForm"
                (securityRolesChanged)="addSecurityRoleChanged($event)"
                (close)="closeModalForm()"></app-form-security-role>
        </div>
    </div>
</app-ui-modal>
<app-ui-modal #modalDelete [dialogClass]="'modal-lg'">
    <div class="app-modal-header">
      <h5 class="modal-title">Eliminar Rol de Seguridad</h5>
      <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
        (click)="modalDelete.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
      <p>¿Está seguro que desea eliminar el rol de seguridad?</p>
    </div>
    <div class="app-modal-footer">
      <button type="button" class="btn btn-secondary" data-dismiss="modal"
        (click)="modalDelete.hide()">Cancelar</button>
      <button type="button" class="btn btn-primary" (click)="deleteSecurityRole()">Aceptar</button>
    </div>
  </app-ui-modal>

<ng2-toasty [position]="position"></ng2-toasty>