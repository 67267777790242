<form #validationQuestion="ngForm" class="editForm" novalidate>
    <div class="row">
        <div class="col-md-12 inputs">
            <label *ngIf="answer?.question?.choices?.length <= 0 ; else message" class="form-label">Respuesta: {{responseQuiz}}</label>

            <ng-template #message>
                <p class="descriptionTitle">
                    ** Lista de respuestas opcion multiple.
                </p>
                <p class="descriptionTitle">
                    ** Si la respuesta es correcta se marca con un color Verde.
                </p>
                <p class="descriptionTitle">
                    ** Si la respuesta es incorrecta se marca con un color Rojo.
                </p>
            </ng-template>


            <div *ngFor="let ch of answer?.question?.choices" >
                <p [ngClass]="
                    answer.right && answer.choiceId === ch.id
                        ?
                          'setOption'
                        : !answer.right && answer.choiceId === ch.id
                            ? 'setOptionB'
                            : 'setOptionN'">
                    {{  ch.title }}
                </p>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="answer?.question?.choices?.length <= 0">
        <div class="col-md-12 inputs">
            <div class="form-check">
                <div *ngFor="let option of options">
                    <input class="form-check-input"
                           [(ngModel)]="resultQuestion"
                           [value]="option.id"
                           (ngModelChange)="this.changeOption()"
                           type="radio"
                           name="AnswareR"
                           id="flexRadioDefault1" checked>
                    <label class="form-check-label" for="flexRadioDefault1">
                        {{option.value}}
                    </label>
                </div>
            </div>
        </div>
    </div>
</form>
