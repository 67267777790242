<div class="rectangle">
  <div class="vector-3"></div>
  <div class="vector-2"></div>
  <div class="vector-1"></div>
  <div class="auth-wrapper">
    <div class="auth-content">
      <div class="card">
        <div class="card-body text-center">
          <h5 class="mb-4">{{title}}</h5>
          <div *ngIf="token">
            <form [formGroup]="form">
              <div class="input-group mb-3">
                <input [type]="newPassType" class="form-control" formControlName="password"
                  placeholder="Nueva contraseña">
                <button type="button" class="btn btn-icon btn-outline-secondary" (click)="toggleVisibility('newPass')">
                  <i *ngIf="newPassVisible" class="fa fa-eye mr-2" aria-hidden="true"></i>
                  <i *ngIf="!newPassVisible" class="fa fa-eye-slash mr-2" aria-hidden="true"></i>
                </button>
              </div>
              <div class="input-group mb-4">
                <input [type]="confPassType" class="form-control" formControlName="passwordConfirm"
                  placeholder="Confirma nueva contraseña">
                <button type="button" class="btn btn-icon btn-outline-secondary"
                  (click)="toggleVisibility('confirmPass')">
                  <i *ngIf="confPassVisible" class="fa fa-eye mr-2" aria-hidden="true"></i>
                  <i *ngIf="!confPassVisible" class="fa fa-eye-slash mr-2" aria-hidden="true"></i>
                </button>
              </div>
              <button class="btn btn-primary mb-4 mr-0" (click)="changePassword()">
                Cambiar Contraseña
              </button>
            </form>
          </div>
          <div *ngIf="!token">
            <button class="btn btn-primary mt-4 mr-0" (click)="cancel()">
              Ir a página de inicio
            </button>
          </div>
        </div>
      </div>
    </div>
    <ng2-toasty [position]="position"></ng2-toasty>
  </div>
</div>