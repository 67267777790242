import {Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef} from '@angular/core';
import {FormQuestion, FormAnswer, Choice} from '../../../api/models/question' ;
import { ToastOptions, ToastyService } from "ng2-toasty";
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-notes-form',
  templateUrl: './notesForm.component.html',
  styleUrls: ['./notesForm.component.scss']
})
export class NotesFormComponent implements OnInit {
  @Input() responseQuiz: string;
  @Input() answer: any;
  @Input() defaultValue: boolean;
  @Input() id: string;
  @Output() resultQuiz: EventEmitter<boolean> = new EventEmitter();

  resultQuestion = false;
  resultQuestionAry;

  options = [
    {value: 'Correcta', id: true},
    {value: 'Incorrecta', id: false},
  ];

  changeOption(): void{
    this.resultQuestionAry = {
      questionId: this.id,
      right: this.resultQuestion
    };
  }

  constructor( private toastyService: ToastyService, private changeDetectorRef: ChangeDetectorRef ) { }
  ngOnInit(): void {
    this.resultQuestionAry = {
      questionId: this.id,
      right: this.resultQuestion
    };
    this.resultQuestion = this.defaultValue;
  }

}
