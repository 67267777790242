<div class="app-modal-header">
    <h5 class="modal-title">Actualizar Alerta
        <button type="button" class="close basic-close" style="top: -5%;" data-dismiss="modal" aria-label="Close"
            (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </h5>
</div>
<div class="app-modal-body d-flex flex-row mt-2" style="max-height: 95%;">
    <div class="mr-5 h-50" style="flex: 1;">
        <app-card [hidHeader]="true" [options]="false">
            <div class="m-b-5">
                <h5>Tipo de Contenido:</h5>
                <hr>
                <p class="m-l-25">{{contentAlert?.contentType?.toUpperCase()}}</p>
            </div>
            <div *ngIf="alert.content_type === 'ACTIVITY'">
                <div class="m-b-5">
                    <h5>T&iacute;tulo:</h5>
                    <hr>
                    <p class="m-l-25">{{contentAlert?.alert?.title}}</p>
                </div>
                <div class="m-b-5">
                    <h5>Creada:</h5>
                    <hr>
                    <p class="m-l-25">{{contentAlert?.alert?.created_at}}</p>
                </div>
                <div class="m-b-5">
                    <h5>Descripci&oacute;n:</h5>
                    <hr>
                    <p class="m-l-25">{{contentAlert?.alert?.description ? contentAlert?.alert?.description :
                        'Sin descripci&oacute;n'}}</p>
                </div>
                <div class="m-b-5">
                    <h5>Comentarios</h5>
                    <hr>
                    <div class="ml-5" *ngIf="contentCommentsLog?.length > 0">
                        <div *ngFor="let comment of contentCommentsLog" class="mb-2">
                            <p class="font-weight-bold">
                                {{comment.created_by}}:
                            </p>
                            <br>
                            <p>
                                {{comment.comment}}
                            </p>
                            <br>
                            <p class="date-text align-self-end">
                                {{comment.created_at}}
                            </p>
                        </div>
                    </div>
                    <div class="ml-5" *ngIf="contentCommentsLog?.length === 0">
                        <p>
                            Actividad sin comentarios.
                        </p>
                    </div>
                </div>
            </div>
            <div *ngIf="alert.content_type === 'MESSAGE'" class="d-flex flex-column">
                <div class="m-b-5">
                    <h5>Asunto:</h5>
                    <hr>
                    <p class="m-l-25">{{contentAlert?.alert?.subject}}</p>
                </div>
                <div class="m-b-5">
                    <h5>Enviado:</h5>
                    <hr>
                    <p class="m-l-25">{{contentAlert?.alert?.created_at}}</p>
                </div>
                <div class="m-b-5">
                    <h5>Contenido:</h5>
                    <hr>
                    <p class="m-l-25">{{contentAlert?.alert?.content ? contentAlert?.alert?.content : 'Sin contenido'}}
                    </p>
                </div>
                <div class="m-b-5">
                    <h5>Documentos Adjuntos:</h5>
                    <hr>
                    <div *ngFor="let attachment of contentAlert?.alert?.attachments">
                        <div class="pointer d-flex flex-row align-items-center"
                            (click)="downloadAttachment(attachment,false)">
                            <img src="../../assets/images/messages/attach-outline.svg" class="m-r-10"
                                alt="attachment" />
                            <p style="margin-bottom: 0 !important;">{{attachment.file_name || 'Archivo'}}</p>
                        </div>
                        <i class="text-c-blue f-20 float-right fas fa-eye m-l-10"
                            (click)="openFilePreview(attachment)"></i>
                    </div>
                    <p class="m-l-25" *ngIf="contentAlert?.alert?.attachments?.length === 0">Sin documentos adjuntos</p>
                </div>
            </div>
            <div *ngIf="alert.content_type === 'RESOURCE'" class="d-flex flex-column">
                <div class="m-b-5">
                    <h5>Nombre:</h5>
                    <hr>
                    <p class="m-l-25">{{contentAlert?.alert?.name}}</p>
                </div>
                <div class="m-b-5">
                    <h5>Creado:</h5>
                    <hr>
                    <p class="m-l-25">{{contentAlert?.alert?.created_at}}</p>
                </div>
                <div class="m-b-5">
                    <h5>Contenido:</h5>
                    <hr>
                    <div class="pointer d-flex flex-row align-items-center">
                        <div class="pointer d-flex flex-row align-items-center"
                            (click)="downloadAttachment(contentAlert?.alert?.link,false)">
                            <img src="../../assets/images/messages/attach-outline.svg" class="m-r-10"
                                alt="attachment" />
                            <p style="margin-bottom: 0 !important;">{{contentAlert?.alert?.link.file_name || 'Recurso'}}
                            </p>
                        </div>
                        <i class="text-c-blue f-20 float-right fas fa-eye m-l-10"
                            (click)="openFilePreview(contentAlert?.alert?.link)"></i>
                    </div>
                    <p class="m-l-25" *ngIf="contentAlert?.alert?.attachments?.length === 0">Sin documentos adjuntos</p>
                </div>
            </div>
        </app-card>
        <app-card [customHeader]="true" [options]="false">
            <div class="app-card-header">
                <h4>Comentarios</h4>
            </div>
            <div style="overflow: overlay; max-height: 40%;">
                <div *ngFor="let comment of commentsLog" class="mb-2">
                    <span class="font-weight-bold">
                        {{comment.created_by}}:
                    </span>
                    <br>
                    <span>
                        {{comment.comment}}
                    </span>
                    <br>
                    <span class="date-text align-self-end">
                        {{comment.created_at}}
                    </span>
                    <hr>
                </div>
                <span *ngIf="commentsLog?.length === 0">
                    Esta alerta no tiene comentarios.
                </span>
            </div>
        </app-card>
    </div>
    <div style="flex: 1;">
        <app-card [hidHeader]="true" [options]="false">
            <div class="m-b-5">
                <h5>Tipo de Alerta:</h5>
                <hr>
                <h6 *ngIf="alert.type==='ABUSE'" class="inactive m-l-25">Acoso</h6>
                <h6 *ngIf="alert.type==='OFFENSIVE'" class="warn m-l-25">Ofensivo</h6>
                <h6 *ngIf="alert.type==='VIOLENCE'" class="inactive m-l-25">Violencia</h6>
                <h6 *ngIf="alert.type==='SPAM'" class="warn m-l-25">Spam</h6>
                <h6 *ngIf="alert.type==='OTHERS'" class="other m-l-25">Otros</h6>
            </div>
            <div class="m-b-5">
                <h5>Responsable del Contenido:</h5>
                <hr>
                <p class="m-l-25">{{contentCreator}}</p>
            </div>
            <div class="m-b-5">
                <h5>Descripci&oacute;n de la Alerta:</h5>
                <hr>
                <p class="m-l-25">{{alert.description}}</p>
            </div>
        </app-card>
        <app-card [hidHeader]="true" [options]="false">
            <form [formGroup]="form">
                <div class="m-b-10">
                    <h5>Agregar Nuevo Comentario:</h5>
                    <hr>
                    <textarea type="text" formControlName="new_comment" class="form-control"
                        placeholder="Ingrese comentarios de revisi&oacute;n"></textarea>
                </div>
                <div class="m-b-15">
                    <h5>Estado:</h5>
                    <hr>
                    <select class="form-control" name="alertStatus" formControlName="status" required
                        [ngClass]="{'is-invalid': form.get('status').touched && form.get('status').hasError('required') && !form.get('status').valid}">
                        <option value="CREATED">Iniciado</option>
                        <option value="PENDING">Pendiente</option>
                        <option value="REVIEW">En revisi&oacute;n</option>
                        <option value="RESOLVED">Resuelto</option>
                        <option value="VOIDED">Desestimado</option>
                    </select>
                    <label
                        *ngIf="form.get('status').touched && form.get('status').hasError('required') && !form.get('status').valid"
                        id="validation-status-error"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        Debe seleccionar un estado.
                    </label>
                </div>
                <div class="col-sm-12 mt-2 d-flex justify-content-between pl-0 pr-0">
                    <div class="btn-group">
                        <button type="button" class="btn btn-sm btn-glow-success btn-success" (click)="updateAlert()">
                            Actualizar
                        </button>
                    </div>
                    <div class="btn-group">
                        <button type="button" class="btn btn-sm btn-glow-warning btn-warning" (click)="closeModal()">
                            Cerrar
                        </button>
                    </div>
                </div>
            </form>
        </app-card>
    </div>
</div>
<app-ui-modal #modalConfirmationDownload [dialogClass]="'modal-lg'">
    <div class="app-modal-header">
        <h5 class="modal-title">Este tipo de archivo no puede ser visualizado!</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
                (click)="modalConfirmationDownload.hide()"><span aria-hidden="true">&times;</span></button>
    </div>
    <div class="app-modal-body">
        <p>¿Desea descargar el archivo?</p>
    </div>
    <div class="app-modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal"
                (click)="modalConfirmationDownload.hide()">Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="downloadAttachment(attachment,true)">Aceptar</button>
    </div>
</app-ui-modal>
