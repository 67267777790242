<div class="row">
    <div class="col-sm-12">
      <ngb-tabset>
          <ngb-tab title="General">
            <ng-template ngbTabContent>
       <div class="row">
          <div class="col-lg-4 col-md-12 horizontal-line">
            <div class="h-list-header">
              <button type="button" [ngClass]="!showComments ? 'btn btn-primary active-button' : 'btn btn-link inactive-button'"
                style="margin-bottom: 15px;" (click)="showComments = false">Entregas</button>
              <button type="button" [ngClass]="!showComments ? 'btn btn-link inactive-button' : 'btn btn-primary active-button'"
               style="margin-bottom: 15px;" (click)="showComments = true">Comentarios</button>
              <div class="input-group search-bar-spacer">
                <input type="text" id="msg-friends" class="form-control" placeholder="Buscar" (keyup)="search($event.target.value)">
                <div class="input-group-append">
                  <span class="input-group-text"><i class='feather icon-search'></i></span>
                </div>
              </div>
            </div>
            <div class="h-list-body">
              <div class="msg-user-list scroll-div">
                <div class="main-friend-list" *ngIf="!showComments">
                  <perfect-scrollbar ngClass="datta-scroll" [style.max-width]="'100%'"
                    [style.max-height]="'calc(100vh - 300px)'" [usePSClass]="'datta'" [disabled]="null"
                    [autoPropagation]="true">
                    <div *ngFor="let item of deliverablesList; let i = index" class="media userlist-box"
                      [ngClass]="{'active': (selectedDeliverable != null && item.delivery.id === selectedDeliverable.delivery.id)}"
                      [attr.data-id]="item.delivery.id" [attr.data-username]="item.delivery.status"
                      (click)="onSelectedDeliverable(item)">
                      <a class="media-left" href="javascript:"><img class="media-object img-radius"
                          src="{{ item.contact.avatar.href }}" alt="{{ item.contact.names }}">
                      </a>
                      <div class="media-body">
                        <div class="row">
                          <div class="col-10">
                            <div class="contact-name mb-1">{{ item.contact.names }}</div>
                            <div class="status mb-1" [ngStyle]="{'color': item.delivery.statusColor}"> {{ item.delivery.statusDesc }} </div>
                            <div class="date"> <app-formatted-date
                                    inputDate="{{item.delivery.updatedAt}}"></app-formatted-date></div>

                          </div>
                          <div class="col-1">
                            <img class="ellipsis" src="../../assets/images/messages/ellipsis-horizontal.svg" alt="attachment" />
                            <div class="score"> {{ item.delivery.score }} </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </perfect-scrollbar>
                </div>
                <div class="main-friend-list" *ngIf="showComments">
                  <perfect-scrollbar ngClass="datta-scroll" [style.max-width]="'100%'"
                    [style.max-height]="'calc(100vh - 300px)'" [usePSClass]="'datta'" [disabled]="null"
                    [autoPropagation]="true">
                    <div *ngFor="let item of activityComments; let i = index" class="media userlist-box"
                      [ngClass]="{'active': (selectedComment != null && item.id === selectedComment?.id)}"
                      (click)="onSelectedComment(item)">
                      <div class="media-body">
                        <div class="row">
                          <div class="col-10">
                            <div class="comment-contact-name mb-1">{{ item.publisher.name }} </div>
                            <div class="status ml-2 mb-1">
                              <app-formatted-date
                                      inputDate="{{item.created_at}}"></app-formatted-date>
                              </div>
                          </div>
                          <div class="col-1">
                            <img class="ellipsis" src="../../assets/images/messages/ellipsis-horizontal.svg" alt="attachment" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </perfect-scrollbar>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8 col-md-12">
            <div class="h-list-body">
              <div class="msg-user-chat scroll-div message-content">
                <perfect-scrollbar ngClass="datta-scroll" [style.max-width]="'100%'" [style.max-height]="'100%'"
                  [usePSClass]="'datta'" [disabled]="null" [autoPropagation]="true">
                  <div class="main" *ngIf="selectedDeliverable != null">
                    <div class="main-content">
                      <div class="row from-information-spacer">
                        <div class="col-1">
                          <img class="media-object img-radius" src="{{ selectedDeliverable.contact.avatar.href }}" alt="usuario"
                            width="50" height="50">
                        </div>
                        <div class="col">
                          <div class="vertical-center">
                            <div class="contact-name"> {{ selectedDeliverable.contact.names }} </div>
                            <div class="email-address"> {{ selectedDeliverable.contact.email }} </div>
                          </div>
                        </div>
                        <div class="col-auto">
                          <div class="status mb-1" [ngStyle]="{'color': selectedDeliverable.delivery.statusColor}"> {{ selectedDeliverable.delivery.statusDesc }} </div>
                          <div class="date">{{ moment(selectedDeliverable.delivery.updatedAt).utcOffset("-06:00").format("DD/MM/YYYY hh:mm a").toString()  }} </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="delivery-name">{{ selectedDeliverable.delivery.activity.title }}</div>
                          <div class="delivery-desc">
                            {{ selectedDeliverable.delivery.activity.description }}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="row to-spacer send-attachment-container" *ngFor="let attachment of selectedDeliverable.delivery.attchaments">
                            <div class="col-12" *ngIf="attachment">
                              <div class="row file-attachment">
                                <div class="col">
                                  <img src="../../assets/images/messages/attach-outline.svg" alt="attachment" />
                                  <div class="file-attached d-inline">{{ attachment.file_name || 'archivo'}}</div>
                                </div>
                                <div class="col-auto">
                                  <div class="material-icons preview-attachment" (click)="openFilePreview(attachment)">
                                    visibility
                                  </div>
                                </div>
                                <div class="col-auto">
                                  <div class="material-icons download-attachment" (click)="downloadAttachment(attachment,false)">
                                    system_update_alt
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf='
                        !selectedDeliverable?.progress?.answers?.length > 0
                        &&
                        selectedDeliverable?.delivery.status !== "DOING"' class="qualification-content">

                      <div class="row header">
                        <div class="col">
                          <div class="title">Calificación</div>
                        </div>
                        <div class="col-auto">
                          <div class="score">{{ selectedDeliverable.delivery.score }}</div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="score-input-label">Puntuación</div>
                          <div>
                            <input type="number" class="input" [(ngModel)]="score"  [disabled]="!grantsService.validateByNameAndType('NOTA', 'ACTION')">
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="score-input-label">Comentario</div>
                          <div>
                            <textarea rows="10" class="input" [(ngModel)]="comment"  [disabled]="!grantsService.validateByNameAndType('NOTA', 'ACTION')"></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 save-button">
                          <button type="button" class="btn btn-primary active-button"
                          (click)="saveQualification(selectedDeliverable.delivery)"
                          [disabled]="!grantsService.validateByNameAndType('NOTA', 'ACTION')">Guardar</button>
                        </div>
                      </div>
                    </div>
                    <div class="wrapper-quiz" *ngIf="selectedDeliverable?.progress?.answers?.length > 0">

                      <h4>Calificar Cuestionario.</h4>
                      <ngb-accordion
                          [destroyOnHide]='false'
                          [closeOthers]="true"
                          (panelChange)='panelShadow($event, shadow)'
                          [activeIds]="'toggle' + (questionsList.length - 1)">
                          <ng-container *ngFor="let answer of selectedDeliverable?.progress?.answers; let i = index">
                            <ngb-panel
                                    cardClass="open"
                                    [title]="answer.question.title"
                                    [id]="'toggle' + i" >
                              <ng-template ngbPanelContent>
                                <app-notes-form
                                        #notes
                                        [id]="answer?.questionId"
                                        [defaultValue]="answer?.right"
                                        [responseQuiz]="answer?.singleResponse"
                                        [answer]="answer"
                                ></app-notes-form>
                              </ng-template>
                            </ngb-panel>
                          </ng-container>

                      </ngb-accordion>

                      <article *ngIf="selectedDeliverable?.delivery?.comments?.length > 0" class="commentsWrapper">
                        <h4>Comentarios: </h4>
                        <ul>
                          <li *ngFor="let dd of  selectedDeliverable?.delivery?.comments">
                            {{ dd?.comment  }}
                          </li>
                        </ul>
                      </article>


                      <div *ngIf="this.showQuiz
                        && selectedDeliverable.delivery.status !== 'REVIEWED'
                        && selectedDeliverable.delivery.status !== 'DOING'
                      ">
                        <label class="form-control-label" for="comentarios">Comentarios:</label>
                        <textarea  type="text" [(ngModel)]="commentsQuiz" class="form-control"
                                  (readonly) = "selectedDeliverable.delivery.status !== 'REVIEWED'"
                                   required rows="3">
                      </textarea>
                      </div>

                      <button
                              *ngIf="selectedDeliverable.delivery.status !== 'REVIEWED' && this.showQuiz && selectedDeliverable.delivery.status !== 'DOING'"
                              type="button"
                              class="btn btn-primary active-button float-right mt-5"
                              (click)="saveNotes()">
                        Guardar
                      </button>
                    </div>
                  </div>
                  <div class="main" *ngIf="selectedComment !== null">
                    <div class="main-content">
                      <div class="row from-information-spacer">
                        <div class="col-1">
                          <img class="media-object img-radius" src="{{ selectedComment?.contact.avatar }}" alt="usuario"
                            width="50" height="50">
                        </div>
                        <div class="col">
                          <div class="vertical-center">
                            <div class="contact-name"> {{ selectedComment?.contact.names }} </div>
                            <div class="email-address"> {{ selectedComment?.contact.email }} </div>
                          </div>
                        </div>
                        <div class="col-auto">
                          <div class="date"><app-formatted-date
                                  inputDate="{{selectedComment?.createdAt}}"></app-formatted-date>
                        </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="delivery-desc">
                            {{ selectedComment?.description }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </perfect-scrollbar>
              </div>
            </div>
          </div>
        </div>
        </ng-template>
      </ngb-tab>
          <ngb-tab title="Edición" *ngIf="grantsService.validateByNameAndType('EDITAR_ACTIVIDADES', 'ACTION')">
        <ng-template ngbTabContent>
          <div class="row main">
            <div class="col-md-8 horizontal-line">
              <div class="qualification-content">
                <div class="row header edit">
                  <form #validationForm="ngForm" class="editForm" novalidate>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="form-control-label" for="type">Tipo Actividad</label>
                          <select class="form-control" id="type" name="type" (change)="changeType()" required
                                  #contactType="ngModel"  [ngClass]="{'is-invalid': !contactType.valid && (contactType.dirty || contactType.touched || isSubmit)}" [(ngModel)]="formInput.type" >
                            <option value="">Ingrese un Tipo Actividad</option>
                            <option value="TASK">Tarea</option>
                            <option value="MEET">Reunion</option>
                            <option value="WATCH_VIDEO">Ver Video</option>
                            <option value="READ_DOCUMENT">Leer Documento</option>
                          </select>
                          <label *ngIf="!contactType.valid && (contactType.dirty || contactType.touched || isSubmit)"
                                 id="validation-contactType-error"
                                 class="error jquery-validation-error small form-text invalid-feedback">Debe seleccionar el tipo de espacio.</label>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="form-label">Titulo</label>
                          <input type="text" class="form-control" id="names" name="names"
                                 placeholder="Ingresar Título"
                                 #names="ngModel"
                                 [(ngModel)]="formInput.title"
                                 [ngClass]="{'is-invalid': !names.valid && (names.dirty || names.touched || isSubmit)}"
                                 required>
                          <label *ngIf="!names.valid && (names.dirty || names.touched || isSubmit)"
                                 id="validation-names-error"
                                 class="error jquery-validation-error small form-text invalid-feedback">Ingrese un
                            t&iacute;tulo valido.</label>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="form-label">Descripcion</label>
                          <textarea
                                  class="form-control"
                                  name="lastNames"
                                  id="lastNames"
                                  #lastNames="ngModel"
                                  [(ngModel)]="formInput.description"
                                  [ngClass]="{'is-invalid': !lastNames.valid && (lastNames.dirty || lastNames.touched || isSubmit)}"
                                  placeholder="Ingresar Descripción"
                                  required
                                  rows="3">
                            </textarea>
                          <label *ngIf="!lastNames.valid && (lastNames.dirty || lastNames.touched || isSubmit)"
                                 id="validation-apellidos-error"
                                 class="error jquery-validation-error small form-text invalid-feedback">Ingrese una descripci&oacute;n valida.</label>
                        </div>
                      </div>
                      <div class="col-md-6" [hidden]="!isMeet">
                        <div class="form-group">
                          <label class="form-label">Direccion de reunion</label>
                          <input type="text" class="form-control" name="urlMeet" id="urlMeet" #urlMeet="ngModel"
                                 [(ngModel)]="formInput.meet_link"
                                 [ngClass]="{'is-invalid': !urlMeet.valid && (urlMeet.dirty || urlMeet.touched || isSubmit)}"
                                 placeholder="Ingresar apellidos" [required]="isMeet">
                          <label *ngIf="!urlMeet.valid && (urlMeet.dirty || urlMeet.touched || isSubmit)"
                                 id="validation-urlmeet-error"
                                 class="error jquery-validation-error small form-text invalid-feedback">Ingrese una descripci&oacute;n valida.</label>
                        </div>
                      </div>
                      <div class="col-md-6" [hidden]="!isMeet">
                        <div class="form-group">
                          <label class="form-control-label" for="type">Plataforma de reunion</label>
                          <select class="form-control" id="platform" name="platform" [required]="isMeet"
                                  #platform="ngModel"   [ngClass]="{'is-invalid': !platform.valid && (platform.dirty || platform.touched || isSubmit)}" [(ngModel)]="formInput.meet_vendor" >
                            <option value="ZOOM">ZOOM</option>
                            <option value="GOOGLE">Google Meet</option>
                            <!--                               &lt;!&ndash; <option value="SKYPE">Skype</option>-->
                            <option value="TEAMS">Teams</option>
                            <!--                               <option value="WEBEX">Webex</option> &ndash;&gt;-->
                          </select>
                          <label *ngIf="!platform.valid && (platform.dirty || platform.touched || isSubmit)"
                                 id="validation-platform-error"
                                 class="error jquery-validation-error small form-text invalid-feedback">Debe seleccionar el tipo de plataforma.</label>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="form-label">Fecha de Entrega</label>
                          <div class="input-group">
                            <input class="form-control" placeholder="dd / mm / aaaa" [minDate]="startDate ? startDate : minStartDate" #end_at="ngModel"
                                   name="dateEnd" id="dateEnd"  [(ngModel)]="endDate" ngbDatepicker #d2="ngbDatepicker"
                                   [ngClass]="{'is-invalid': !dateEnd.valid && (dateEnd.dirty || dateEnd.touched || isSubmit)}" required>
                            <div class="input-group-append">
                              <button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
                                <i class="fas fa-calendar f-14"></i>
                              </button>
                            </div>
                          </div>
                          <label *ngIf="!dateEnd.valid && (dateEnd.dirty || dateEnd.touched || isSubmit)"
                                 id="validation-dateEnd-error"
                                 class="error jquery-validation-error small form-text invalid-feedback">
                            Ingrese una fecha de entrega valida.
                          </label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <div class="ngx-material-timepicker-example__form-group">
                            <label class="form-label">Hora de Entrega</label>
                            <input
                                    [(ngModel)]="formInput.end_time"
                                    #dateEnd="ngModel"
                                    id="endTime"
                                    name="endTime"
                                    placeholder="Default time 11:11 pm"
                                    [required]="isMeet && isTask"
                                    [format]="24"
                                    aria-label="default time"
                                    [ngxTimepicker]="defaultTime"
                                    [value]="activityTime"
                                    class="timePicker"
                                    >
                            <ngx-material-timepicker #defaultTime ></ngx-material-timepicker>
                          </div>

                          <label *ngIf="!dateEnd.valid && (dateEnd.dirty || dateEnd.touched || isSubmit)"
                                 id="validation-timeEnd-error"
                                 class="error jquery-validation-error small form-text invalid-feedback">Ingrese una hora de entrega valida.</label>
                        </div>
                      </div>
                      <div class="col-md-6" *ngIf="!isMeet">
                        <div class="form-group">
                          <label class="form-label">Peso de nota</label>
                          <input type="number" class="form-control" name="score" id="score" #score="ngModel"
                                 [(ngModel)]="formInput.score" [min]="0" [max]="100"
                                 [ngClass]="{'is-invalid': !score.valid && (score.dirty || score.touched || isSubmit)}"
                                 placeholder="Ingresar Descripción" required>
                          <label *ngIf="!score.valid && (score.dirty || score.touched || isSubmit)"
                                 id="validation-apellidos-error"
                                 class="error jquery-validation-error small form-text invalid-feedback">La nota debe ser entre 0-100</label>
                        </div>
                      </div>

                      <div class="col-md-12" *ngIf="isVideo || isDocument">
                        <article>
                          <section class="wrapper-upload">
                            <div *ngIf="documentAttached" class="documentAdd">
                              <i *ngIf="isVideo" class="feather icon-film" style="font-size: 40px"></i>
                              <i *ngIf="isDocument" class="feather icon-file-text" style="font-size: 40px"></i>
                              <br/>
                              {{ documentAttached.target.files[0].name }}
                            </div>
                            <input hidden type="file" accept="{{isVideo ? 'video/mp4,video/x-m4v,video/*' : 'application/pdf' }}  " #uploader (change)="selectDocument($event)" [multiple]="false" />
                            <button type="button" class="btn btn-sm btn-glow-light btn-light btnAdd btnUpload" (click)="uploader.click()">
                              <i *ngIf="isVideo" class="feather icon-film"></i>
                              <i *ngIf="isDocument" class="feather icon-file-text"></i>

                              {{isVideo ? 'Buscar video' : isDocument && 'Buscar documento'}}</button>
                          </section>
                        </article>
                      </div>
                    </div>
                  </form>
                  <div class="col-12 save-button load-btn-container">
                    <button type="button" class="btn btn-primary active-button btn-inversed"
                    (click)="setActivityFields(selectedDeliverable.delivery.activity)">Cancelar</button>
                    <div class="d-inline-block m-r-20"></div>
                    <button type="button" class="btn btn-primary active-button"
                    (click)="updateActivityTask(validationForm)">Guardar</button>
                    <div *ngIf="loading" class="card-body d-flex flex-row justify-content-center mt-5">
                      <div class="spinner-border text-info" role="status">
                        <span class="sr-only"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="qualification-content">
                <div class="row header edit">
                  <div class="col-12">
                    <div class="title edit m-b-35">Recursos</div>
                    <div class="container-dnd" appDnd (fileDropped)="onFileDropped($event)">
                      <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />
                      <div class="message">Clic aquí para cargar archivos</div>
                    </div>
                   </div>
                   <div class="col-12">
                    <div class="files-list">
                      <div class="single-file" *ngFor="let file of files; let i = index">
                        <div class="info">
                          <h4 class="name">
                            {{ file?.name }}
                          </h4>
                          <p class="size">
                            {{ formatBytes(file?.size) }}
                          </p>
                        </div>
                        <div class="delete" (click)="deleteFile(i)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18">
                            <path fill="#f44236" fill-rule="nonzero"
                              d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v10zm3.17-7.83a.996.996 0 0 1 1.41 0L7 9.59l1.42-1.42a.996.996 0 1 1 1.41 1.41L8.41 11l1.42 1.42a.996.996 0 1 1-1.41 1.41L7 12.41l-1.42 1.42a.996.996 0 1 1-1.41-1.41L5.59 11 4.17 9.58a.996.996 0 0 1 0-1.41zM10.5 1L9.79.29C9.61.11 9.35 0 9.09 0H4.91c-.26 0-.52.11-.7.29L3.5 1H1c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 text-center load-btn-container">
                      <button type="button" [disabled]="files.length === 0" class="btn btn-primary active-button btn-inversed" (click)="upload()">Cargar Archivo(s)</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-tab>
          <ngb-tab title="Recursos">
        <ng-template ngbTabContent>
          <div class="row main">
            <div class="col-md-12">
              <div class="files-list">
                <div class="single-file" *ngFor="let file of resourcesFiles; let i = index">
                  <div class="info">
                    <h4 class="name">
                      {{ file.file_name || 'Archivo' }}
                    </h4>
                    <p class="size">
                      {{ formatBytes(file.size) }}
                    </p>
                  </div>
                  <div class="col-auto">
                    <div class="material-icons preview-attachment" (click)="openFilePreview(file)">
                      visibility
                    </div>
                  </div>
                  <div class="col-auto">
                    <div class="material-icons download-attachment" (click)="downloadAttachment(file,false)">
                      system_update_alt
                    </div>
                  </div>
                  <div class="delete">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" (click)="removeAttachment(file)">
                      <path fill="#f44236" fill-rule="nonzero"
                        d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v10zm3.17-7.83a.996.996 0 0 1 1.41 0L7 9.59l1.42-1.42a.996.996 0 1 1 1.41 1.41L8.41 11l1.42 1.42a.996.996 0 1 1-1.41 1.41L7 12.41l-1.42 1.42a.996.996 0 1 1-1.41-1.41L5.59 11 4.17 9.58a.996.996 0 0 1 0-1.41zM10.5 1L9.79.29C9.61.11 9.35 0 9.09 0H4.91c-.26 0-.52.11-.7.29L3.5 1H1c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </ng-template>
        </ngb-tab>
          <ngb-tab *ngIf="formInput?.type === 'WATCH_VIDEO' || formInput?.type === 'READ_DOCUMENT'" title="Cuestionario">
          <ng-template ngbTabContent>
            <form #validationFormQuestion="ngForm" class="editForm" novalidate>
              <div class="row main">
              <div class="col-md-6">
                <div class="row" style="margin-top: 15px; margin-bottom: 15px;">
                  <div class="col-md-4">
                    <label class="form-label">Nota*</label>
                    <input
                            type="number"
                            class="form-control"
                            name="score"
                            readonly
                            id="score"
                            #score="ngModel"
                            [(ngModel)]="formInputQuestions.score"
                            [min]="0"
                            [max]="100"
                            [disabled]="!questionActiveF"
                            [ngClass]="{'is-invalid': !score.valid && (score.dirty || score.touched || isSubmit)}"
                            placeholder="nota"
                            required>
                    <label *ngIf="!score.valid && (score.dirty || score.touched || isSubmit)"
                           id="validation-apellidos-error"
                           class="error jquery-validation-error small form-text invalid-feedback">La nota debe ser entre 0-100</label>
                  </div>
                  <div class="col-md-4">
                    <label class="form-label">Nota minima*</label>
                    <input type="number" class="form-control" name="minScore" id="minScore" #minScore="ngModel"
                           [disabled]="!questionActiveF"
                           [(ngModel)]="formInputQuestions.minScore" [min]="0" [max]="100"
                           [ngClass]="{'is-invalid': !minScore.valid && (minScore.dirty || minScore.touched || isSubmit)}"
                           placeholder="nota minima" required>
                    <label *ngIf="!minScore.valid && (minScore.dirty || minScore.touched || isSubmit)"
                           id="validation-apellidos-error"
                           class="error jquery-validation-error small form-text invalid-feedback">La nota minima debe ser entre 0-100</label>
                  </div>
                  <div class="col-md-4" *ngIf="showAttempts">
                    <label class="form-label">Oportunidades para responder*</label>
                    <input type="number" class="form-control" name="retries" id="retries" #retries="ngModel"
                           [disabled]="!questionActiveF"
                           [(ngModel)]="formInputQuestions.retries" [min]="0" [max]="100"
                           [ngClass]="{'is-invalid': !retries.valid && (retries.dirty || retries.touched || isSubmit)}"
                           placeholder="intentos">
                    <label *ngIf="!retries.valid && (retries.dirty || retries.touched || isSubmit)"
                           id="validation-apellidos-error"
                           class="error jquery-validation-error small form-text invalid-feedback">el intento debe estar entre 0-100</label>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <button
                        type="button"
                        class="btn btn-primary active-button float-right mt-5"
                        (click)="newQuestion()"
                        [disabled]="!questionActiveF">
                          Agregar Pregunta
                </button>
              </div>

              <div class="col-md-12 mt-3">
                <ngb-accordion
                        [destroyOnHide]='false'
                        [closeOthers]="true"
                        (panelChange)='panelShadow($event, shadow)'
                        [activeIds]="'toggle' + (questionsList.length - 1)">
                  <ngb-panel cardClass="open" *ngFor="let q of questionsList; let i = index"  [title]="'Pregunta' + ' ' + (i + 1) " [id]="'toggle' + i" >

                    <ng-template ngbPanelContent>
                      <span class="deleteQuestion" (click)="removeQuestion(i)">Eliminar pregunta!</span>
                      <app-question-form
                              #questions
                              (changeData)="this.fillQuestion()"
                              [defaultData]="q"></app-question-form>
                    </ng-template>
                  </ngb-panel>
                </ngb-accordion>

                <button
                        type="button"
                        style="background-color: #0bb92d"
                        class="btn btn-primary active-button float-right mt-5"
                        (click)="updateActivityTask(validationFormQuestion, true)"
                        [disabled]="
                        !formInputQuestions.score ||
                        !formInputQuestions.minScore ||
                        !(questionsList.length > 0)
                ">Guardar Cuestionario!</button>

              </div>
            </div>
            </form>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
    </div>
  </div>

<app-ui-modal #modalConfirmationDownload [dialogClass]="'modal-lg'">
  <div class="app-modal-header">
    <h5 class="modal-title">Este tipo de archivo no puede ser visualizado!</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="modalConfirmationDownload.hide()"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <p>¿Desea descargar el archivo?</p>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal"
            (click)="modalConfirmationDownload.hide()">Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="downloadAttachment(attachment,true)">Aceptar</button>
  </div>
</app-ui-modal>

<app-screen-loading [message]="messageScreen"></app-screen-loading>
