<div *ngIf="!validToken" class="rectangle">
  <div class="vector-3"></div>
  <div class="vector-2"></div>
  <div class="vector-1"></div>
  <div class="auth-wrapper">
    <div class="auth-content">
      <div class="card">
        <div class="card-body text-center">
          <div class="mb-4">
            <img style="padding-bottom:25px;padding-top: 25px " src="assets/images/logo.svg">
          </div>
          <h3 class="mb-4 login-important login-title">Iniciar sesión</h3>
          <div class="input-group mb-3">
            <input (keydown.enter)="login()" type="email" class="form-control" placeholder="Usuario" [(ngModel)]="username"
              value="eliu@gmail.com">
          </div>
          <div class="input-group mb-4">
            <input (keydown.enter)="login()" type="password" class="form-control" placeholder="Constraseña" [(ngModel)]="password"
              value="Test123!!">
          </div>
          <div class="form-group text-left">
            <div class="checkbox checkbox-fill d-inline">
              <input type="checkbox" name="checkbox-fill-1" id="checkbox-fill-a1" checked="">
              <label for="checkbox-fill-a1" class="cr login-muted"> Recordar usuario</label>
            </div>
          </div>
          <button class="btn btn-primary mb-4" (click)="login()">Iniciar sesión</button>
          <p class="mb-2 login-muted"><a class="login-important"
            [routerLink]="['/auth/forgot-password']">¿Olvid&oacute; su contraseña?</a></p>
          <p class="mb-0 login-muted">¿A&uacute;n no tiene cuenta? <a class="login-important" [routerLink]="['/auth/signup']">Registrarse</a></p>
        </div>
      </div>
    </div>
  </div>
  <ng2-toasty [position]="position"></ng2-toasty>
</div>
