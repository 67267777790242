import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import {SpaceExpandableFlatNode} from '../../../util/space';
import {BranchResponse, SpaceResponse} from '../../../api/client/data-contracts';


@Component({
  selector: 'app-tree-eduk2',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss']
})
export class TreeComponent implements OnInit {
  @Input() dataSource: any;
  @Input() treeControl: FlatTreeControl<SpaceExpandableFlatNode>;
  // tslint:disable-next-line:no-shadowed-variable no-output-on-prefix
  @Output() onSelectedChangeObsOut: EventEmitter<SpaceExpandableFlatNode> = new EventEmitter();
  @Output() openClassOut: EventEmitter<any> = new EventEmitter();

  idSelected = '';

  constructor( ) { }

  // tslint:disable-next-line:typedefs typedef
  getIcon(type: string) {
    switch (type) {
      case 'CLASSROOM':
        return 'import_contacts';
      case 'CATEGORY':
        return 'grid_on';
      case 'GRADE':
        return 'class';
      case 'CAREER':
        return 'school';
      case 'SECTION':
        return 'view_headline';
      case 'INSTITUTION':
        return 'account_balance';
    }
  }

  // tslint:disable-next-line:typedef
  openClass(node) {
    if (node.type === 'CLASSROOM') {
      this.openClassOut.emit(node);
    } else {
      this.onSelectedChangeObs(node);
    }
  }
  // tslint:disable-next-line:typedef
  // tslint:disable-next-line:typedef
  onSelectedChangeObs(node: SpaceExpandableFlatNode) {
    this.onSelectedChangeObsOut.emit(node);
    this.idSelected = node.id;
  }

  hasChild = (_: number, node: SpaceExpandableFlatNode) => node.expandable;

  ngOnInit(): void {
    console.log('dataSource {} ', this.dataSource);
  }

}
