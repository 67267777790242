export enum ContactTypeEnum {
    PARENT = 'PARENT',
    STUDENT = 'STUDENT',
    STAFF = 'STAFF',
    ADMIN = 'ADMIN',
    ROOT = 'ROOT',
    OWNER = 'OWNER'
}

export enum ActivityType{
    TASK ='Tarea',
    MEET = 'Reunion Virtual',
    WATCH_VIDEO = 'Ver Video',
    READ_DOCUMENT='Leer Documento'
}

export enum ScoreLoadReasons {
    Correction = 'CORRECTION',
    ErrorInData = 'ERROR_IN_DATA',
}

export enum FILES_NOT_PREVEW {
    POWERPOINT = 'POWERPOINT',
    WORD = 'WORD',
    EXCEL = 'EXCEL',
}


export const PAGE_SIZE = 20;
