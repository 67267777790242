import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastOptions, ToastyService } from 'ng2-toasty';
import {ContactResponse, CycleUpdateRequest, ScoreMap, SpaceResponse, SpaceScoreMap, UnitsInput} from 'src/api/client/data-contracts';
import { Service } from 'src/api/service/Service';
import { StorageService } from 'src/api/service/storage.service';

@Component({
  selector: 'app-space-scores-modal',
  templateUrl: './space-scores-modal.component.html',
  styleUrls: ['./space-scores-modal.component.scss']
})
export class SpaceScoresModalComponent implements OnInit {

  title = 'Notas';
  subTitle = '';
  scoreHandling = '';
  unitsScore = '';
  tabTitle = '';
  form: FormGroup;
  studentScores: any[] = [];
  classrooms = new Map<string, string>();
  scoresMap = new Map<string, ScoreMap>();
  noCycleError = false;
  currentCycle: CycleUpdateRequest;
  cycleUnits: UnitsInput[] = [];
  selectedUnit: number;
  compute = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public incomingData: {
      space?: Partial<SpaceResponse>,
      selectUnit?: boolean,
      showSpace?: boolean,
    },
    private dialogRef: MatDialogRef<SpaceScoresModalComponent>,
    private storageService: StorageService,
    private toastyService: ToastyService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    if (this.incomingData.selectUnit) {
      this.getActiveCycleAndUnits();
      this.buildForm();
    }
  }
  
  buildForm() {
    this.form = this.fb.group({
      unit: ['', [Validators.required]],
    });
    const service = new Service(this.storageService);
    service.getSpaceBreadCrumb(this.incomingData.space.id).then(value => {
      this.subTitle = value.bread_crumb;
    });
    this.form.get('unit').valueChanges.subscribe(unitId => {
      if (unitId !== 'total') {
        this.compute = false;
        this.selectedUnit = this.cycleUnits.find(unit => unit.id === unitId).unit;
        this.getScoreSpace(unitId, false);
        this.tabTitle = `Notas de Unidad ${this.selectedUnit} `;
      } else {
        this.compute = true;
        this.getScoreSpace(null, true);
        this.tabTitle = `Notas totales`;
      }
    });
  }

  getActiveCycleAndUnits() {
    const service = new Service(this.storageService);
    // @ts-ignore
    service.getActiveCycleBySpaceOrAssigment(this.incomingData.space.id).then((cycle: CycleUpdateRequest) => {
      if (Boolean(cycle)) {
        this.currentCycle = cycle[0];
        this.scoreHandling = this.currentCycle.score_handling != null ? 'Base de cálculo: ' + (this.currentCycle.score_handling === 'SUM' ? 'Suma' : 'Promedio') : '';

        service.getCycleUnits(this.currentCycle.id)
          .then((units: UnitsInput[]) => {
            this.cycleUnits = units.sort((a, b) => a.unit - b.unit);
          });
      } else {
        this.noCycleError = true;
      }
    }).catch(err => {
      this.noCycleError = true;
      this.toastyService.clearAll();
      const toastOptions: ToastOptions = {
        title: 'Espere',
        msg: 'Guardando registro',
        timeout: 5000,
        theme: 'default'
      };
      this.toastyService.error(toastOptions);
      console.log('Err:', err);
    });
  }

  async getScoreSpace(unitId?: string, compute: boolean = false) {
    const service = new Service(this.storageService);
    this.scoresMap.clear();
    this.classrooms.clear();
    const params: {
      unit_id?: string,
      compute?: boolean,
    } = {};
    if (this.incomingData.selectUnit && unitId && this.incomingData.space) { params.unit_id = unitId; }

    if (compute) {
      params.compute = compute;
    }
    const spaceScores = await service.getSpaceScoreInfo(this.incomingData.space.id, params) as any[];
    this.studentScores = spaceScores;

    if ( this.studentScores && this.studentScores.length > 0){
      this.unitsScore = 'Notas de unidades: '.concat(this.studentScores[0].units_scores.sort((a, b) => Number(a) - Number(b)));
    }else{
      this.unitsScore = '';
    }

    this.studentScores.forEach(value => {
      const spaceScoreMap = {
        space: value.space,
        score_activities: value.score_activities,
        score_loads:  value.score_loads,
        total_score:  value.total_score
      };
      if ( this.scoresMap.has(value.contact.id) ){

        this.scoresMap.get(value.contact.id).scores.set(value.space.id, spaceScoreMap);
      }else{
        const mapTmp = {contact : value.contact,
          scores: new Map<string, SpaceScoreMap>().set(value.space.id, spaceScoreMap)};
        this.scoresMap.set(value.contact.id, mapTmp);
      }
      this.classrooms.set(value.space.id, value.space.title);

    });
    this.scoresMap.forEach((value, key) => console.log(key, value));
  }

  closeModal() {
    this.dialogRef.close();
  }

}
