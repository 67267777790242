import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Service } from 'src/api/service/Service';
import { StorageService } from 'src/api/service/storage.service';

@Injectable({
  providedIn: 'root'
})
export class UploadsNotifierService {

  showNotifier: boolean;
  uploadsList: any[];
  checkUploads: boolean = false;

  showNotifierChange$: Subject<boolean> = new Subject<boolean>();
  updatedUploadsList$: Subject<any> = new Subject<any>();

  constructor(
    private storageService: StorageService,
  ) {
    this.showNotifierChange$.subscribe((value) => {
      this.showNotifier = value;
    });
    this.updatedUploadsList$.subscribe((value) => {
      this.uploadsList = value;
    });
  }

  toggleNotifierVisibility() {
    this.showNotifierChange$.next(!this.showNotifier);
  }

  async checkPendingUploads() {
    const service = new Service(this.storageService);
    this.checkUploads = true;
    service.getLoadsBySchoolId().then(fileLoads => {
      this.updatedUploadsList$.next(fileLoads);
    });
  }

}