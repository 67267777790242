import { Component, Inject, OnInit } from '@angular/core';
import { ToastOptions, ToastyService } from "ng2-toasty";
import { StorageService } from "../../../../api/service/storage.service";
import { Service } from "../../../../api/service/Service";
import { FormContentComponent } from "../form-content.component";
import { AddFileResourceUsingPostPayload } from 'src/api/client/data-contracts'

@Component({
    selector: 'form-resources-component',
    templateUrl: './form-resources.component.html',
    styleUrls: ['./form-resources.component.scss']
})
export class FormResourcesComponent implements OnInit {

    position = 'top-center';
    formInput: AddFileResourceUsingPostPayload;
    file: File = null;
    form: any;
    loading: boolean = false;

    public isSubmit: boolean;
    public isUpdate: boolean;

    constructor(
        @Inject(FormContentComponent) private formContentComponent: FormContentComponent,
        private storageService: StorageService,
        private toastyService: ToastyService,
    ) {
        this.isSubmit = false;
    }

    ngOnInit() {
        this.initializeForm();
    }

    public validateContact: (MovingDirection) => boolean = (form: any) => {
        if (!form.valid) {
            this.isSubmit = true;
            return false;
        } else {
            return true;
        }
    }

    // On file Select
    onChange(event) {
        this.file = event.target.files[0];
    }

    initializeForm() {
        this.formInput = {
            name: '',
            description: '',
            file: null
        };
    }

    async save(form: any) {
        this.loading = true;
        if (form.valid) {
            this.toastyService.clearAll();
            var toastOptions: ToastOptions = {
                title: "Espere",
                msg: "Guardando registro",
                timeout: 5000,
                theme: "default"
            };
            this.toastyService.wait(toastOptions);
            const service = new Service(this.storageService);
            if (this.isUpdate) {

            } else {
                await service.addRsourceToActivitie(this.formContentComponent.id, this.formInput).then(value => {
                    this.toastyService.clearAll();
                    var toastOptions: ToastOptions = {
                        title: "Exito",
                        msg: "Actividad creada exitosamente.",
                        timeout: 3000,
                        theme: "default"
                    };
                    this.toastyService.info(toastOptions);
                    // @ts-ignore
                    this.formContentComponent.updateDataResources(this.formContentComponent.id);
                    this.formContentComponent.closeModalFormResource();
                    this.initializeForm();
                    this.loading = false;
                }).catch(reason => {
                    this.toastyService.clearAll();
                    var toastOptions: ToastOptions = {
                        title: "Error",
                        msg: reason.message,
                        timeout: 5000,
                        theme: "default"
                    };
                    this.toastyService.error(toastOptions);
                    this.initializeForm();
                    this.loading = false;
                });
            }
        }
    }

    fileChangeEvent(fileInput: any) {
        this.formInput.file = fileInput.target.files[0];
    }

    initializeCreate() {
        this.initializeForm();
    }

}