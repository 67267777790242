import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MediaAsset } from 'src/api/client/data-contracts';

import PSPDFKit from 'pspdfkit';

@Component({
  selector: 'app-media-preview-modal',
  templateUrl: './media-preview-modal.component.html',
  styleUrls: ['./media-preview-modal.component.scss']
})
export class MediaPreviewModalComponent implements OnInit {

  @ViewChild('video1') video1: ElementRef;

  title = 'Vista previa';
  landscapeView: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public attachment: MediaAsset,
    private dialogRef: MatDialogRef<MediaPreviewModalComponent>,
  ) { }

  ngOnInit(): void {
    if (this.attachment.type === 'VIDEO') {
      setTimeout(() => {
        const videoWidth = this.video1.nativeElement.offsetWidth;
        const videoHeight = this.video1.nativeElement.offsetHeight;
        this.landscapeView = videoWidth > videoHeight ? true : false;
      }, 1000);
    }
  }

  ngAfterViewInit() {
    const baseUrl = `${window.location.protocol}//${window.location.host}/assets/`;
    PSPDFKit.load({
      baseUrl,
      document: this.attachment.href,
      container: '#pdfApp',
    }).then()
      .catch(error => {
        console.log("Error: ", error);
      });
  }

  closeModal() {
    this.dialogRef.close();
  }

}