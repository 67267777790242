<div class="row align-items-center justify-content-center">
    <div class="col icon" (click)="onClick()">
        <i class="far text-c-blue f-40 float-left" [ngClass]="icon === 'file' ? 'fa-file-alt' : 'fa-calendar-alt'"></i>
    </div>
    <div class="col text" (click)="onClick()">
        <div class="row align-items-center justify-content-center">
            <div class="col">
                <h5 class="m-0 title spaceTitle">{{name}}</h5>
                <p class="description">{{description}}</p>
            </div>
        </div>
    </div>
    <div class="col date" (click)="onClick()">
        <div class="row align-items-center justify-content-center">
            <div class="col">
                <p *ngIf="startDate" class="date mb-0"><span class="date-title mr-1">Fecha de inicio:</span> {{startDate}}</p>
                <p *ngIf="startTime" class="date mb-2"><span class="date-title mr-1">Hora de inicio:</span> {{startTime}}</p>
                <p *ngIf="endDate " class="date mb-0"><span class="date-title end-date mr-1">Fecha de entrega:</span> {{endDate}}</p>
                <p *ngIf="endTime" class="date mb-2"><span class="date-title end-date mr-1">Hora de entrega:</span> {{endTime}}</p>
            </div>
        </div>
    </div>
    <div class="col delete">
        <i class="far text-c-red f-20 float-right fa-trash-alt" (click)="onDelete()" *ngIf="grantsService.validateByNameAndType('ELIMINAR_ACTIVIDADES', 'ACTION')"></i>
    </div>
</div>
