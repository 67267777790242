import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ResourceResponse } from 'src/api/client/data-contracts';

import PSPDFKit from 'pspdfkit';

@Component({
  selector: 'app-resource-preview',
  templateUrl: './resource-preview.component.html',
  styleUrls: ['./resource-preview.component.scss']
})
export class ResourcePreviewComponent implements OnInit {

  @ViewChild('video1') video1: ElementRef;
  
  @Input() selectedResource: ResourceResponse;

  landscapeView: boolean = false;

  constructor() { }

  ngOnInit(): void {
    if (this.selectedResource.link.type === 'VIDEO') {
      setTimeout(() => {
        const videoWidth = this.video1.nativeElement.offsetWidth;
        const videoHeight = this.video1.nativeElement.offsetHeight;
        this.landscapeView = videoWidth > videoHeight ? true : false;
      }, 500);
    }
  }

  ngAfterViewInit() {
    const baseUrl = `${window.location.protocol}//${window.location.host}/assets/`;
    PSPDFKit.load({
      baseUrl,
      document: this.selectedResource.link.href,
      container: '#pdfApp',
    }).then()
      .catch(error => {
        console.log("Error: ", error);
      });
  }

}