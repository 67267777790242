import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './theme/shared/shared.module';
import { AppComponent } from './app.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { NavigationComponent } from './theme/layout/admin/navigation/navigation.component';
import { NavLogoComponent } from './theme/layout/admin/navigation/nav-logo/nav-logo.component';
import { NavContentComponent } from './theme/layout/admin/navigation/nav-content/nav-content.component';
import { NavigationItem } from './theme/layout/admin/navigation/navigation';
import { NavGroupComponent } from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavCollapseComponent } from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavItemComponent } from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { NavBarComponent } from './theme/layout/admin/nav-bar/nav-bar.component';
import { ToggleFullScreenDirective } from './theme/shared/full-screen/toggle-full-screen';
import { NgbButtonLabel, NgbButtonsModule, NgbDropdownModule, NgbModule, NgbPopoverModule, NgbRadioGroup, NgbTabsetModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NavLeftComponent } from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import { NavSearchComponent } from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import { NavRightComponent } from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import { ChatUserListComponent } from './theme/layout/admin/nav-bar/nav-right/chat-user-list/chat-user-list.component';
import { FriendComponent } from './theme/layout/admin/nav-bar/nav-right/chat-user-list/friend/friend.component';
import { ChatMsgComponent } from './theme/layout/admin/nav-bar/nav-right/chat-msg/chat-msg.component';
import { ConfigurationComponent } from './theme/layout/admin/configuration/configuration.component';
import { SettingsComponent } from './settings/settings.component';
import { FormsModule } from "@angular/forms";
import { ToastyModule } from "ng2-toasty";
import { ArchwizardModule } from "angular-archwizard";
import { MatTreeModule } from "@angular/material/tree";
import { MatIconModule } from '@angular/material/icon';
import { ContentAlertsModule } from "./content-alerts/content-alerts.module";
import { SecurityRolesModule } from "./security-roles/security-roles.module";
import { ProfileManagerComponent } from './theme/layout/admin/nav-bar/nav-right/profile-manager/profile-manager.component';
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthSigninModule } from "./auth-signin/auth-signin.module";
import { EventCalendarModule } from './event-calendar/event-calendar.module';
import { SchoolCyclesModule } from "./school-cycles/school-cycles.module";
import { SettingsSchoolModule } from "./settings-school/settings-school.module";

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    NavigationComponent,
    NavLogoComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavSearchComponent,
    NavBarComponent,
    ToggleFullScreenDirective,
    NavLeftComponent,
    NavRightComponent,
    ChatUserListComponent,
    FriendComponent,
    ChatMsgComponent,
    ConfigurationComponent,
    SettingsComponent,
    ProfileManagerComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbTabsetModule,
    FormsModule,
    ToastyModule,
    ArchwizardModule,
    MatTreeModule,
    MatIconModule,
    ContentAlertsModule,
    SettingsSchoolModule,
    SecurityRolesModule,
    MatDialogModule,
    NgbModule,
    AuthSigninModule,
    NgbPopoverModule,
    EventCalendarModule,
    SchoolCyclesModule
  ],
  providers: [
    NavigationItem,
    MatDialog,
    {
      provide: MAT_DIALOG_DATA,
      useValue: {}
    },
    {
      provide: MatDialogRef,
      useValue: {}
    },
    NgbRadioGroup,
    NgbButtonLabel,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
