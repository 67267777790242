import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastOptions, ToastyService } from 'ng2-toasty';
import { ContactResponse } from 'src/api/client/data-contracts';
import { Service } from 'src/api/service/Service';
import { StorageService } from 'src/api/service/storage.service';
import { ContactTypeEnum } from 'src/api/utils/Constants';

@Component({
  selector: 'app-relate-manager',
  templateUrl: './relate-manager.component.html',
  styleUrls: ['./relate-manager.component.scss']
})
export class RelateManagerComponent implements OnInit {

  title: string = '';
  subTitle: string = '';
  allContacts: Array<ContactResponse> = new Array<ContactResponse>();
  contacts: Array<ContactResponse> = new Array<ContactResponse>();
  alreadyRelated: string[] = [];
  contactsToRelate: string[] = [];
  contactsToUnrelate: string[] = [];
  loading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public selectedContact: ContactResponse,
    private storageService: StorageService,
    private dialogRef: MatDialogRef<RelateManagerComponent>,
    private toastyService: ToastyService,
  ) { }

  ngOnInit(): void {
    const service = new Service(this.storageService);
    switch (this.selectedContact.contact_type) {
      case 'PARENT':
        this.title = 'Asociar hijos';
        this.subTitle = 'Seleccione los hijos que desea asociar a';
        service.getAllUsersByRole("", 1, ContactTypeEnum.STUDENT).then((value:any) => {
          this.allContacts = value.data;
          this.contacts = value.data;
        });
        break;
      case 'STUDENT':
        this.title = 'Asociar padres';
        this.subTitle = 'Seleccione los padres que desea asociar a';
        service.getAllUsersByRole("", 1, ContactTypeEnum.PARENT).then(value => {
          this.allContacts = value;
          this.contacts = value;
        });
        break;
      default:
        break;
    }
    service.getContactChildren(this.selectedContact.id)
      .then((relatedContacts: ContactResponse[]) => {
        if (relatedContacts && relatedContacts.length > 0) {
          relatedContacts.forEach(relatedContact => {
            this.alreadyRelated.push(relatedContact.id);
          });
        }
      });
  }

  changeCheckbox(student: ContactResponse, checked: boolean) {
    if (checked) {
      const indexInAlreadyRelated = this.alreadyRelated.indexOf(student.id);
      const indexInToUnrelate = this.contactsToUnrelate.indexOf(student.id);
      if (indexInAlreadyRelated < 0) this.contactsToRelate.push(student.id);
      if (indexInToUnrelate >= 0) this.contactsToUnrelate.splice(indexInToUnrelate, 1);
    } else {
      const index = this.contactsToRelate.indexOf(student.id);
      const indexInAlreadyRelated = this.alreadyRelated.indexOf(student.id);
      if (indexInAlreadyRelated >= 0) this.contactsToUnrelate.push(student.id);
      if (index >= 0) this.contactsToRelate.splice(index, 1);
    }
  }

  async associate() {
    this.loading = true;
    const childContactsRequest = {
      "app_id": "admin-ui",
      "data": {
        "childs": this.contactsToRelate
      }
    };
    const service = new Service(this.storageService);
    const errors: string[] = [];
    try {
      if (this.contactsToRelate && this.contactsToRelate.length > 0) {
        await service.addChilds(this.selectedContact.id, childContactsRequest);
      }
    } catch (err) {
      errors.push(err);
    }
    try {
      if (this.contactsToUnrelate && this.contactsToUnrelate.length > 0) {
        await service.unrelateStudentsFromParent(this.selectedContact.id, this.contactsToUnrelate);
      }
    } catch (err) {
      errors.push(err);
    }
    if (errors.length === 0) {
      this.toastyService.clearAll();
      var toastOptions: ToastOptions = {
        title: "Exito",
        msg: "Relación guardada exitosamente.",
        timeout: 3000,
        theme: "default"
      };
      this.toastyService.info(toastOptions);
      this.close();
    } else {
      let msg: string;
      errors.forEach(error => {
        msg += `${error}, `;
      });
      this.toastyService.clearAll();
      var toastOptions: ToastOptions = {
        title: "Error",
        msg,
        timeout: 3000,
        theme: "default"
      };
      this.toastyService.error(toastOptions);
      this.loading = false;
    }
  }

  search(searchFor: string) {
    if (searchFor !== '') {
      this.contacts = this.allContacts.filter(contact =>
        contact.names.toLowerCase().includes(searchFor.toLowerCase())
        || contact.last_names.toLowerCase().includes(searchFor.toLowerCase()));
    } else {
      this.contacts = this.allContacts;
    }
  }

  close() {
    this.dialogRef.close();
  }

}
