import { Component, Input, OnInit } from '@angular/core';
import { MessageResponse } from 'src/api/client/data-contracts';

@Component({
  selector: 'app-message-preview',
  templateUrl: './message-preview.component.html',
  styleUrls: ['./message-preview.component.scss']
})
export class MessagePreviewComponent implements OnInit {

  @Input() selectedMessage: MessageResponse;

  constructor() { }

  ngOnInit(): void {
  }

}