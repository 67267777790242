import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastOptions, ToastyService } from 'ng2-toasty';
import { StorageService } from '../../../api/service/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivityShortResponse, ContactResponse, MediaAsset, ResourceResponse } from '../../../api/client/data-contracts';
import { Service } from '../../../api/service/Service';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateParserFormatter } from 'src/app/util/dateFormatterService';
import { GrantsService } from 'src/app/services/grants.service';
import { MediaPreviewModalComponent } from 'src/app/theme/shared/components/media-preview-modal/media-preview-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { saveAs } from 'file-saver';
import { ScoresManagerComponent } from './scores-manager/scores-manager.component';
import { DeleteScoreModalComponent } from './delete-score-modal/delete-score-modal.component';
import { StudentScoresModalComponent } from 'src/app/theme/shared/components/student-scores-modal/student-scores-modal.component';
import {FILES_NOT_PREVEW} from '../../../api/utils/Constants';

@Component({
  selector: 'app-form-content',
  templateUrl: './form-content.component.html',
  styleUrls: ['./form-content.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class FormContentComponent implements OnInit {

  position = 'top-center';
  id = '';
  activityToDelete = '';
  resourceToDelete = '';
  period = '';
  nameParam = '';
  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;
  activities = new Array<ActivityShortResponse>();
  resources = new Array<ResourceResponse>();
  students = [];
  activitiesByName: ActivityShortResponse[] = [];
  selectedStudent = null;
  todaySelected = false;
  weekSelected = false;
  monthSelected = false;
  rangeSelected = false;
  nameSelected = false;
  _studentsPage = 1;
  scores = [];
  existingUnitsIds: number[] = [];
  attachment: MediaAsset;

  @ViewChild('modalCreateActivitie', { static: true }) modalCreateActivite: ElementRef;
  @ViewChild('modalCreateResource', { static: true }) modalCreateResource: ElementRef;
  @ViewChild('modalFormActivitie', { static: true }) modalFormActivitie: ElementRef;
  @ViewChild('modalFormResource', { static: true }) modalFormResource: ElementRef;
  @ViewChild('modalDelete', { static: true }) modalDelete: ElementRef;
  @ViewChild('modalDeleteActivity', { static: true }) modalDeleteActivity: ElementRef;
  @ViewChild('modalDeleteResource', { static: true }) modalDeleteResource: ElementRef;
  @ViewChild('modalSearchActivity', { static: true }) modalSearchActivity: ElementRef;
  @ViewChild('modalConfirmationDownload', { static: true }) modalConfirmationDownload: ElementRef;

  constructor(
    private storageService: StorageService,
    private toastyService: ToastyService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public grantsService: GrantsService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.load();
  }

  load() {
    this.activatedRoute.queryParams.subscribe(params => {
      this.id = params.id;
      this.initializeSelected();
      this.updateDataActivities('');
      this.updateDataResources();
      this.updateDataStudents();
      this.updateDataScores();
    });
  }

  public updateDataActivities(params) {
    const service = new Service(this.storageService);
    service.getAllActivitiesFromSpace(this.id, params).then(acts => {
      acts.sort((a, b) => {
        const aDate = new Date(a.start_at),
          bDate = new Date(b.start_at);
        if (aDate < bDate) {
          return 1;
        }
        if (aDate > bDate) {
          return -1;
        }
        return 0;
      });
      this.activities = acts;
    });
  }

  public updateDataResources() {
    const service = new Service(this.storageService);
    service.getAllResourcesFromSpace(this.id).then(value => {
      this.resources = value;
    });
  }

  updateDataStudents() {
    const service = new Service(this.storageService);
    service.getStudentsBySpace(this.id).then(value => this.students = value);
  }

  updateDataScores() {
    const service = new Service(this.storageService);
    service.getScoresBySpace(this.id).then(value => {
      this.scores = value;
      this.scores.forEach(score => this.existingUnitsIds.push(score.unit.unit));
    });
  }

  selectDeleteStudent(student) {
    this.selectedStudent = student;
    // @ts-ignore
    this.modalDelete.show();
  }

  async deleteStudent() {
    const service = new Service(this.storageService);
    await service.unassignContactsStudent(this.id, [this.selectedStudent.id]).then(value => {
      this.toastyService.clearAll();
      const toastOptions: ToastOptions = {
        title: 'Exito',
        msg: 'Estudiante eliminado exitosamente.',
        timeout: 3000,
        theme: 'default'
      };
      this.toastyService.info(toastOptions);
      this.updateDataStudents();
      this.closeModalFormUser();
    }).catch(reason => {
      this.toastyService.clearAll();
      const toastOptions: ToastOptions = {
        title: 'Error',
        msg: reason.message,
        timeout: 5000,
        theme: 'default'
      };
      this.toastyService.error(toastOptions);
    });
    this.initializeSelected();
    // @ts-ignore
    this.modalDelete.hide();
  }

  deleteActivityShow(id) {
    // @ts-ignore
    this.modalDeleteActivity.show();
    this.activityToDelete = id;
  }

  deleteActivity() {
    const service = new Service(this.storageService);

    service.removeActivityFromSpace(this.activityToDelete).then((value) => {
      // @ts-ignore
      this.modalDeleteActivity.hide();
      this.toastyService.clearAll();
      const toastOptions: ToastOptions = {
        title: 'Exito',
        msg: 'Actividad eliminada exitosamente.',
        timeout: 3000,
        theme: 'default'
      };
      this.toastyService.error(toastOptions);
      this.activities = this.activities.filter((item) => item.id !== this.activityToDelete);
      this.activityToDelete = '';
    });
  }

  deleteResourceShow(id) {
    // @ts-ignore
    this.modalDeleteResource.show();
    this.resourceToDelete = id;
  }

  deleteResource() {
    const service = new Service(this.storageService);

    service.removeResourceFromActivity(this.id, this.resourceToDelete).then((value) => {
        if (!value){
          // @ts-ignore
          this.modalDeleteResource.hide();
          this.toastyService.clearAll();
          const toastOptions: ToastOptions = {
            title: 'Exito',
            msg: 'Recurso eliminada exitosamente.',
            timeout: 3000,
            theme: 'default'
          };
          this.toastyService.info(toastOptions);
          this.updateDataResources();
        }
      });
  }

  closeModalFormActivities() {
    // @ts-ignore
    this.modalCreateActivite.hide();
  }

  closeModalFormResource() {
    // @ts-ignore
    this.modalCreateResource.hide();
  }

  closeModalFormUser() {
    // @ts-ignore
    this.modalDelete.hide();
  }

  initializeSelected() {
    this.selectedStudent = {
      names: '',
      last_names: '',
      birth_date: '',
      gender: '',
      id: '',
    };
  }

  downloadResource(link) {
    const filename = link.file_name ? link.file_name : 'resource_file_' + Math.random();
    const a = document.createElement('a');
    a.download = filename;
    a.href = link.href;
    a.textContent = filename;
    document.body.appendChild(a);
    a.click();

    setTimeout(() => document.body.removeChild(a), 100);
  }

  openFilePreview(resource: MediaAsset) {
    console.log(resource);
    if ( FILES_NOT_PREVEW[resource.type]){
      this.attachment = resource;
      // @ts-ignore
      this.modalConfirmationDownload.show();
    }else {
      this.dialog.open(MediaPreviewModalComponent, {
        data: resource,
        height: '85%',
        width: '75%',
        autoFocus: false,
      }).afterClosed().subscribe(() => {
        // TODO If action needed after closed
      });
    }
  }

  goToTask(id: string) {
    const allCourses = '/all-courses/';
    const courses_url = window.location.href.indexOf(allCourses) > 0 ? allCourses : '/my-courses/';
    this.router.navigate([`menu/content${courses_url}form-content/activity`], { queryParams: { id } });
  }

  async exportExcel() {
    const service = new Service(this.storageService);
    await service.getStudentsExcel(this.id).then((data: any) => {
      const fileName = 'Estudiantes.xlsx';
      const fileBlob = this.dataURItoBlob(data);
      saveAs(fileBlob, fileName);
    }).catch(reason => {
      console.log(reason);
    });
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }
  openSearch() {
    // @ts-ignore
    this.modalSearchActivity.show();
  }

  doSearchActivities(rangeSelected: string) {
    this.period = rangeSelected;
    const params: { period?: string, from?: string, to?: string } = {};
    switch (this.period) {
      case 'NOW':
        this.todaySelected = true;
        this.weekSelected = false;
        this.monthSelected = false;
        this.rangeSelected = false;
        this.nameSelected = false;
        params.period = this.period;
        break;
      case 'WEEK':
        this.todaySelected = false;
        this.weekSelected = true;
        this.monthSelected = false;
        this.rangeSelected = false;
        this.nameSelected = false;
        params.period = this.period;
        break;
      case 'MONTH':
        this.todaySelected = false;
        this.weekSelected = false;
        this.monthSelected = true;
        this.rangeSelected = false;
        this.nameSelected = false;
        params.period = this.period;
        break;
      case 'RANGE':
        params.from = [this.addZeroToDate(this.fromDate.day), this.addZeroToDate(this.fromDate.month), this.fromDate.year].join('-');
        params.to = [this.addZeroToDate(this.toDate.day), this.addZeroToDate(this.toDate.month), this.toDate.year].join('-');
        params.period = this.period;
        break;
      case 'NAME':
        this.todaySelected = false;
        this.weekSelected = false;
        this.monthSelected = false;
        this.rangeSelected = false;
        this.nameSelected = true;
        this.activitiesByName = this.activities.slice();
        break;
      case '':
        this.todaySelected = false;
        this.weekSelected = false;
        this.monthSelected = false;
        this.rangeSelected = false;
        this.nameSelected = false;
        break;
    }

    if (!this.nameSelected) {
      this.updateDataActivities(params);
    }

  }

  searchByName() {
    this.activities = this.activitiesByName.filter((act) => act.title.toLowerCase().includes(this.nameParam.toLowerCase()));
  }

  selectRange() {
    this.todaySelected = false;
    this.weekSelected = false;
    this.monthSelected = false;
    this.nameSelected = false;
    this.rangeSelected = true;
  }

  addZeroToDate(date: number) {
    let value = '';
    if (date < 10) {
      value = `0${date}`;
    } else {
      value = `${date}`;
    }
    return value;
  }

  generateScore() {
    this.dialog.open(ScoresManagerComponent, {
      data: {
        spaceId: this.id,
        students: this.students,
        existingUnits: this.existingUnitsIds,
      },
      height: '85%',
      width: '75%',
      autoFocus: false,
    }).afterClosed().subscribe(() => {
      this.updateDataScores();
    });
  }

  scorePreview(score) {
    this.dialog.open(ScoresManagerComponent, {
      data: {
        spaceId: this.id,
        score,
      },
      height: '85%',
      width: '75%',
      autoFocus: false,
    });
  }

  showSpaceScores(student: ContactResponse) {
    this.selectedStudent = student;
    this.dialog.open(StudentScoresModalComponent, {
      data: {
        selectedStudent: this.selectedStudent,
        space: { id: this.id },
        selectUnit: false,
        showSpace: false,
      },
      height: '75%',
      width: '55%',
      autoFocus: false,
    });
  }

  downloadScores(score) {
    const service = new Service(this.storageService);
    service.downloadScoreInfo(this.id, score.id).then((data: any) => {
      const fileName = `Notas para la Unidad ${score.unit.unit}.xlsx`;
      const fileBlob = this.dataURItoBlob(data);
      saveAs(fileBlob, fileName);
    }).catch(reason => {
      console.log(reason);
    });
  }

  deleteScore(score) {
    this.dialog.open(DeleteScoreModalComponent, {
      data: {
        spaceId: this.id,
        scoreId: score.id,
      },
      height: '65%',
      width: '65%',
      autoFocus: false,
    }).afterClosed().subscribe(deleted => {
      deleted && this.updateDataScores();
    });
  }

  get studentsPage() {
    return this._studentsPage;
  }

  set studentsPage(page: number) {
    this._studentsPage = page;
  }

  downloadAttachment(attachment: MediaAsset, close: boolean) {
    if ( close ){
      // @ts-ignore
      this.modalConfirmationDownload.hide();
    }
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', attachment.href);
    link.setAttribute('download', `${attachment.file_name == null ? 'archivo' : attachment.file_name}`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

}
