<div class="row align-items-center justify-content-center btn-div mb-3">
    <div class="col">
        <div class="row align-items-end justify-content-center">
            <div class="col text-right">
                <div *ngIf="grantsService.validateByNameAndType('AGREGAR', 'ACTION')">
                    <button class="btn btn-primary add-btn" (click)="createCycle()" *ngIf="true">
                        Agregar Nuevo Ciclo <i class="fas fa-plus f-14 mr-0 ml-2"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<app-card [hidHeader]="true" [options]="false" cardClass="msg-card mb-0" blockClass="card-body msg-block">
    <div style="overflow: overlay;">
        <table class="table">
            <thead>
                <tr>
                    <th class="table-title">Nombre del ciclo</th>
                    <th class="table-title">Sucursal</th>
                    <th class="table-title">Inicio del ciclo</th>
                    <th class="table-title">Fin de ciclo</th>
                    <th class="table-title">Estado</th>
                    <th class="table-title">Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let cycle of cycles" class="table-row">
                    <td>
                        <h6 class="ml-0 mr-0 mb-0" style="margin-top: 0.8rem;">
                            {{cycle.name}}
                        </h6>
                    </td>
                    <td>
                        <h6 class="ml-0 mr-0 mb-0" style="margin-top: 0.8rem;">
                           {{cycle.branch?.name}}
                        </h6>
                    </td>
                    <td>
                        <h6 class="ml-0 mr-0 mb-0" style="margin-top: 0.8rem;">
                            {{ cycle.start_at.split(' ')[0] }}
                        </h6>
                    </td>
                    <td>
                        <h6 class="ml-0 mr-0 mb-0" style="margin-top: 0.8rem;">
                            {{ cycle.end_at.split(' ')[0] }}
                        </h6>
                    </td>
                    <td>
                        <div *ngIf="cycle.enabled===true">
                          <h6 style="margin-top: 0.8rem;" class="active">Activo</h6>
                          <div class="active-circle m-0 ml-2"></div>
                        </div>
                        <div *ngIf="cycle.enabled===false">
                          <h6 style="margin-top: 0.8rem;" class="inactive">Inactivo</h6>
                          <div class="inactive-circle m-0 ml-2"></div>
                        </div>
                      </td>
                    <td>
                        <ng-template #cycleActions>
                          <span *ngIf="grantsService.validateByNameAndType('EDITAR', 'ACTION')" class="dropdown-button dropdown-item btn-glow-info btn-info pointer pl-2 pr-2" 
                                (click)="updateCycle(cycle)">
                            <i class="mr-1 fas fa-edit" [placement]="'top'" ngbTooltip="Editar" (click)="updateCycle(cycle)"></i>
                            Editar
                          </span>
                          <span *ngIf="(grantsService.validateByNameAndType('ABRIR_CICLO', 'ACTION')) && cycle.enabled===false" class="mt-2 dropdown-button dropdown-item btn-glow-success btn-success pointer pl-2 pr-2" 
                                (click)="changeCycleStatus(cycle, 'activate')">
                            <i class="mr-1 fas fa-check-circle" [placement]="'top'" ngbTooltip="Activar" (click)="changeCycleStatus(cycle, 'activate')"></i>
                            Activar
                          </span>
                          <span *ngIf="(grantsService.validateByNameAndType('ABRIR_CICLO', 'ACTION')) && cycle.enabled===true" class="mt-2 dropdown-button dropdown-item btn-glow-danger btn-danger pointer pl-2 pr-2"
                                (click)="changeCycleStatus(cycle, 'deactivate')">
                            <i class="mr-1 fas fa-times-circle" [placement]="'top'" ngbTooltip="Desactivar" (click)="changeCycleStatus(cycle, 'deactivate')"></i>
                            Desactivar
                          </span>
                        </ng-template>
                        <button type="button" class="btn drp-icon btn-outline-primary" [placement]="'bottom'"
                          [ngbPopover]="cycleActions">
                          <i class="fas fa-cog"></i>
                        </button>
                      </td>
                </tr>
            </tbody>
        </table>
        <div class="d-flex justify-content-center">
            <h5 *ngIf="cycles?.length === 0">No hay ningún ciclo creado.</h5>
        </div>
    </div>
</app-card>
<ng2-toasty [position]="position"></ng2-toasty>