<div class="d-flex flex-column h-100">
    <div class="app-modal-header mb-2 d-flex flex-row justify-content-between align-items-center">
        <div>
            <h5 class="modal-title">
                {{title}}
            </h5>
        </div>
        <div>
            <button type="button" class="close basic-close" style="top: -5%;" data-dismiss="modal" aria-label="Close"
                (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="app-modal-body text-center mt-auto">
        <div *ngIf="cycleAndRequest.statusChangeRequest === 'activate'" class="col-sm-12 ">
            <div *ngIf="activeCycle">
                <span>
                    Si activa este ciclo {{ cycleAndRequest.cycle.name }}, el ciclo {{ activeCycle.name }} se cerrará y
                    desactivará automáticamente.
                </span>
            </div>
            <div *ngIf="!activeCycle">
                <span>
                    Si activa este ciclo {{ cycleAndRequest.cycle.name }}, todas las tareas y actividades relacionadas
                    se
                    activarán con el.
                </span>
            </div>
            <span>
                ¿Aún desea activar el ciclo {{ cycleAndRequest.cycle.name }}?
            </span>
        </div>
        <div *ngIf="cycleAndRequest.statusChangeRequest === 'deactivate'" class="col-sm-12 ">
            <span>
                Si desactiva este ciclo {{ cycleAndRequest.cycle.name }}, no se podrán recibir tareas, ni agendar
                actividades relacionadas con el.
            </span>
            <span>
                ¿Aún desea activar el ciclo {{ cycleAndRequest.cycle.name }}?
            </span>
        </div>
    </div>
    <div class="app-modal-footer mt-auto">
        <div *ngIf="loading" class="card-body d-flex flex-row justify-content-center mt-5">
            <div class="spinner-border text-info" role="status">
                <span class="sr-only"></span>
            </div>
        </div>
        <div *ngIf="!loading" class="d-flex flex-row justify-content-end">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()">
                Cancelar
            </button>
            <button type="button" class="btn btn-primary" (click)="changeCycleStatus()">
                {{ acceptButtonText }}
            </button>
        </div>
    </div>
</div>