import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastOptions, ToastyService } from 'ng2-toasty'
import { CreateRole } from 'src/api/client/data-contracts'
import { Service } from 'src/api/service/Service'
import { StorageService } from 'src/api/service/storage.service'
import { SecurityRole } from '../models/security-roles-models'

@Component({
  selector: 'app-security-roles',
  templateUrl: './security-roles.component.html',
  styleUrls: ['./security-roles.component.scss']
})
export class SecurityRolesComponent implements OnInit {

  position = 'top-center';
  _page: number = 1;
  securityRoles: SecurityRole[] = [];
  createUpdateTitle = 'Crear';
  titleSelected = '';
  securityRoleToDelete = '';

  @ViewChild('modalCreate', { static: true }) modalCreate: ElementRef;
  @ViewChild('modalForm', { static: true }) modalForm: ElementRef;
  @ViewChild('modalDelete', { static: true }) modalDelete: ElementRef;


  constructor(private storageService: StorageService, private toastyService: ToastyService) { }

  ngOnInit(): void {
    this.getSecurityRoles();
  }

  getSecurityRoles() {
    const service = new Service(this.storageService);
    this._page = 1;
    service.getAllSecurityRoles(this.page).then(securityRoles => {
      this.securityRoles = securityRoles;
    });
  }

  toggleEnabled(securityRole: SecurityRole) {
    const service = new Service(this.storageService);
    service.toggleEnableSecurityRoles(securityRole.id, securityRole.disabled).then(() => {
      this.securityRoles = this.securityRoles.map((secRole: SecurityRole) => {
        if (secRole.id === securityRole.id) {
          return ({ ...secRole, disabled: !securityRole.disabled })
        } else {
          return secRole;
        }
      }
      )
    });
  }

  createUpdate(securityRole?: SecurityRole) {
    if (securityRole) {
      this.createUpdateTitle = 'Actualizar';
      this.titleSelected = securityRole.name;
      // @ts-ignore
      this.modalForm.initialize(securityRole.id, securityRole);
    } else {
      this.createUpdateTitle = 'Crear';
      this.titleSelected = '';
      // @ts-ignore
      this.modalForm.initialize('', null);
    }
     // @ts-ignore
     this.modalCreate.show();
  }

  deleteSecurityRoleShow(id: string) {
     // @ts-ignore
     this.modalDelete.show();
     this.securityRoleToDelete = id;
    }
    
  deleteSecurityRole() {
    const service = new Service(this.storageService);
    service.deleteSecurityRole(this.securityRoleToDelete).then((val) => {
      // @ts-ignore
      this.modalDelete.hide();
      this.toastyService.clearAll();
      var toastOptions: ToastOptions = {
        title: "Exito",
        msg: "Rol de seguridad eliminado exitosamente.",
        timeout: 3000,
        theme: "default"
      };
      this.toastyService.error(toastOptions);

      this.securityRoles = this.securityRoles.filter((item) => item.id !== this.securityRoleToDelete);
      this.securityRoleToDelete = '';
    })

  }

  addSecurityRoleChanged(securityRole: SecurityRole) {
    // 0 === create
    if (securityRole.action === 0) {
      this.securityRoles.push(securityRole);
    } else if (securityRole.action === 1) {
      this.securityRoles = this.securityRoles.map((secRole) => {
        if (secRole.id === securityRole.id) {
          return { ...secRole, name: securityRole.name, keyword: securityRole.keyword, grants: securityRole.grants };
        }
        return secRole;
      })
    }
  }

  closeModalForm() {
    // @ts-ignore
    this.modalCreate.hide();
  }

  get page() {
    return this._page;
  }

  set page(page: number) {
    this._page = page;
  }

}
