import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastOptions, ToastyService } from 'ng2-toasty';
import { Service } from 'src/api/service/Service';
import { StorageService } from 'src/api/service/storage.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  position = 'top-center';
  title: string = 'Cambia tu contraseña';
  form: FormGroup;
  userId: number;
  token: string;
  newPassType = 'password';
  confPassType = 'password';
  newPassVisible = false;
  confPassVisible = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private toastyService: ToastyService,
    private activatedRoute: ActivatedRoute,
    private storageService: StorageService,
  ) { }

  ngOnInit(): void {
    this.token = this.activatedRoute.snapshot.params.hash_token;
    if (!this.token) {
      this.title = "No hay token"
    }
    !this.form && this.buildForm();
  }

  ngOnDestroy(): void {
    this.form && this.form.reset();
  }

  buildForm() {
    this.form = this.fb.group({
      password: ['', [Validators.required]],
      passwordConfirm: ['', [Validators.required, this.verifyMatch()]],
    });
  }

  changePassword() {
    this.toastyService.clearAll();
    var toastOptions: ToastOptions = {
      title: "Espere",
      msg: "Procesando petición",
      timeout: 5000,
      theme: "default"
    };
    this.toastyService.wait(toastOptions);
    const service = new Service(this.storageService);
    const newPasswordRequest = {
      ...this.form.value
    };
    service.confirmPasswordReset(this.token, newPasswordRequest).then(() => {
      this.toastyService.clearAll();
      var toastOptions: ToastOptions = {
        title: "Exito",
        msg: "Contraseña actualizada con éxito",
        timeout: 6000,
        theme: "default"
      };
      this.toastyService.info(toastOptions);
      setTimeout(() => {
        this.cancel();
      }, 6050);
    }).catch(reason => {
      this.toastyService.clearAll();
      var toastOptions: ToastOptions = {
        title: "Error",
        msg: reason.message,
        timeout: 5000,
        theme: "default"
      };
      this.toastyService.error(toastOptions);
    });
  }

  verifyMatch() {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        const newPass = this.form.get('password').value;
        const confirmPass = this.form.get('passwordConfirm').value;
        return (newPass !== confirmPass) ? { noMatch: true } : null
      }
      return null;
    }
  }

  toggleVisibility(req: string) {
    switch (req) {
      case 'newPass':
        if (this.newPassVisible) {
          this.newPassType = 'password';
          this.newPassVisible = false;
        } else {
          this.newPassType = 'text';
          this.newPassVisible = true;
        }
        break;
      case 'confirmPass':
        if (this.confPassVisible) {
          this.confPassType = 'password';
          this.confPassVisible = false;
        } else {
          this.confPassType = 'text';
          this.confPassVisible = true;
        }
        break;
    }
  }

  cancel() {
    this.router.navigate(['']);
  }

}