<div class="row align-items-center justify-content-center">
    <div class="col icon" (click)="onClick()">
        <i class="far text-c-blue f-40 float-left" [ngClass]="icon === 'file' ? 'fa-file-alt' : 'fa-calendar-alt'"></i>
    </div>
    <div class="col text" (click)="onClick()">
        <div class="row align-items-center justify-content-center">
            <div class="col">
                <h5 class="m-0 title">{{name}}</h5>
                <p class="description">{{description}}</p>
                <p *ngIf="date" class="date mb-0">Fecha: {{date}}</p>
            </div>
        </div>
    </div>
    <div class="col preview">
        <i class="text-c-blue f-20 float-right fas fa-file-download" alt="Descargar" (click)="showPreview()"></i>
    </div>
    <div class="col delete">
        <i class="far text-c-red f-20 float-right fa-trash-alt" alt="Eliminar" (click)="onDelete()" *ngIf="grantsService.validateByNameAndType('ELIMINAR_RECURSOS', 'ACTION')"></i>
    </div>
</div>
