import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreeComponent } from './tree.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { TreeviewModule } from 'ngx-treeview';
import {MatTreeModule} from '@angular/material/tree';
import {MatIconModule} from '@angular/material/icon';
@NgModule({
    declarations: [TreeComponent],
    exports: [
        TreeComponent
    ],
    imports: [
        CommonModule,
        NgbModule,
        TreeviewModule,
        MatTreeModule,
        MatIconModule,
    ]
})
export class TreeEduk2Module { }
