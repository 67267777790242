import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GrantsService } from 'src/app/services/grants.service';
import {ActivityType} from '../../../../api/utils/Constants';
@Component({
  selector: 'app-card-space-form',
  templateUrl: './card-space-form.component.html',
  styleUrls: ['./card-space-form.component.scss']
})
export class CardSpaceFormComponent implements OnInit {

  @Input() name: string;
  @Input() description: string;
  @Input() dates: {
    endDate: string,
    endTime: string,
  };
  @Input() icon: string;
  @Output() spaceCardClick: EventEmitter<void> = new EventEmitter();
  @Output() spaceCardDelete: EventEmitter<void> = new EventEmitter();

  startDate: string = '';
  startTime: string = '';
  endDate: string = '';
  endTime: string = '';
  taskType: ActivityType;

  constructor(
    public grantsService: GrantsService,
  ) { }

  ngOnInit(): void {
    //this.startDate = this.convertDateFormat(this.dates.startDate);
    this.endDate = this.convertDateFormat(this.dates.endDate);
    //this.startTime = this.convert24hourTo12HourFormat(this.dates.startTime);
    if (this.dates.endTime){
      this.endTime = this.convert24hourTo12HourFormat(this.dates.endTime);
    }
  }

  convert24hourTo12HourFormat(time) {
    const separatedTimeArray = time.split(":");
    let ampm = 'AM';
    if (separatedTimeArray[0] >= 12) {
      ampm = 'PM';
    }
    if (separatedTimeArray[0] > 12) {
      separatedTimeArray[0] = separatedTimeArray[0] - 12;
    }
    const formattedTime = separatedTimeArray[0] + ':' + separatedTimeArray[1] + ' ' + ampm;
    return formattedTime;
  }

  convertDateFormat(date: string) {
    const separatedDateArray = date.split('-');
    const convertedDate = `${separatedDateArray[2]}-${separatedDateArray[1]}-${separatedDateArray[0]}`;
    return convertedDate;
  }

  onClick() {
    this.spaceCardClick.emit();
  }

  onDelete() {
    this.spaceCardDelete.emit();
  }

}
