import { Component, Input, OnInit } from '@angular/core';
import { UploadsNotifierService } from '../../services/uploads-notifier.service';

@Component({
  selector: 'app-uploads-notifier',
  templateUrl: './uploads-notifier.component.html',
  styleUrls: ['./uploads-notifier.component.scss']
})
export class UploadsNotifierComponent implements OnInit {

  @Input() showButtons: boolean = true;
  
  pendingUploads: any[] = [];
  uploadErrors: any[] = [];
  notifierHidden: boolean = false;

  constructor(
    public uploadsNotifierService: UploadsNotifierService,
  ) { }

  ngOnInit(): void {
    this.uploadsNotifierService.updatedUploadsList$.subscribe(value => {
      this.pendingUploads = value;
    });
  }

  hideNotifier() {
    this.notifierHidden = !this.notifierHidden;
  }

  closeNotifier() {
    this.uploadsNotifierService.toggleNotifierVisibility();
  }

}