<article>
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" style="margin-right: 15px">
        <mat-tree-node
                *matTreeNodeDef="let node" matTreeNodePadding
                [ngClass]="{selected: idSelected === node.id}"
                class="mb-4">
            <section *ngIf="node?.type !== 'CLASSROOM';else clr">
                <button mat-icon-button matTreeNodeToggle
                        [attr.aria-label]="'Toggle ' + node.name"
                        [id]="node.id"
                        (click)="onSelectedChangeObs(node)"
                        class="button-icon iconBtn">

                    <div class="row">
                        <div class="col-md-5 mr-0 ml-0 pr-0 pl-0">
                            <mat-icon>
                                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                            </mat-icon>
                            <div *ngIf="node?.avatar;else iconOpt">
                                <img src={{node.avatar}} alt="icon" class="imgIcon">
                            </div>
                            <ng-template #iconOpt>
                                <mat-icon style="margin: 3px 0px 0px 8px; font-size: 19px;">
                                    {{ getIcon(node.type) }}
                                </mat-icon>
                            </ng-template>
                        </div>
                        <div class="col-md-7 ml-0 pl-0">
                        <span class="titleNodeCh">
                            {{node.name}}
                        </span>
                        </div>
                    </div>

                </button>
            </section>
            <ng-template #clr>
                <button
                        mat-icon-button
                        class="button-icon iconBtnCl"
                        (click)="openClass(node)">
                    <div class="row">
                        <div class="col-md-4 mr-0 ml-0 pl-0 pr-0">
                            <mat-icon>
                                {{ getIcon(node.type) }}
                            </mat-icon>
                        </div>
                        <div class="col-md-8 ml-0 pl-0">
                            <span class="titleNodeCh">
                                {{node.name}}
                            </span>
                        </div>
                    </div>
                </button>
            </ng-template>
        </mat-tree-node>
    </mat-tree>
</article>


