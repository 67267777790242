import {Component, Input, OnInit} from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'app-formatted-date',
    templateUrl: './formatted-date.component.html',
    styleUrls: ['./formatted-date.component.scss']
})
export class FormattedDateComponent implements OnInit {

    // TODO: use moment-timezones afterwards
    // TODO: enable later to switch based on list https://gist.github.com/diogocapela/12c6617fc87607d11fd62d2a4f42b02a

    constructor() {
    }

    static readonly HOURS_DIFF_UTC: number = -6;
    static readonly DATE_FORMAT: string = 'DD/MM/YYYY HH:mm:ss A';
    @Input() inputDate: string;
    outputDate: string;

    ngOnInit(): void {
        moment.locale('es');
        const parsed = moment(this.inputDate, FormattedDateComponent.DATE_FORMAT);
        if (parsed.isValid()) {
            this.outputDate = parsed.add(FormattedDateComponent.HOURS_DIFF_UTC, 'hours')
                .format('LLLL');
        } else {
            const nativeParsedDate = moment(this.inputDate);
            this.outputDate = nativeParsedDate.add(FormattedDateComponent.HOURS_DIFF_UTC, 'hours')
                .format('LLLL');
        }
    }

}
