<div class="d-flex flex-column h-100">
    <div class="app-modal-header mb-2 d-flex flex-row justify-content-between align-items-center">
        <div>
            <h5 class="modal-title">
                {{title}}
            </h5>
        </div>
        <div>
            <button type="button" class="close basic-close" style="top: -5%;" data-dismiss="modal" aria-label="Close"
                (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="app-modal-body">
        <form *ngIf="incomingData.selectUnit" [formGroup]="form">
            <div class="col-md-6">
                <div class="form-group">
                    <label class="form-control-label" for="unit">Unidad</label>
                    <select class="form-control" name="unit" formControlName="unit" required
                        [ngClass]="{'is-invalid': form.get('unit').touched && form.get('unit').hasError('required')}">
                        <option *ngFor="let unit of cycleUnits" [value]="unit.id">
                            Unidad {{ unit.unit }}
                        </option>
                        <option value="total">
                            Nota total
                        </option>
                    </select>
                    <label *ngIf="form.get('unit').touched && form.get('unit').hasError('required')"
                        id="validation-phone-type-error"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        Seleccione una unidad
                    </label>
                </div>
            </div>
        </form>
        <div *ngIf="(form?.get('unit').value || !incomingData.selectUnit) && (studentScores && studentScores.length > 0)"
            class="col-md-12 pl-0 pr-0">
            <div class="table-responsive">
                <div class="d-flex flex-row justify-content-center mt-2 mb-2">
                    <h3>
                        {{ tabTitle }}
                    </h3>
                </div>
                <table class="table">
                    <thead>
                        <tr>
                            <th *ngIf="incomingData.showSpace" class="text-center col-1">Curso</th>
                            <th *ngIf="compute" class="text-center col-1">Unidad</th>
                            <th class="text-center col-3">Nota Cargada</th>
                            <th class="text-center col-2">Nota Actividades Entregadas</th>
                            <th class="text-center col-5">Total</th>
                            <th class="text-center col-5">Resultado</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let score of studentScores">
                            <th *ngIf="incomingData.showSpace" class="text-center align-middle" scope="row">
                                {{ score.space.title || 'N/A' }}
                            </th>
                            <td *ngIf="compute" class="text-center align-middle">
                                {{ score.unit.unit || 'N/A' }}
                            </td>
                            <td class="text-center align-middle">
                                {{ ( score.score_loads % 1 === 0 ? score.score_loads :  score.score_loads.toFixed(2)) || 0 }}
                            </td>
                            <td class="text-center align-middle">
                                {{ ( score.score_activities % 1 === 0 ? score.score_activities :  score.score_activities.toFixed(2)) || 0 }}
                            </td>
                            <td class="text-center align-middle">
                                {{ ( score.total_score % 1 === 0 ? score.total_score :  score.total_score.toFixed(2)) || 0 }}
                            </td>
                            <td class="text-center align-middle">
                                <div *ngIf="score.approved">
                                    <h6 style="margin-top: 0.8rem;" class="passed">Aprobada</h6>
                                </div>
                                <div *ngIf="!score.approved">
                                    <h6 style="margin-top: 0.8rem;" class="failed">Reprobada</h6>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div
            *ngIf="(form?.get('unit').value || !incomingData.selectUnit) && !(studentScores && studentScores.length > 0)">
            <div class="d-flex flex-row justify-content-center mt-5">
                <h3>
                    No hay notas
                </h3>
            </div>
        </div>
    </div>
</div>
