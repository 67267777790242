<div class="app-modal-header">
    <button type="button" class="close basic-close" style="top: -5%;" data-dismiss="modal" aria-label="Close"
        (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="app-modal-body d-flex flex-row mt-2" style="height: 87%; max-height: 89%;">
    <ng-container [ngSwitch]="contentAlert.contentType">
        <div *ngSwitchCase="'ACTIVITY'" class="d-flex justify-content-center align-items-center w-100">
            <app-activity-preview [selectedActivity]="contentAlert.alert" class="w-100">
            </app-activity-preview>
        </div>
        <div *ngSwitchCase="'MESSAGE'" class="d-flex justify-content-center w-100">
            <app-message-preview [selectedMessage]="contentAlert.alert">
            </app-message-preview>
        </div>
        <div *ngSwitchCase="'RESOURCE'" style="width: 100%; height: 73vh;">
            <app-resource-preview [selectedResource]="contentAlert.alert">
            </app-resource-preview>
        </div>
    </ng-container>
</div>
<div class="app-modal-footer d-flex justify-content-end">
    <button type="button" class="btn-sm btn-primary btn-glow-primary" data-dismiss="modal" (click)="closeModal()">
        Cerrar
    </button>
</div>