import { SelectionModel } from '@angular/cdk/collections'
import { FlatTreeControl } from '@angular/cdk/tree'
import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree'
import { ToastOptions, ToastyService } from 'ng2-toasty'
import { Observable, of } from 'rxjs'
import { Grant } from 'src/api/client/data-contracts'
import { Service } from 'src/api/service/Service'
import { StorageService } from 'src/api/service/storage.service'
import { SecurityRole } from '../models/security-roles-models'
import { GrantsService } from '../../services/grants.service'

/**
 * Node for to-do item
 */
export class TodoItemNode {
  children: TodoItemNode[]
  item: string
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
  item: string
  level: number
  expandable: boolean
  index: number
}

@Component({
  selector: 'app-form-security-role',
  templateUrl: './form-security-role.component.html',
  styleUrls: ['./form-security-role.component.scss'],
})
export class FormSecurityRoleComponent implements OnInit  {
  // @Input() parentId: string;
  @Output() securityRolesChanged: EventEmitter<SecurityRole> =
    new EventEmitter()
  @Output() close: EventEmitter<void> = new EventEmitter()

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap: Map<TodoItemFlatNode, TodoItemNode> = new Map<
    TodoItemFlatNode,
    TodoItemNode
  >()

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap: Map<TodoItemNode, TodoItemFlatNode> = new Map<
    TodoItemNode,
    TodoItemFlatNode
  >()

  treeControl: FlatTreeControl<TodoItemFlatNode>

  treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>

  treeControlUpdate: FlatTreeControl<TodoItemFlatNode>

  treeFlattenerUpdate: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>

  dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>

  dataSourceUpdate: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */)

  /** A selected parent node to be inserted */
  selectedParent: TodoItemFlatNode | null = null

  /** The new item's name */
  newItemName: string = ''

  formInput: SecurityRole
  grants: Grant[] = []
  isUpdate: boolean
  id: string;
  parentNodes: TodoItemFlatNode[] = [];
  grantsToInsert: Grant[] = [];
  codePattern = /^[A-Za-z0-9]*$/;

  constructor(
    private storageService: StorageService,
    private toastyService: ToastyService,
    private grantsService: GrantsService,
  ) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    )
    this.treeControl = new FlatTreeControl<TodoItemFlatNode>(
      this.getLevel,
      this.isExpandable
    )
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    )
    this.treeControlUpdate = new FlatTreeControl<TodoItemFlatNode>(
      this.getLevel,
      this.isExpandable
    )
    this.treeFlattenerUpdate = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    )
    this.dataSourceUpdate = new MatTreeFlatDataSource(
      this.treeControlUpdate,
      this.treeFlattenerUpdate
    )
  }

  ngOnInit(): void {
    this.initializeForm();
    this.loadGrants();
    const data = this.buildFileTree({}, 0)
    this.dataSource.data = data
  }

  loadGrants() {
    const service = new Service(this.storageService)
    service.getAllUIGrants().then((systemGrants) => {
      const uigrantsObject = this.grantsService.mapGrantToObject(systemGrants, false);
      const data = this.buildFileTree(uigrantsObject, 0)
      this.dataSource.data = data;
    });
  }

  initializeForm() {
    this.formInput = {
      name: '',
      keyword: '',
      grants: [],
    }
  }

  initialize(id: string, securityRole: SecurityRole) {
    this.treeControl.dataNodes.forEach((node) => {
      this.checklistSelection.deselect(node);
    });

    if (securityRole) {
      this.isUpdate = true
      this.formInput = { ...securityRole }
      this.id = id;
      const service = new Service(this.storageService);
      service.getSecurityRole(id).then((secRole: any) => {
        const uigrantsObject = this.grantsService.mapGrantToObject(secRole.grants, true);
        const data = this.buildFileTree(uigrantsObject, 0);
        this.dataSourceUpdate.data = data;
        const selectedNodes = this.treeControlUpdate.dataNodes;

        const dataNodes = this.treeControl.dataNodes;
        for (let i = selectedNodes.length - 1; i >= 0; i--) {
          for (let j = 0; j <= dataNodes.length - 1; j++) {
             if (selectedNodes[i].item === dataNodes[j].item && selectedNodes[i].level === dataNodes[j].level && selectedNodes[i].expandable === dataNodes[j].expandable) {
               this.todoItemSelectionToggle(dataNodes[j], true);
               selectedNodes.splice(i, 1);
               break;
             }
          }
        }
      })
    } else {
      this.isUpdate = false
      this.initializeForm()
      this.id = ''
    }
  }

  save(form: any) {
    if (form.valid) {
      this.toastyService.clearAll()
      var toastOptions: ToastOptions = {
        title: 'Espere',
        msg: 'Guardando registro',
        timeout: 3000,
        theme: 'default',
      }
      this.toastyService.wait(toastOptions)
      const service = new Service(this.storageService)

      if (this.isUpdate) {
        this.formInput.grants = this.addGrantsToObject();
        service
          .updateSecurityRole(this.id, this.formInput)
          .then(() => {
            this.formInput.action = 1
            this.formInput.id = this.id

            this.securityRolesChanged.emit(this.formInput)
            this.close.emit()
            this.initializeForm()
          })
          .catch((error) => {
            this.toastyService.clearAll()
            var toastOptions: ToastOptions = {
              title: 'Error',
              msg: error,
              timeout: 5000,
              theme: 'default',
            }
            this.toastyService.error(toastOptions)
          })
      } else {
        this.formInput.grants = this.addGrantsToObject();

        service
          .createSecurityRole(this.formInput)
          .then((securityRole: any) => {
            this.toastyService.clearAll()
            var toastOptions: ToastOptions = {
              title: 'Exito',
              msg: 'Rol de seguridad creado exitosamente.',
              timeout: 3000,
              theme: 'default',
            }
            this.toastyService.info(toastOptions)

            this.formInput.action = 0

            this.securityRolesChanged.emit(this.formInput)
            this.close.emit()
            this.initializeForm()
          })
          .catch((error) => {
            this.toastyService.clearAll()
            var toastOptions: ToastOptions = {
              title: 'Error',
              msg: error,
              timeout: 5000,
              theme: 'default',
            }
            this.toastyService.error(toastOptions)
          })
      }
    }
  }
  
  addGrantsToObject() {
    const inputGrants = [];
    const selectedLevelZero = this.checklistSelection.selected.filter((node) => node.level === 0);
    selectedLevelZero.forEach((rootNode) => {
      const descendantsSelected = this.treeControl.getDescendants(rootNode)
        .filter((desc) => this.checklistSelection.selected.includes(desc));
      descendantsSelected.splice(0, 0, rootNode);

      let mapToGrantDescendants = descendantsSelected.map((node) => {
        const item = node.item.split('-');
        const type = item[1];
        const id = type.slice(type.length - 6);
        return ({ name: item[0].trim(), type: type.slice(0, type.length - 7).trim(), level: node.level, grants: [], id });
      });

      this.grantsService.createGrantArray(mapToGrantDescendants);
      const grants = mapToGrantDescendants
        .filter((grantDesc) => grantDesc.level === 0)
        .map((grant) => 
          ({ name: grant.name, type: grant.type, grants: this.grantsService.mapReverseInnerGrant(grant.grants), id: grant.id })
        );
        
      // @ts-ignore
      inputGrants.push(...grants);
    });
    return inputGrants;
  }

  getLevel = (node: TodoItemFlatNode) => {
    return node.level
  }

  isExpandable = (node: TodoItemFlatNode) => {
    return node.expandable
  }

  getChildren = (node: TodoItemNode): Observable<TodoItemNode[]> => {
    return of(node.children)
  }

  hasChild = (_: number, _nodeData: TodoItemFlatNode) => {
    return _nodeData.expandable
  }

  hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => {
    return _nodeData.item === ''
  }

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: TodoItemNode, level: number) => {
    let flatNode =
      this.nestedNodeMap.has(node) &&
      this.nestedNodeMap.get(node)!.item === node.item
        ? this.nestedNodeMap.get(node)!
        : new TodoItemFlatNode()
    flatNode.item = node.item
    flatNode.level = level
    flatNode.expandable = !!node.children
    this.flatNodeMap.set(flatNode, node)
    this.nestedNodeMap.set(node, flatNode)
    return flatNode
  }

  /** Whether all the descendants of the node are selected */
  descendantsAllSelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node)
    return descendants.every((child) =>
      this.checklistSelection.isSelected(child)
    )
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node)
    const result = descendants.some((child) =>
      this.checklistSelection.isSelected(child)
    )
    return result && !this.descendantsAllSelected(node)
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: TodoItemFlatNode, isUpdate: boolean): void {
    if (isUpdate) {
      this.checklistSelection.select(node);
    } else {
      this.checklistSelection.toggle(node);
      if (node.expandable) {
        const descendants = this.treeControl.getDescendants(node);
        this.checklistSelection.isSelected(node)
          ? this.checklistSelection.select(...descendants)
          : this.checklistSelection.deselect(...descendants);
      }
  
      const parents = [];
      this.getParentsNode(node, parents);
  
  
      if (!this.checklistSelection.isSelected(node) && parents.length > 0) {
        const selected = this.checklistSelection.selected.filter((nodeSelected) => nodeSelected.level === node.level);
        if (selected.length > 0) {
          const hasSiblings = selected.some((sibling) => {
            const parent = this.getParentNode(sibling);
            return JSON.stringify(parent) === JSON.stringify(parents[0]);
          });
          if (!hasSiblings) {
            this.checklistSelection.deselect(...parents);
          }
        } else {
          // last child then deselected parents
          this.checklistSelection.deselect(...parents);
        }
      }
      
      if (parents.length > 0) {
        if (this.checklistSelection.isSelected(node)) {
          this.checklistSelection.select(...parents)
        }
      }
    }
    
  }

  getParentsNode(node: TodoItemFlatNode, parents: TodoItemFlatNode[]) {
    const parent = this.getParentNode(node);
    if (parent) {
      parents.push(parent);
      this.getParentsNode(parent, parents);
    }
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: TodoItemFlatNode): void {
    let parent: TodoItemFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: TodoItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every(child => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.findIndex((dataNode) => 
      dataNode.expandable === node.expandable && dataNode.item === node.item && dataNode.level === node.level) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TodoItemNode`.
   */
  buildFileTree(value: any, level: number) {
    let data: any[] = []
    for (let k in value) {
      let v = value[k]
      let node = new TodoItemNode()
      node.item = `${k}`
      if (v === null || v === undefined) {
        // no action
      } else if (typeof v === 'object') {
        node.children = this.buildFileTree(v, level + 1)
      } else {
        node.item = v
      }
      data.push(node)
    }
    return data
  }
}
