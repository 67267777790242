import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CycleUpdateRequest } from 'src/api/client/data-contracts';
import { Service } from 'src/api/service/Service';
import { StorageService } from 'src/api/service/storage.service';
import { GrantsService } from '../services/grants.service';
import { SchoolCycleManagerComponent } from './school-cycle-manager/school-cycle-manager.component';
import { StatusChangeConfirmationModalComponent } from './status-change-confirmation-modal/status-change-confirmation-modal.component';

@Component({
  selector: 'app-school-cycles',
  templateUrl: './school-cycles.component.html',
  styleUrls: ['./school-cycles.component.scss']
})
export class SchoolCyclesComponent implements OnInit {

  position = 'top-center';
  cycles: CycleUpdateRequest[] = [];

  constructor(
    private storageService: StorageService,
    private dialog: MatDialog,
    public grantsService: GrantsService,
    ) { }

  ngOnInit(): void {
    this.getCycles();
  }

  getCycles() {
    const service = new Service(this.storageService);
    service.getAllCycles().then(cycles => {
      this.cycles = cycles;
    }).catch(err => {
      console.log("Err:", err);
    });
  }

  createCycle() {
    this.dialog.open(SchoolCycleManagerComponent, {
      height: '80%',
      width: '80%'
    }).afterClosed().subscribe(updated => {
      updated && this.getCycles();
    });
  }

  updateCycle(cycle: CycleUpdateRequest) {
    this.dialog.open(SchoolCycleManagerComponent, {
      data: cycle,
      height: '80%',
      width: '80%'
    }).afterClosed().subscribe(updated => {
      updated && this.getCycles();
    });
  }

  changeCycleStatus(cycle: CycleUpdateRequest, action: string) {
    this.dialog.open(StatusChangeConfirmationModalComponent, {
      data: { cycle, statusChangeRequest: action },
      height: '35%',
      width: '35%',
    }).afterClosed().subscribe(changeAccepted => {
      changeAccepted && this.getCycles();
    });
  }

}