<div class="d-flex flex-column h-100">
    <div class="app-modal-header mb-2 d-flex flex-row justify-content-between align-items-center">
        <div>
            <h5 class="modal-title">
                {{title}}
            </h5>
        </div>
        <div>
            <button type="button" class="close basic-close" style="top: -5%;" data-dismiss="modal" aria-label="Close"
                (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="app-modal-body">
        <div class="col-sm-12" *ngIf="form">
            <form [formGroup]="form">
                <div class="row">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-label">Nombre</label>
                                <input type="text" class="form-control" formControlName="name"
                                    placeholder="Ingresar nombres" required
                                    [ngClass]="{'is-invalid': (form.get('name').touched && form.get('name').hasError('required')) || (form.get('name').touched && !form.get('name').valid)}">
                                <label
                                    *ngIf="(form.get('name').touched && form.get('name').hasError('required')) || (form.get('name').touched && !form.get('name').valid)"
                                    class="error jquery-validation-error small form-text invalid-feedback">
                                    Ingrese un nombre v&aacute;lido.
                                </label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-control-label mb-3" for="enabled">Estado</label>
                                <br>
                                <ng-container [ngSwitch]="selectedCycle?.enabled">
                                    <div *ngSwitchCase="true" class="d-flex justify-content-center">
                                        <div>
                                            <h6 class="active">Activo</h6>
                                            <div class="active-circle m-0 ml-2"></div>
                                        </div>
                                    </div>
                                    <div *ngSwitchDefault class="d-flex justify-content-center">
                                        <div>
                                            <h6 class="inactive">Inactivo</h6>
                                            <div class="inactive-circle m-0 ml-2"></div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-label">Inicio de Ciclo</label>
                                <div class="input-group">
                                    <input class="form-control" formControlName="start_at" placeholder="dd / mm / aaaa"
                                        [minDate]="minStartDate" ngbDatepicker #d="ngbDatepicker" required
                                        [ngClass]="{'is-invalid': (form.get('start_at').touched && form.get('start_at').hasError('required')) || (form.get('start_at').touched && !form.get('start_at').valid)}"
                                        [disabled]="selectedCycle?.enabled">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button" [disabled]="selectedCycle?.enabled">
                                            <i class="fas fa-calendar f-14"></i>
                                        </button>
                                    </div>
                                </div>
                                <label id="validation-start_at-error"
                                    *ngIf="(form.get('start_at').touched && form.get('start_at').hasError('required')) || (form.get('start_at').touched && !form.get('start_at').valid)"
                                    class="error jquery-validation-error small form-text invalid-feedback">
                                    Ingrese una fecha de inicio válida.
                                </label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-label">Fin de Ciclo</label>
                                <div class="input-group">
                                    <input class="form-control" formControlName="end_at" placeholder="dd / mm / aaaa"
                                        [minDate]="minEndDate" ngbDatepicker #d2="ngbDatepicker" required
                                        [ngClass]="{'is-invalid': (form.get('end_at').touched && form.get('end_at').hasError('required')) || (form.get('end_at').touched && !form.get('end_at').valid)}"
                                        [disabled]="selectedCycle?.enabled">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button" [disabled]="selectedCycle?.enabled">
                                            <i class="fas fa-calendar f-14"></i>
                                        </button>
                                    </div>
                                </div>
                                <label id="validation-start_at-error"
                                    *ngIf="(form.get('end_at').touched && form.get('end_at').hasError('required')) || (form.get('end_at').touched && !form.get('end_at').valid)"
                                    class="error jquery-validation-error small form-text invalid-feedback">
                                    Ingrese una fecha de inicio válida.
                                </label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-label">Nota mínima</label>
                                <input type="number" class="form-control" formControlName="minimum_score" [min]="0" [max]="101"
                                    placeholder="Nota mínima" required
                                    [ngClass]="{'is-invalid': (form.get('minimum_score').touched && form.get('minimum_score').hasError('required')) || (form.get('minimum_score').touched && !form.get('minimum_score').valid)}">
                                <label
                                    *ngIf="(form.get('minimum_score').touched && form.get('minimum_score').hasError('required')) || (form.get('minimum_score').touched && !form.get('minimum_score').valid)"
                                    class="error jquery-validation-error small form-text invalid-feedback">
                                    La nota debe ser entre 0 - 100
                                </label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-control-label" for="cycle_type">Tipo de Ciclo</label>
                                <select class="form-control" name="cycle_type" required formControlName="cycle_type"
                                    [ngClass]="{'is-invalid': form.get('cycle_type').touched && form.get('cycle_type').hasError('required') && !form.get('cycle_type').valid}">
                                    <option value="ORDINARY">Ordinario</option>
                                    <option value="EXTRAORDINARY">Extraordinario</option>
                                </select>
                                <label
                                    *ngIf="form.get('cycle_type').touched && form.get('cycle_type').hasError('required') && !form.get('cycle_type').valid"
                                    class="error jquery-validation-error small form-text invalid-feedback">
                                    Seleccione un tipo de ciclo
                                </label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-control-label" for="score_handling">Operador de Calificaciones</label>
                                <select class="form-control" name="score_handling" required formControlName="score_handling"
                                    [ngClass]="{'is-invalid': form.get('score_handling').touched && form.get('score_handling').hasError('required') && !form.get('score_handling').valid}">
                                    <option value="AVG">Promediar</option>
                                    <option value="SUM">Sumar</option>
                                </select>
                                <label
                                    *ngIf="form.get('score_handling').touched && form.get('score_handling').hasError('required') && !form.get('score_handling').valid"
                                    class="error jquery-validation-error small form-text invalid-feedback">
                                    Debe seleccionar un operador.
                                </label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-control-label" for="branch">Sucursales</label>
                                <select class="form-control" name="branch" formControlName="branch"
                                    [ngClass]="{'is-invalid': form.get('branch').touched && form.get('branch').hasError('required') && !form.get('branch').valid}">
                                    <option *ngFor="let branch of allBranchs" [value]="branch.id">{{ branch?.branch?.name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div *ngIf="(form.get('start_at') && form.get('end_at').value)" class="form-group">
                                <label class="form-label">Unidades {{form.get('units_amount').value}}</label>
                                <div *ngIf="!selectedCycle?.enabled" class="input-group">
                                    <input type="range" class="form-control" formControlName="units_amount" min="1"
                                        max="12" required>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="(form.get('start_at') && form.get('end_at').value)">
                    <div formArrayName="units" class="col-md-5 pl-0 pr-0">
                        <ng-container *ngFor="let unit of form.get('units')['controls']; let i = index;">
                            <div [formGroupName]="i" class="d-flex flex-row mb-2">
                                <div class="d-flex flex-column align-items-center mr-3">
                                    <span>
                                        Unidad
                                    </span>
                                    <span>
                                        {{ i + 1 }}
                                    </span>
                                </div>
                                <div class="input-group">
                                    <input class="form-control" formControlName="end_date" required
                                        placeholder="Fecha en que termina" [minDate]="getUnitMinDate(i)"
                                        [maxDate]="form.get('end_at').value" ngbDatepicker #d3="ngbDatepicker"
                                        [ngClass]="{'is-invalid': (getUnitOption(i).get('end_date').touched && getUnitOption(i).get('end_date').hasError('required')) || (getUnitOption(i).get('end_date').touched && !getUnitOption(i).get('end_date').valid)}"
                                        [disabled]="selectedCycle?.enabled">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary" (click)="d3.toggle()" type="button" [disabled]="selectedCycle?.enabled">
                                            <i class="fas fa-calendar f-14"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="app-modal-footer mt-auto" *ngIf="form">
        <div *ngIf="loading" class="card-body d-flex flex-row justify-content-center mt-5">
            <div class="spinner-border text-info" role="status">
                <span class="sr-only"></span>
            </div>
        </div>
        <div *ngIf="!loading" class="d-flex flex-row justify-content-end">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal()">
                Cancelar
            </button>
            <button *ngIf="grantsService.validateByNameAndType('ABRIR_CICLO', 'ACTION')" type="button" class="btn btn-primary" (click)="submitCycleAndActivate()" [disabled]="!form.valid">
                {{ saveAndActivateButtonText }}
            </button>
            <button type="button" class="btn btn-primary" (click)="submitCycle()" [disabled]="!form.valid">
                {{ saveButtonText }}
            </button>
        </div>
    </div>
</div>