import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GrantsService } from 'src/app/services/grants.service';

@Component({
  selector: 'app-card-resource-form',
  templateUrl: './card-resource-form.component.html',
  styleUrls: ['./card-resource-form.component.scss']
})
export class CardResourceFormComponent implements OnInit {

  @Input() name: string;
  @Input() description: string;
  @Input() date: string;
  @Input() icon: string;
  @Output() spaceCardClick: EventEmitter<void> = new EventEmitter();
  @Output() spaceCardDelete: EventEmitter<void> = new EventEmitter();
  @Output() spaceCardPreview: EventEmitter<void> = new EventEmitter();

  constructor(
    public grantsService: GrantsService,
  ) { }

  ngOnInit(): void { }

  onClick() {
    this.spaceCardClick.emit();
  }

  showPreview() {
    this.spaceCardPreview.emit();
  }
  download() {
    this.spaceCardPreview.emit();
  }

  onDelete() {
    this.spaceCardDelete.emit();
  }

}
