<div class="app-modal-header">
    <h5 class="modal-title">Manejar Perfil
        <button type="button" class="close basic-close" style="top: -5%;" data-dismiss="modal" aria-label="Close"
            (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </h5>
</div>
<div class="app-modal-body d-flex flex-column pl-5 pb-2 pr-5 pt-1" style="max-height: 95%;">
    <form [formGroup]="form">
        <div class="d-flex flex-row mb-3">
            <div class="d-flex flex-column w-50 align-items-center">
                <img [src]="profilePicture ? profilePicture.href : '/assets/images/user/avatar-2.jpg'" width="200"
                    height="200" onerror="this.onerror=null; this.src='/assets/images/user/avatar-2.jpg'"
                    class="rounded-circle mb-3">
                <div class="d-flex flex-row justify-content-center">
                    <input hidden type="file" #uploader (change)="changeProfilePicture($event)" [multiple]="false" />
                    <button type="button" class="btn btn-sm btn-glow-light btn-light" (click)="uploader.click()"><i
                            class="feather icon-paperclip"></i>Cambiar imagen de perfil</button>
                </div>
                <span class="font-weight-bold mt-3">
                    Correo electr&oacute;nico:
                </span>
                <span>
                    {{loggedContact?.email}}
                </span>
            </div>
            <div class="d-flex flex-column w-50">
                <span class="form-title font-weight-bold">
                    Informaci&oacute;n Personal
                </span>
                <div class="form-group mt-3">
                    <label class="form-label">
                        Nombres:
                    </label>
                    <input type="text" class="form-control" formControlName="firstNames" required
                        [ngClass]="{'is-invalid': (form.get('firstNames').touched && form.get('firstNames').hasError('required')) || (form.get('firstNames').touched && !form.get('firstNames').valid)}">
                    <label
                        *ngIf="(form.get('firstNames').touched && form.get('firstNames').hasError('required')) || (form.get('firstNames').touched && !form.get('firstNames').valid)"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        Este campo no puede quedar vac&iacute;o.
                    </label>
                </div>
                <div class="form-group">
                    <label>
                        Apellidos:
                    </label>
                    <input type="text" class="form-control" formControlName="lastNames" required
                        [ngClass]="{'is-invalid': (form.get('lastNames').touched && form.get('lastNames').hasError('required')) || (form.get('lastNames').touched && !form.get('lastNames').valid)}">
                    <label
                        *ngIf="(form.get('lastNames').touched && form.get('lastNames').hasError('required')) || (form.get('lastNames').touched && !form.get('lastNames').valid)"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        Este campo no puede quedar vac&iacute;o.
                    </label>
                </div>
            </div>
        </div>
        <div class="mr-0">
            <div class="d-flex flex-row justify-content-between">
                <div class="w-50 mr-2">
                    <div class="form-group">
                        <label class="form-control-label" for="birthDate">Fecha de Nacimiento</label>
                        <div class="input-group">
                            <input type="date" class="form-control" formControlName="birthDate">
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="form-control-label" for="gender">Sexo</label>
                        <br>
                        <div class="form-check d-inline mr-5"
                            [ngClass]="{'err-color': form.get('gender').touched && !form.get('gender').valid}">
                            <input type="radio" [value]="'FEMALE'" name="gender" id="gender-1" formControlName="gender"
                                class="form-check-input d-inline" required>
                            <label class="form-check-label" for="gender-1">Femenino</label>
                        </div>
                        <div class="form-check d-inline"
                            [ngClass]="{'err-color': form.get('gender').touched && !form.get('gender').valid}">
                            <input type="radio" [value]="'MALE'" name="gender" id="gender-2" formControlName="gender"
                                class="form-check-input d-inline" required>
                            <label class="form-check-label" for="gender-2">Masculino</label>
                        </div>
                        <br>
                        <label *ngIf="form.get('gender').touched && !form.get('gender').valid" class="err-color">
                            Debe seleccionar el sexo de contacto.
                        </label>
                    </div>
                    <span class="form-title font-weight-bold">
                        ¿Deseas cambiar tu contraseña?
                    </span>
                    <div class="form-group">
                        <label>
                            Nueva contraseña:
                        </label>
                        <div class="input-group">
                            <input type="text" class="form-control" [type]="newPassType" formControlName="newPass">
                            <div class="input-group-append">
                                <button type="button" class="pl-1 btn btn-outline-secondary" style="max-width: 1rem;"
                                    (click)="toggleVisibility()">
                                    <i *ngIf="!newPassVisible" class="fa fa-eye" aria-hidden="true"
                                        data-toggle="tooltip" data-placement="top" title="Ver contraseña"></i>
                                    <i *ngIf="newPassVisible" class="fa fa-eye-slash" aria-hidden="true"
                                        data-toggle="tooltip" data-placement="top" title="Ocultar contraseña"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="w-50">
                    <div class="col-sm-12 ">
                        <div class="form-group w-50 mr-1">
                            <label class="form-control-label" for="phoneType">Tipo Tel&eacute;fono</label>
                            <select class="form-control" name="phoneType" formControlName="phoneType">
                                <option value="CELLPHONE">Celular</option>
                                <option value="HOME">Casa</option>
                                <option value="WORK">Trabajo</option>
                                <option value="OTHER">Otro</option>
                            </select>
                        </div>
                        <div>
                            <label class="form-control-label" for="phoneNumber">Tel&eacute;fono</label>
                            <div class="d-flex">
                                <select
                                        style="width: 150px;"
                                        class="form-control mr-1" id="phone_code"
                                        formControlName="phone_code" required
                                        [ngClass]="{'is-invalid': form.get('phone_code').touched && form.get('phone_code').hasError('required')}">
                                    <option *ngFor="let country of countries" [value]="country.code">
                                        {{country.countryCode}} {{country.code}}</option>
                                </select>
                                <input
                                       style="width: 200px;"
                                        type="text" class="form-control" placeholder="00000000"
                                       id="phoneNumber" name="phoneNumber" formControlName="phoneNumber"
                                       maxlength="8"
                                       [ngClass]="{'is-invalid': form.get('phoneNumber').touched && form.get('phoneNumber').hasError('required')}">
                                <button
                                        type="button"
                                        class="btn btn-sm btn-glow-success btn-success"
                                        style="margin-left: 15px; margin-top: 5px;"
                                        (click)="addPhone()">
                                    Agregar
                                </button>
                            </div>
                            <label
                                    *ngIf="form.get('phoneNumber').touched && form.get('phoneNumber').hasError('required')"
                                    id="validation-phone-error"
                                    class="error jquery-validation-error small form-text invalid-feedback">
                                Ingrese un tel&eacute;fono.
                            </label>
                            <label
                                    *ngIf="form.get('phoneNumber').touched && form.get('phoneNumber').hasError('pattern')"
                                    id="validation-phone-number-error"
                                    class="error jquery-validation-error small form-text invalid-feedback">
                                ingresar un numero valido.
                            </label>

                        </div>

                    </div>
                    <div class="col-sm-12 ml-3 d-flex flex-column phones-box pt-2">
                        <div *ngIf="userPhones?.length === 0" class="align-self-center" style="margin-top: 2.4rem;">
                            <span>
                                No tiene números de tel&eacute;fonos asignados
                            </span>
                        </div>
                        <div *ngFor="let phone of userPhones"
                            class="w-100 d-flex justify-content-between align-items-center">
                            <h6 *ngIf="phone.type.toUpperCase() === 'CELLPHONE'" class="m-0">
                                Celuar
                            </h6>
                            <h6 *ngIf="phone.type.toUpperCase() === 'HOME'" class="m-0">
                                Casa
                            </h6>
                            <h6 *ngIf="phone.type.toUpperCase() === 'WORK'" class="m-0">
                                Trabajo
                            </h6>
                            <h6 *ngIf="phone.type.toUpperCase() === 'OTHER'" class="m-0">
                                Otro
                            </h6>
                            <h6 class="m-0">{{phone.number}}</h6>
                            <h6 class="m-0">
                                <button type="button" class="btn btn-icon btn-outline-danger btn-sm"
                                    (click)="deletePhone(phone)">
                                    <i class="feather icon-trash"></i>
                                </button>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center mt-5 mb-5">
                <button type="button" class="btn btn-sm btn-glow-success btn-success" (click)="updateUser()"
                    style="margin-right: 2rem;" [disabled]="!form.valid">
                    Actualizar
                </button>
                <button type="button" class="btn btn-sm btn-glow-warning btn-warning" (click)="closeModal(true)">
                    Cancelar
                </button>
            </div>
        </div>
    </form>
</div>
