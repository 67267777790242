import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {Address, BranchResponse, ContactResponse, Phone, SpaceRequest, SpaceRequestUpdate} from '../../../api/client/data-contracts';

import { ToastOptions, ToastyService } from 'ng2-toasty';
import { StorageService } from '../../../api/service/storage.service';
import { Service } from '../../../api/service/Service';
import { SpaceNodeFlat } from 'src/util/space';
import { ContactTypeEnum } from 'src/api/utils/Constants';

@Component({
    selector: 'app-form-space',
    templateUrl: './form-space.component.html',
    styleUrls: ['./form-space.component.scss']
})
export class FormSpaceComponent implements OnInit {

    @Input() parentNode: SpaceNodeFlat;
    @Input() parentId: string;
    @Output() spacesChanged: EventEmitter<SpaceNodeFlat> = new EventEmitter();
    @Output() close: EventEmitter<void> = new EventEmitter();

    position = 'top-center';
    phones: Array<Phone> = new Array<Phone>();
    address: Array<Address> = new Array<Address>();
    formInput: SpaceRequest & { branchAddress?:string, branchAdmins?:string, branchTelephones?:string, branchId?:string };
    avatarInput: File;
    form: any;
    id: string;
    needUser: String;
    showSecurity: boolean;
    loading: boolean = false;
    staffMembers: ContactResponse[] = [];

    public isSubmit: boolean;
    public isSubmitSecuity: boolean;
    public isUpdate: boolean;
    public branch?: BranchResponse;

    constructor(private storageService: StorageService, private toastyService: ToastyService) {
        this.isSubmit = false;
        this.showSecurity = false;
        this.isSubmitSecuity = false;
        this.needUser = 'NO';
    }

    ngOnInit() {
        this.initializeForm();
        if (!this.parentId) {
            const service = new Service(this.storageService);
            service.getAllUsersByRole('', null, `${ContactTypeEnum.STAFF},${ContactTypeEnum.OWNER}`).then((value: any) => {
                value.data?.forEach(value1 => {
                    this.staffMembers.push(value1);
                });
            });
        }
    }

    public validateContact: (MovingDirection) => boolean = (form: any) => {
        if (!form.valid) {
            this.isSubmit = true;
            return false;
        } else {
            return true;
        }
    }

    public validateSecurity: (MovingDirection) => boolean = (form: any) => {
        if (!form.valid && this.showSecurity) {
            this.isSubmitSecuity = true;
            return false;
        } else {
            return true;
        }
    }

    initializeForm() {
        this.formInput = {
            title: '',
            description: '',
            type: '',
            parent_space_id: null,
            branchAddress: '',
            branchAdmins: '',
            branchTelephones: '',
            branchId: '',
            branch: null
        };
    }

    saveContract(form: any) {
        this.loading = true;
        if (form.valid) {
            this.toastyService.clearAll();
            var toastOptions: ToastOptions = {
                title: "Espere",
                msg: "Guardando registro",
                timeout: 3000,
                theme: "default"
            };
            this.toastyService.wait(toastOptions);
            const service = new Service(this.storageService);
            //TODO: Analizar asignacion de calssroom a un grado existente
            // let cycleId = this.storageService.getCurrentSession().cycle.id;
            // this.formInput.assign_scholar_cycle = cycleId;
            if (this.isUpdate) {
                const space: SpaceRequestUpdate = {
                    title: this.formInput.title,
                    type: this.formInput.type,
                    description: this.formInput.description
                };
                if (this.formInput.type === 'INSTITUTION') {
                    space.branch = {
                        name: this.formInput.title,
                        address: this.formInput.branchAddress,
                        admins: [this.formInput.branchAdmins],
                        telephones: [this.formInput.branchTelephones],
                        id: this.formInput.branchId
                    };
                }
                service.updateSpace(this.id, space)
                    .then(() => {
                        // @ts-ignore
                        if(space.branch){
                            space.branch.admins = [{contact_id: space.branch.admins[0]}];
                        }
                        const updatedSpace: SpaceNodeFlat = {
                            name: this.formInput.title,
                            id: this.id,
                            type: this.formInput.type,
                            description: this.formInput.description,
                            parentId: this.formInput.parent_space_id,
                            action: 1,
                            avatar: this.formInput.avatar,
                            branch: space.branch,
                            parentNode: this.parentNode
                        };
                        updatedSpace.branch = this.branch;
                        if (this.avatarInput) {
                            service.uploadAvatarSpace(this.id, { 'file': this.avatarInput })
                                .then((value) => {
                                    updatedSpace.avatar = value.avatar.href;
                                    this.spacesChanged.emit(updatedSpace);
                                    this.close.emit();
                                    this.initializeForm();
                                    this.avatarInput = null;
                                });
                        } else {
                            this.spacesChanged.emit(updatedSpace);
                            this.close.emit();
                            this.initializeForm();
                            this.avatarInput = null;
                        }
                        this.loading = false;
                    }).catch((error) => {
                        this.toastyService.clearAll();
                        var toastOptions: ToastOptions = {
                            title: "Error",
                            msg: error,
                            timeout: 5000,
                            theme: "default"
                        };
                        this.toastyService.error(toastOptions);
                        this.loading = false;
                    });
            } else {
                const newSpaceToSend: SpaceRequest = {
                    title: this.formInput.title,
                    type: this.formInput.type,
                    description: this.formInput.description
                };
                if (this.parentId !== null && this.parentId !== '') {
                    newSpaceToSend.parent_space_id = this.parentId;
                    this.formInput.parent_space_id = this.parentId;
                }
                if(this.formInput.type === 'INSTITUTION') {
                    newSpaceToSend.branch = {
                        name: this.formInput.title,
                        address: this.formInput.branchAddress,
                        admins: [this.formInput.branchAdmins],
                        telephones: [this.formInput.branchTelephones],
                        id: this.formInput.branchId
                    };
                }
                service.createSpaceObs(newSpaceToSend)
                    .subscribe((space) => {
                        this.toastyService.clearAll();
                        const toastOptions: ToastOptions = {
                            title: 'Exito',
                            msg: 'Espacio creado exitosamente.',
                            timeout: 3000,
                            theme: 'default'
                        };
                        this.toastyService.info(toastOptions);
                        const createdSpace: SpaceNodeFlat = {
                            name: this.formInput.title,
                            id: space,
                            type: this.formInput.type,
                            description: this.formInput.description,
                            parentId: this.formInput.parent_space_id,
                            action: 0,
                            parentNode: this.parentNode
                        };
                        if (!newSpaceToSend.branch){
                            createdSpace.branch = this.branch;
                        }
                        if (this.avatarInput) {
                            console.log('create avatar');
                            service.uploadAvatarSpace(space, { 'file': this.avatarInput })
                                .then((value) => {
                                    createdSpace.avatar = value.avatar.href;
                                    this.spacesChanged.emit(createdSpace);
                                    this.close.emit();
                                    this.initializeForm();
                                    this.avatarInput = null;
                                });
                        } else {
                            this.spacesChanged.emit(createdSpace);
                            this.close.emit();
                            this.initializeForm();
                            this.avatarInput = null;
                        }
                        this.loading = false;
                    }, (error) => {
                        this.toastyService.clearAll();
                        var toastOptions: ToastOptions = {
                            title: "Error",
                            msg: error,
                            timeout: 5000,
                            theme: "default"
                        };
                        this.toastyService.error(toastOptions);
                        this.loading = false;
                    });
            }
        } else {
            this.loading = false;
        }
    }

    fileChangeEvent(fileInput: any) {
        this.avatarInput = fileInput.target.files[0];
    }

    initialize(id: string, node: SpaceRequest) {
        if (node) {
            this.isUpdate = true;
            this.formInput = node;
            this.branch = node.branch;
            if (this.formInput.branch.address){
                this.formInput.branchAddress = this.formInput.branch.address;
            }
            console.info(this.formInput.branch.admins);
            this.formInput.branchAdmins = this.formInput.branch.admins[0]['contact_id'];
            if (this.formInput.branch.telephones){
                this.formInput.branchTelephones = this.formInput.branch.telephones[0];
            }
            if (this.formInput.branch.id){
                this.formInput.branchId = this.formInput.branch.id;
            }
            this.id = id;
        } else {
            this.isUpdate = false;
            this.initializeForm();
            this.id = '';
        }
    }


}
