<div class="app-modal-header">
    <h5 class="modal-title">
        {{ title }}
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </h5>
</div>
<div class="app-modal-body">
    <div *ngIf="!loading">
        <p>
            {{ subTitle }}
            <b>
                {{selectedContact.names}} {{selectedContact.last_names}}
            </b>
        </p>

        <div class="input-group search-bar-spacer">
            <input type="text" id="msg-friends" class="form-control" placeholder="Buscar"
                (keyup)="search($event.target.value)">
            <div class="input-group-append">
                <span class="input-group-text"><i class='feather icon-search'></i></span>
            </div>
        </div>

        <div style="height: 300px; overflow-y:auto; margin-top: 15px;">
            <div class="form-group" *ngFor="let contact of contacts">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="gridCheck"
                        [checked]="alreadyRelated.includes(contact.id)"
                        (change)="changeCheckbox(contact, $event.target.checked)">
                    <label class="form-check-label" for="gridCheck">{{ contact.names }} {{ contact.last_names }}</label>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="loading" class="card-body d-flex flex-row justify-content-center mt-5">
        <div class="spinner-border text-info" role="status">
            <span class="sr-only"></span>
        </div>
    </div>
</div>
<div *ngIf="!loading" class="app-modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="close()">Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="associate()">Asociar</button>
</div>