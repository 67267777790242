<aw-wizard #wizard class="arc-wizard" navBarLayout="large-empty-symbols">
    <aw-wizard-step stepTitle="General" [navigationSymbol]="{ symbol: '&#xe8e9;', fontFamily: 'feather' }">
        <div class="row" *ngIf="!loading">
            <form #validationForm="ngForm" class="editForm" novalidate>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">Título</label>
                            <input type="text" class="form-control" id="names" name="names"
                                   placeholder="Ingresar Título" #names="ngModel" [(ngModel)]="formInput.title"
                                   [ngClass]="{'is-invalid': (!names.valid && names.touched) && (names.dirty || names.touched || isSubmit)}"
                                   required>
                            <label *ngIf="!names.valid && (names.dirty || names.touched || isSubmit)"
                                   id="validation-names-error"
                                   class="error jquery-validation-error small form-text invalid-feedback">Ingrese un
                                t&iacute;tulo valido.</label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">Descripcion</label>
                            <input type="text" class="form-control" name="lastNames" id="lastNames" #lastNames="ngModel"
                                   [(ngModel)]="formInput.description"
                                   [ngClass]="{'is-invalid': (!lastNames.valid && lastNames.touched) && (lastNames.dirty || lastNames.touched || isSubmit)}"
                                   placeholder="Ingresar Descripción" required>
                            <label *ngIf="!lastNames.valid && (lastNames.dirty || lastNames.touched || isSubmit)"
                                   id="validation-apellidos-error"
                                   class="error jquery-validation-error small form-text invalid-feedback">Ingrese una descripci&oacute;n valida.</label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-control-label" for="type">Tipo Espacio</label>
                            <select class="form-control" id="type" name="type" required 
                                    #contactType="ngModel"   [ngClass]="{'is-invalid': (!contactType.valid && contactType.touched) && (contactType.dirty || contactType.touched || isSubmit)}" [(ngModel)]="formInput.type" >
                                <option value='' selected>Selecciona un tipo</option>
                                <option *ngIf="!parentId || formInput.type=='INSTITUTION'" value="INSTITUTION">Sucursal</option>
                                <option *ngIf="parentId" value="CLASSROOM">Clase</option>
                                <option *ngIf="parentId" value="CAREER">Carrera</option>
                                <option *ngIf="parentId" value="GRADE">Grado</option>
                                <option *ngIf="parentId" value="CATEGORY">Categoría</option>
                                <option *ngIf="parentId" value="SECTION">Secci&oacute;n</option>
                            </select>
                            <label *ngIf="!contactType.valid && (contactType.dirty || contactType.touched || isSubmit)"
                                   id="validation-contactType-error"
                                   class="error jquery-validation-error small form-text invalid-feedback">Debe seleccionar el tipo de espacio.</label>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="form-label">Avatar</label>
                            <input type="file" class="form-control" id="file" name="file"
                                   #avatar="ngModel" [(ngModel)]="avatarInput" (change)="fileChangeEvent($event)">
                        </div>
                    </div>
                    <ng-container *ngIf="formInput.type === 'INSTITUTION'" >
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-label">Direcci&oacute;n</label>
                                <input type="text" class="form-control" id="branchAddress" name="branchAddress"
                                       placeholder="Ingresar Direcci&oacute;n" #branchAddress="ngModel" [(ngModel)]="formInput.branchAddress"
                                       [ngClass]="{'is-invalid': (!branchAddress.valid && branchAddress.touched) && (branchAddress.dirty || branchAddress.touched || isSubmit)}"
                                       >
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-control-label" for="type">Administrador</label>
                                <select class="form-control" id="branchAdmins" name="branchAdmins" required 
                                        #branchAdmins="ngModel"   [ngClass]="{'is-invalid': (!branchAdmins.valid && branchAdmins.touched) 
                                        && (branchAdmins.dirty || branchAdmins.touched || isSubmit)}" [(ngModel)]="formInput.branchAdmins" >
                                    <option value='' selected>Selecciona un tipo</option>
                                    <option *ngFor="let staff of staffMembers" [value]="staff.id" >{{staff.names}} {{staff.last_names}}</option>
                                    
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-label">Tel&eacute;fono</label>
                                <input type="text" maxlength="8" class="form-control" id="branchTelephones" name="branchTelephones"
                                       placeholder="Ingresar Tel&eacute;fono" #branchTelephones="ngModel" [(ngModel)]="formInput.branchTelephones"
                                       [ngClass]="{'is-invalid': (!branchTelephones.valid && branchTelephones.touched) && (branchTelephones.dirty || 
                                       branchTelephones.touched || isSubmit)}"
                                >
                            </div>
                        </div>
                    </ng-container>
                </div>
            </form>
            <div class="col-sm-12 centered-content">
                <div class="btn-group mt-10">
                    <button type="button" class="btn btn-primary btn-sm"
                    [disabled]="!contactType.valid || !names.valid || !lastNames.valid" 
                    (click)="saveContract(validationForm)" >Guardar</button>
                </div>
                <p *ngIf="!contactType.valid || !names.valid || !lastNames.valid" style="margin-top: 5px;">Por favor, llena todas las casillas</p>
            </div>
        </div>
        <div *ngIf="loading" class="card-body d-flex flex-row justify-content-center mt-5">
            <div class="spinner-border text-info" role="status">
                <span class="sr-only"></span>
            </div>
        </div>
    </aw-wizard-step>
</aw-wizard>
