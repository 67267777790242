<div class="app-modal-header mb-1 border-bottom">
    <h5 class="modal-title mb-2">
        ¡No hay ciclo activo!
        <button type="button" class="close basic-close" style="top: -5%;" data-dismiss="modal" aria-label="Close"
            (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </h5>
</div><div class="app-modal-body">
    <div class="card-body d-flex flex-column align-items-center mt-4">
        <i class="fas fa-calendar-times fa-5x" style="color: #F44236;"></i>
        <div class="mt-5 text-center">
            <span>
                Sin ciclo activo no se pueden asignar o recibir tareas, ni actividades. Por favor activar un ciclo.
            </span>
        </div>
    </div>
</div>