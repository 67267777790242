<div class="app-modal-header">
    <h5 class="modal-title">{{ title }}
        <button type="button" class="close basic-close" style="top: -5%;" data-dismiss="modal" aria-label="Close"
            (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </h5>
</div>
<div class="app-modal-body mt-3">
    <div>
        <div [ngSwitch]="attachment.type">
            <div *ngSwitchCase="'IMAGE'" class="d-flex flex-column align-items-center justify-content-center">
                <div id="pdfApp" style="width: 100%; height: 73vh;"></div>
            </div>
            <div *ngSwitchCase="'PDF'" class="d-flex flex-column align-items-center justify-content-center">
                <div id="pdfApp" style="width: 100%; height: 73vh;"></div>
            </div>
            <div *ngSwitchCase="'VIDEO'">
                <div id="video-div"
                    class="angled-img pull-left d-flex flex-column align-items-center justify-content-center">
                    <video #video1 class="img" muted autoplay controls
                        [ngClass]="{'landscape': landscapeView, 'portrait': !landscapeView}">
                        <source [src]="attachment.href" type='video/mp4' />
                    </video>
                </div>
            </div>
            <div *ngSwitchCase="'AUDIO'" class="d-flex flex-column align-items-center justify-content-center" style="margin-top: 20%;">
                <audio controls>
                    <source type="audio/mpeg" [src]="attachment.href">
                </audio>
            </div>
            <div *ngSwitchCase="'M4A'" class="d-flex flex-column align-items-center justify-content-center" style="margin-top: 20%;">
                <audio controls>
                    <source type="audio/mpeg" [src]="attachment.href">
                </audio>
            </div>
            <div *ngSwitchCase="'LINK'" class="d-flex flex-column align-items-center justify-content-center">
                <p>
                    Link: {{ attachment.href }}
                </p>
                <iframe style="width: 100%; height: 73vh;" [src]="attachment.href" title="Link preview"></iframe>
            </div>
            <div *ngSwitchDefault class="d-flex flex-column align-items-center justify-content-center">
                <div id="pdfApp" style="width: 100%; height: 73vh;"></div>
            </div>
        </div>
    </div>
</div>