import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScreenLoadingComponent } from './screenLoading.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatIconModule} from '@angular/material/icon';
@NgModule({
    declarations: [ScreenLoadingComponent],
    exports: [
        ScreenLoadingComponent
    ],
    imports: [
        CommonModule,
        NgbModule,
        MatIconModule,
    ]
})
export class ScreenLoadingModule { }
