import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastOptions, ToastyService } from 'ng2-toasty';
import { ScoreLoadDeleteResquest } from 'src/api/client/data-contracts';
import { Service } from 'src/api/service/Service';
import { StorageService } from 'src/api/service/storage.service';

@Component({
  selector: 'app-delete-score-modal',
  templateUrl: './delete-score-modal.component.html',
  styleUrls: ['./delete-score-modal.component.scss']
})
export class DeleteScoreModalComponent implements OnInit {

  form: FormGroup;
  loading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public disableData: {
      spaceId?: string,
      scoreId?: string,
    },
    private dialogRef: MatDialogRef<DeleteScoreModalComponent>,
    private storageService: StorageService,
    private toastyService: ToastyService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      reason_code: ['', [Validators.required]],
      reason: ['', [Validators.required]],
    });
  }

  deleteScore() {
    this.loading = true;
    this.toastyService.clearAll();
    var toastOptions: ToastOptions = {
      title: "Espere",
      msg: "Borrando notas",
      timeout: 5000,
      theme: "default"
    };
    this.toastyService.wait(toastOptions);
    const service = new Service(this.storageService);
    const disableRequest: ScoreLoadDeleteResquest = {
      ...this.form.value,
    }
    service.deleteScore(this.disableData.spaceId, this.disableData.scoreId, disableRequest).then(() => {
      this.toastyService.clearAll();
      var toastOptions: ToastOptions = {
        title: "Exito",
        msg: "Notas eliminadas exitosamente.",
        timeout: 3000,
        theme: "default"
      };
      this.toastyService.info(toastOptions);
      this.loading = false;
      this.closeModal(true);
    }).catch(reason => {
      this.toastyService.clearAll();
      var toastOptions: ToastOptions = {
        title: "Error",
        msg: reason.message,
        timeout: 5000,
        theme: "default"
      };
      this.toastyService.error(toastOptions);
      this.loading = false;
    });
  }

  closeModal(deleted: boolean = false) {
    this.dialogRef.close(deleted);
  }

}
