<div class="d-flex flex-column h-100">
    <div class="app-modal-header mb-2 d-flex flex-row justify-content-between align-items-center">
        <div>
            <table>
                <tr>
                    <h5 class="modal-title">
                        {{title}}
                    </h5>
                </tr>
                <tr>
                    <label class="modal-title">
                        {{subTitle}}
                    </label>
                </tr>
                <tr>
                    <label class="modal-title">
                        {{scoreHandling}}
                    </label>
                </tr>
            </table>
        </div>
        <div>
            <button type="button" class="close basic-close" style="top: -5%;" data-dismiss="modal" aria-label="Close"
                (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="app-modal-body">
        <form *ngIf="incomingData.selectUnit" [formGroup]="form">
            <div class="col-md-6">
                <div class="form-group">
                    <label class="form-control-label" for="unit">Unidad</label>
                    <select class="form-control" name="unit" formControlName="unit" required
                        [ngClass]="{'is-invalid': form.get('unit').touched && form.get('unit').hasError('required')}">
                        <option *ngFor="let unit of cycleUnits" [value]="unit.id">
                            Unidad {{ unit.unit }}
                        </option>
                        <option value="total">
                            Nota total
                        </option>
                    </select>
                    <label *ngIf="form.get('unit').touched && form.get('unit').hasError('required')"
                        id="validation-phone-type-error"
                        class="error jquery-validation-error small form-text invalid-feedback">
                        Seleccione una unidad
                    </label>
                </div>
            </div>
        </form>
        <div *ngIf="(form?.get('unit').value || !incomingData.selectUnit) && (studentScores && studentScores.length > 0)"
            class="col-md-12 pl-0 pr-0">
            <div class="table-responsive">
                <div class="d-flex flex-row justify-content-center mt-2 mb-2">
                    <h3>
                        {{ tabTitle }}
                    </h3>
                </div>
                <table class="table">
                    <thead>
                        <tr>
                            <th class="text-center col-1">Estudiante</th>
                            <ng-container  *ngFor="let class of classrooms.values()">
                                <th class="text-center col-1">{{class}}</th>
                            </ng-container>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let score of scoresMap.values()">
                            <th class="text-center align-middle" scope="row">
                                {{ score.contact.names || '' }} {{ score.contact.last_name|| '' }}
                            </th>
                            <ng-container  *ngFor="let keyClass of classrooms.keys()">
                                <th class="text-center col-1">{{score.scores.has(keyClass) ?
                                    ( score.scores.get(keyClass).total_score % 1 === 0 ? score.scores.get(keyClass).total_score :  score.scores.get(keyClass).total_score.toFixed(2))
                                    : '0' }}</th>
                            </ng-container>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div
            *ngIf="(form?.get('unit').value || !incomingData.selectUnit) && !(studentScores && studentScores.length > 0)">
            <div class="d-flex flex-row justify-content-center mt-5">
                <h3>
                    No hay notas
                </h3>
            </div>
        </div>
        <div *ngIf="studentScores.length > 0">
            <label class="modal-title">
                {{unitsScore}}
            </label>
        </div>
    </div>
</div>
