<div class="row">
  <div class="col-xl-12">
    <div class="row buttons">
      <div class="col">
        <div class="row">
          <div class="col text-right">
            <div class="to-do-button">
              <button class="btn btn-primary" title="Refrescar" (click)="load()">
                <i class="fas fa-sync-alt f-14 mr-0"></i>
              </button>
              <button class="btn btn-primary" (click)="modalCreateActivitie.show()" *ngIf="grantsService.validateByNameAndType('AGREGAR_ACTIVIDADES', 'ACTION')">
                Agregar Actividad <i class="fas fa-plus f-14 mr-0"></i>
              </button>
              <button class="btn btn-primary" (click)="modalCreateResource.show()" *ngIf="grantsService.validateByNameAndType('AGREGAR_RECURSOS', 'ACTION')">
                Cargar un Recurso <i class="fas fa-plus f-14 mr-0"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-ui-modal #modalCreateActivitie [dialogClass]="'modal-lg'">
      <div class="app-modal-header">
        <h5 class="modal-title">Crear Actividad</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
          (click)="modalCreateActivitie.hide()"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="app-modal-body">
        <div class="col-sm-12">
          <app-form-activities #modalFormActivitie id="modalFormActivitie"></app-form-activities>
        </div>
      </div>
    </app-ui-modal>
    <app-ui-modal #modalCreateResource [dialogClass]="'modal-lg'">
      <div class="app-modal-header">
        <h5 class="modal-title">Cargar un recurso</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
          (click)="modalCreateResource.hide()"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="app-modal-body">
        <div class="col-sm-12 ">
          <form-resources-component #modalFormResource id="modalFormResource"></form-resources-component>
        </div>
      </div>
    </app-ui-modal>
    <ngb-tabset>
      <ngb-tab title="Actividades">
        <ng-template ngbTabContent>
          <div class="d-flex flex-row justify-content-between">
            <div>
              <button type="button" (click)="doSearchActivities('NOW')"
                [ngClass]="todaySelected ? 'btn btn-glow-info btn-info' : 'btn btn-outline-info'">
                Hoy
              </button>
              <button type="button" (click)="doSearchActivities('WEEK')"
                [ngClass]="weekSelected ? 'btn btn-glow-warning btn-warning' : 'btn btn-outline-warning'">
                Semana
              </button>
              <button type="button" (click)="doSearchActivities('MONTH')"
                [ngClass]="monthSelected ? 'btn btn-glow-success btn-success' : 'btn btn-outline-success'">
                Mes
              </button>
              <button type="button" (click)="doSearchActivities('NAME')"
                [ngClass]="nameSelected ? 'btn btn-glow-secondary btn-secondary' : 'btn btn-outline-secondary'">
                Nombre
              </button>
              <button type="button" (click)="selectRange()"
              [ngClass]="rangeSelected ? 'btn btn-glow-secondary btn-secondary' : 'btn btn-outline-secondary'">
                Rango de fechas
              </button>
            </div>
            <div>
              <button type="button" class="btn btn-danger" (click)="doSearchActivities('')">
                Borrar filtros
              </button>
            </div>
          </div>
          <div class="d-flex flex-row mt-3 mb-4" *ngIf="nameSelected">
            <div class="col-md-12">
              <label class="form-control-label" for="name">Nombre</label>
              <div class="input-group">
                <input class="form-control" placeholder="Ingresa nombre" #name="ngModel"
                    name="name" id="name" [(ngModel)]="nameParam" (keyup)="searchByName()"
                    [ngClass]="{'is-invalid': !name.valid && (name.dirty || name.touched)}" required>
              </div>
            </div>
          </div>
          <div *ngIf="rangeSelected" class="d-flex flex-row mt-3 mb-4">
            <div class="col-md-5">
              <label class="form-control-label" for="from">Desde</label>
              <div class="input-group">
                <input class="form-control" placeholder="dd / mm / aaaa" #from="ngModel" name="from" id="from"
                  [(ngModel)]="fromDate" ngbDatepicker #d="ngbDatepicker"
                  [ngClass]="{'is-invalid': !from.valid && (from.dirty || from.touched)}" required>
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                    <i class="fas fa-calendar f-14"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <label class="form-control-label" for="to">Hasta</label>
              <div class="input-group">
                <input class="form-control" placeholder="dd / mm / aaaa" [minDate]="fromDate" #to="ngModel" name="to"
                  id="to" [(ngModel)]="toDate" ngbDatepicker #d2="ngbDatepicker"
                  [ngClass]="{'is-invalid': !to.valid && (to.dirty || to.touched)}" required>
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" (click)="d2.toggle()" type="button">
                    <i class="fas fa-calendar f-14"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="d-flex align-items-end justify-content-end">
              <button type="button" class="btn btn-primary mb-0" (click)="doSearchActivities('RANGE')">
                Buscar
              </button>
            </div>
          </div>
          <div class="row">
            <app-card-space-form *ngFor="let activity of activities" class="card resource-card"
              (spaceCardClick)="goToTask(activity.id)" (spaceCardDelete)="deleteActivityShow(activity.id)"
              [name]="activity.title" [description]="activity.description"
              [dates]="{ endDate: activity.end_at, endTime: activity.end_time }"
              [icon]="activity.type === 'TASK' ? 'file' : 'calendar'">
              {{activity.type}}
            </app-card-space-form>
          </div>
          <div class="row no-data" *ngIf="activities.length === 0">
            <ng-container>
              <div class="title">
                No existen Actividades para este curso, puedes crear una por medio del botón <b> "Agregar Actividad
                  +"</b>
              </div>
            </ng-container>
          </div>
        </ng-template>
      </ngb-tab>
      <ngb-tab title="Recursos">
        <ng-template ngbTabContent>
          <div class="row">
            <app-card-resource-form *ngFor="let resource of resources" class="card resource-card"
              (spaceCardClick)="openFilePreview(resource.link)"
              (spaceCardDelete)="deleteResourceShow(resource.id)"
              (spaceCardPreview)="downloadResource(resource.link)" [name]="resource.name"
              [description]="resource.description" [date]="resource.created_at" [icon]="'file'">
            </app-card-resource-form>
          </div>
          <div class="row no-data" *ngIf="resources.length === 0">
            <ng-container>
              <div class="title">
                No existen Recursos para este curso, puedes cargar uno por medio del botón <b> "Cargar un Recurso +"</b>
              </div>
            </ng-container>
          </div>
        </ng-template>
      </ngb-tab>
      <ngb-tab title="Calificaciones">
        <ng-template ngbTabContent>
          <div class="d-flex flex-row justify-content-end m-b-15">
            <button class="btn btn-primary" (click)="generateScore()">
              Subir Notas
            </button>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th class="text-center col-3">Unidad</th>
                  <th class="text-center col-3">Cantidad de Alumnos</th>
                  <th class="text-center col-3">Estado</th>
                  <th class="text-center col-3">Acci&oacute;n</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let score of scores" class="table-row">
                  <td class="pt-1 pb-1 pr-0 pl-0 text-center">
                    <h6 class="ml-0 mb-0 mr-0" style="margin-top: 0.8rem;" >{{ score?.unit?.unit || 'N/A' }}</h6>
                  </td>
                  <td class="pt-1 pb-1 pr-0 pl-0 text-center">
                    <h6 class="ml-0 mb-0 mr-0" style="margin-top: 0.8rem;" >{{ score?.score_quantity || 'N/A' }}</h6>
                  </td>
                  <td class="pt-1 pb-1 pr-0 pl-0 text-center">
                    <div *ngIf="score.status==='COMPLETED'">
                      <h6 style="margin-top: 0.8rem;" class="active">Procesada</h6>
                      <div class="active-circle m-0 ml-4"></div>
                    </div>
                    <div *ngIf="score.status!=='COMPLETED'">
                      <h6 style="margin-top: 0.8rem;" class="inactive">Fallida</h6>
                      <div class="inactive-circle m-0 ml-4"></div>
                    </div>
                  </td>
                  <td class="pt-1 pb-1 pr-0 pl-0 text-center">
                    <ng-template #scoresActions>
                      <span class="mt-2 dropdown-button dropdown-item btn-glow-warning btn-warning pointer pl-2 pr-2"
                            (click)="scorePreview(score)">
                        <i class="mr-1 fas fa-eye" [placement]="'top'" ngbTooltip="Ver" (click)="scorePreview(score)"></i>
                        Ver
                      </span>
                      <span class="mt-2 dropdown-button dropdown-item btn-glow-success btn-success pointer pl-2 pr-2"
                            (click)="downloadScores(score)">
                        <i class="mr-1 fas fa-download" [placement]="'top'" ngbTooltip="Descargar" (click)="downloadScores(score)"></i>
                        Descargar
                      </span>
                      <span class="mt-2 dropdown-button dropdown-item btn-glow-danger btn-danger pointer pl-2 pr-2"
                            (click)="deleteScore(score)">
                        <i class="mr-1 fas fa-times-circle" [placement]="'top'" ngbTooltip="Anular" (click)="deleteScore(score)"></i>
                        Anular
                      </span>
                    </ng-template>
                    <button type="button" class="btn drp-icon btn-outline-primary" [placement]="'bottom'"
                      [ngbPopover]="scoresActions">
                      <i class="fas fa-cog"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex justify-content-center">
              <h5 *ngIf="scores?.length === 0">No hay notas creadas.</h5>
            </div>
          </div>
        </ng-template>
      </ngb-tab>
      <ngb-tab title="Alumnos">
        <ng-template ngbTabContent>
          <div class="d-flex flex-row justify-content-end m-b-15">
            <button class="btn btn-primary" (click)="exportExcel()" *ngIf="grantsService.validateByNameAndType('AGREGAR_ACTIVIDADES', 'ACTION')">
              Exportar Excel <i class="fas fa-file-excel f-14 mr-0"></i>
            </button>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Nombres y Apellidos</th>
                  <th>Correo Electr&oacute;nico</th>
                  <th>Fecha Nacimiento</th>
                  <th>Sexo</th>
                  <th *ngIf="grantsService.validateByNameAndType('ASOCIAR_ESTUD', 'ACTION')">Acci&oacute;n</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let student of students | slice: (studentsPage-1) * 10 : studentsPage * 10"
                  class="table-row">
                  <td>
                    <h6 class="m-0">
                      <img class="rounded-circle m-r-10" style="width:40px; height: 40px;"
                        src="{{student.avatar.href ==null ? 'assets/images/user/avatar-1.jpg' : student.avatar.href }}"
                        alt="activity-user">
                      {{student.names}} {{student.last_names}}
                    </h6>
                  </td>
                  <td>
                    <h6 class="m-0">{{student.email}}</h6>
                  </td>
                  <td>
                    <h6 class="m-0">{{student.birth_date}}</h6>
                  </td>
                  <td>
                    <h6 *ngIf="student.gender?.toLowerCase()==='male'" class="m-0">Masculino</h6>
                    <h6 *ngIf="student.gender?.toLowerCase()==='female'" class="m-0">Femenino</h6>
                    <h6 *ngIf="student.gender?.toLowerCase()==='n/a'" class="m-0">N/A</h6>
                  </td>
                  <td class="pt-1 pb-1 pr-0 pl-0 text-center">
                    <ng-template #scoresActions>
                      <span class="mt-2 dropdown-button dropdown-item btn-glow-warning btn-warning pointer pl-2 pr-2"
                            (click)="showSpaceScores(student)">
                        <i class="mr-1 fas fa-eye" [placement]="'top'" ngbTooltip="Ver" (click)="scorePreview(score)"></i>
                        Ver notas
                      </span>
                      <span *ngIf="grantsService.validateByNameAndType('ASOCIAR_ESTUD', 'ACTION')" href="javascript:" class="mt-2 dropdown-button dropdown-item btn-glow-danger btn-danger pointer pl-2 pr-2"
                      (click)="selectDeleteStudent(student)">
                        Eliminar
                      </span>
                    </ng-template>
                    <button type="button" class="btn drp-icon btn-outline-primary" [placement]="'bottom'"
                      [ngbPopover]="scoresActions">
                      <i class="fas fa-cog"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="d-flex justify-content-center">
              <h5 *ngIf="students?.length === 0">No hay estudiantes asignados.</h5>
            </div>
            <div class="d-flex justify-content-center p-2 mb-3">
              <ngb-pagination *ngIf="students?.length > 0" [collectionSize]="students.length"
                [(page)]="studentsPage" [pageSize]="10">
              </ngb-pagination>
            </div>
          </div>
        </ng-template>
      </ngb-tab>
    </ngb-tabset>
    <app-ui-modal #modalDelete [dialogClass]="'modal-lg'">
      <div class="app-modal-header">
        <h5 class="modal-title">Eliminar Estudiante del Curso</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
          (click)="modalDelete.hide()"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="app-modal-body">
        <p>¿Está seguro de eliminar el estudiante '<b> {{selectedStudent.names}} {{selectedStudent.last_names}}</b> ',
          del curso?</p>
      </div>
      <div class="app-modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal"
          (click)="modalDelete.hide()">Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="deleteStudent()">Aceptar</button>
      </div>
    </app-ui-modal>
    <app-ui-modal #modalDeleteActivity [dialogClass]="'modal-lg'">
      <div class="app-modal-header">
        <h5 class="modal-title">Eliminar Actividad del Curso</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
          (click)="modalDeleteActivity.hide()"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="app-modal-body">
        <p>¿Está seguro de eliminar la Actividad?</p>
      </div>
      <div class="app-modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal"
          (click)="modalDeleteActivity.hide()">Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="deleteActivity()">Aceptar</button>
      </div>
    </app-ui-modal>
    <app-ui-modal #modalDeleteResource [dialogClass]="'modal-lg'">
      <div class="app-modal-header">
        <h5 class="modal-title">Eliminar Recurso del Curso</h5>
        <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
          (click)="modalDeleteResource.hide()"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="app-modal-body">
        <p>¿Está seguro de eliminar el Recurso?</p>
      </div>
      <div class="app-modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal"
          (click)="modalDeleteResource.hide()">Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="deleteResource()">Aceptar</button>
      </div>
    </app-ui-modal>
  </div>
</div>
<ng2-toasty [position]="position"></ng2-toasty>
<app-ui-modal #modalConfirmationDownload [dialogClass]="'modal-lg'">
  <div class="app-modal-header">
    <h5 class="modal-title">Este tipo de archivo no puede ser visualizado!</h5>
    <button type="button" class="close basic-close" data-dismiss="modal" aria-label="Close"
            (click)="modalConfirmationDownload.hide()"><span aria-hidden="true">&times;</span></button>
  </div>
  <div class="app-modal-body">
    <p>¿Desea descargar el archivo?</p>
  </div>
  <div class="app-modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal"
            (click)="modalConfirmationDownload.hide()">Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="downloadAttachment(attachment,true)">Aceptar</button>
  </div>
</app-ui-modal>
