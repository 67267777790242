import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventCalendarRoutingModule } from './event-calendar-routing.module';
import { EventCalendarComponent } from './event-calendar.component';
import { FormsModule } from '@angular/forms';
import { FullCalendarModule } from '@fullcalendar/angular';
import { SharedModule } from '../theme/shared/shared.module';

import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin
]);

@NgModule({
  imports: [
    CommonModule,
    EventCalendarRoutingModule,
    SharedModule,
    FullCalendarModule,
    FormsModule
  ],
  declarations: [
    EventCalendarComponent
  ],
  providers: [],
})
export class EventCalendarModule { }