import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import {StorageService} from "../../api/service/storage.service";
import {Service} from "../../api/service/Service";
import { ActivityShortResponse } from "../../api/client/data-contracts"

@Injectable({
  providedIn: 'root'
})
export class ActivityResolverService implements Resolve<ActivityShortResponse | any> {

  constructor(private storageService: StorageService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<ActivityShortResponse | any> {
    const id = route.queryParams['id'];
    const service = new Service(this.storageService);
    return service.getActivity(id, this.storageService.getCurrentUser().contact_id);
  }

}