import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastOptions, ToastyService } from 'ng2-toasty';
import { Service } from 'src/api/service/Service';
import { StorageService } from 'src/api/service/storage.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  position = 'top-center';
  form: FormGroup;

  constructor(
    private toastyService: ToastyService,
    private storageService: StorageService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      email: ['', [Validators.required]]
    });
  }

  sendEmail(){
    this.toastyService.clearAll();
    var toastOptions: ToastOptions = {
      title: "Espere",
      msg: "Procesando petición",
      timeout: 5000,
      theme: "default"
    };
    this.toastyService.wait(toastOptions);
    const service = new Service(this.storageService);
    service.sendEmailForPasswordReset({ ...this.form.value }).then(() => {
      this.toastyService.clearAll();
      var toastOptions: ToastOptions = {
        title: "Exito",
        msg: "Espere a recibir un correo con su nueva contraseña",
        timeout: 6000,
        theme: "default"
      };
      this.toastyService.info(toastOptions);
      this.form.reset();
    }).catch(reason => {
      this.toastyService.clearAll();
      var toastOptions: ToastOptions = {
        title: "Error",
        msg: reason.message,
        timeout: 5000,
        theme: "default"
      };
      this.toastyService.error(toastOptions);
    });
  }

}