import {Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef} from '@angular/core';
import {FormQuestion, FormAnswer, Choice} from '../../../api/models/question' ;
import { ToastOptions, ToastyService } from "ng2-toasty";
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-question-form',
  templateUrl: './questionForm.component.html',
  styleUrls: ['./questionForm.component.scss']
})
export class QuestionFormComponent implements OnInit {
  @Input() defaultData: FormQuestion;
  @Output() changeData: EventEmitter<boolean> = new EventEmitter();

  formInputQuestion: FormQuestion;
  formAnswer: FormAnswer;
  Answers: Choice[] = [];

  constructor( private toastyService: ToastyService, private changeDetectorRef: ChangeDetectorRef ) { }
  ngOnInit(): void {
    this.formInputQuestion = {
      id: this.defaultData.id,
      title: this.defaultData.title,
      score: this.defaultData.score,
      multipleChoice:this.defaultData.multipleChoice,
      choices: this.defaultData.choices ? this.defaultData.choices : []
    };

    this.defaultData?.choices.forEach((c, i: number) => {
      this.Answers.push({
        id: c.id,
        title: c.title,
        right: c.right
      });
    });

    this.formAnswer = {
      answer: '',
      isValid: false
    };
  }

  changeMultipleC(): void {
    this.changeData.emit(true);
  }

  onChange(newValue): void {
    this.changeDetectorRef.detectChanges();
    this.formInputQuestion.title = newValue;  // don't forget to update the model here
    // ... do other stuff here ...
  }


  addAnswer(): void{
    if (this.formAnswer.answer){
      this.Answers.push({
        id: uuidv4(),
        title: this.formAnswer.answer,
        right: this.formAnswer.isValid
      });
      this.formAnswer.answer = '';
      this.formAnswer.isValid = false;
      this.formInputQuestion.choices = this.Answers;
    }else{
      this.toastyService.clearAll();
      const toastOptions: ToastOptions = {
        title: "Datos incompletos",
        msg: "Agregar una respuesta valida!",
        timeout: 5000,
        theme: "default"
      };
      this.toastyService.error(toastOptions);
    }

  }

  removeAnswer(a: Choice): void{
    this.Answers.splice(this.Answers.findIndex(item => item.title === a.title), 1);
    this.formInputQuestion.choices = this.Answers;
  }

  getForm(): FormQuestion{
    this.changeDetectorRef.detectChanges();
    return this.formInputQuestion;
  }
}
