<div class="d-flex flex-column h-100">
    <div class="app-modal-header mb-2 d-flex flex-row justify-content-between align-items-center">
        <div>
            <h5 class="modal-title">
                ¿Seguro que desea eliminar estas notas?
            </h5>
        </div>
        <div>
            <button type="button" class="close basic-close" style="top: -5%;" data-dismiss="modal" aria-label="Close"
                (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="app-modal-body">
        <div *ngIf="!loading" class="d-flex flex-column mt-3">
            <form [formGroup]="form">
                <div class="col-md-12">
                    <div class="form-group">
                        <label class="form-control-label" for="reason_code">Seleccionar una Raz&oacute;n</label>
                        <select class="form-control" name="reason_code" formControlName="reason_code" required
                            [ngClass]="{'is-invalid': form.get('reason_code').touched && form.get('reason_code').hasError('required')}">
                            <option value="CORRECTION">Corrección</option>
                            <option value="ERROR_IN_DATA">Datos incorrectos</option>
                        </select>
                        <label *ngIf="form.get('reason_code').touched && form.get('reason_code').hasError('required')"
                            id="validation-phone-type-error"
                            class="error jquery-validation-error small form-text invalid-feedback">
                            Campo requerido.
                        </label>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <label class="form-control-label" for="reason">Descripci&oacute;n</label>
                        <textarea type="text" formControlName="reason" class="form-control"
                            placeholder="Ingrese una raz&oacute;n" required rows="3"
                            [ngClass]="{'is-invalid': form.get('reason').touched && form.get('reason').hasError('required')}"></textarea>
                        <label *ngIf="form.get('reason').touched && form.get('reason').hasError('required')"
                            id="validation-phone-error"
                            class="error jquery-validation-error small form-text invalid-feedback">
                            Ingrese una razón.
                        </label>
                    </div>
                </div>
                <div class="d-flex justify-content-center mt-3">
                    <button type="button" class="btn btn-sm btn-glow-danger btn-danger" [disabled]="!form.valid"
                        (click)="deleteScore()">
                        Eliminar
                    </button>
                </div>
            </form>
        </div>
        <div *ngIf="loading" class="card-body d-flex flex-row justify-content-center mt-5">
            <div class="spinner-border text-info" role="status">
                <span class="sr-only"></span>
            </div>
        </div>
    </div>
</div>