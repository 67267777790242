import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DatesService {

  months = [
    'Ene',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sep',
    'Oct',
    'Nov',
    'Dic',
  ];

  constructor() { }

  getTimeStringSinceCreated = (createdAt: string) => {
    const now = new Date();
    const dateCreated = new Date(createdAt);
    const msSincePublished = (now.getTime() - dateCreated.getTime()) / 1000;
    const diffMins = Math.abs(Math.floor(msSincePublished / 60));
    if (diffMins > 60) {
      if (diffMins > 1440) {
        const diffDays = Math.floor(diffMins / 1440);
        if (diffDays > 7) {
          const diffWeeks = Math.floor(diffDays / 7);
          if (diffWeeks > 3) {
            const timePassed = `${this.months[dateCreated.getMonth()]} ${dateCreated.getDate()}`;
            return timePassed;
          } else {
            const timePassed = diffWeeks > 1 ? `${diffWeeks} semanas` : `${diffWeeks} semana`;
            return timePassed;
          }
        } else {
          const timePassed = diffDays > 1 ? `${diffDays} días` : `${diffDays} día`;
          return timePassed;
        }
      } else {
        const diffHours = Math.floor(diffMins / 60);
        const timePassed = diffHours > 1 ? `${diffHours}hrs` : `${diffHours}hr`;
        return timePassed;
      }
    } else {
      const timePassed = diffMins > 1 ? `${diffMins}mins` : `${diffMins}min`;
      return timePassed;
    }
  }

  getDifferenceBetweenDates = (startDate: string, endDate: string) => {
    let datesDifferenceObj: {
      days: number,
      months?: number,
      years?: number,
    } = { days: 0 }
    const initialDate = new Date(startDate);
    const finalDate = new Date(endDate);
    const msSincePublished = (initialDate.getTime() - finalDate.getTime()) / 1000;
    const diffMins = Math.abs(Math.floor(msSincePublished / 60));
    if (diffMins > 1440) {
      const diffDays = Math.floor(diffMins / 1440);
      if (diffDays > 30) {
        const diffMonths = Math.floor(diffDays / 30);
        if (diffMonths > 12) {
          const diffYears = Math.floor(diffMonths / 12);
          datesDifferenceObj = {
            days: diffDays,
            months: diffMonths,
            years: diffYears,
          };
        } else {
          datesDifferenceObj = {
            days: diffDays,
            months: diffMonths,
          };
        }
      } else {
        datesDifferenceObj.days = diffDays;
      }
    } else {
      datesDifferenceObj.days = 0;
    }
    return datesDifferenceObj;
  }

  getDateAfterDayDifference(date: { day: number, month: number, year: number }, dayDifference: { days?: number, months?: number, years?: number }, type: 'up' | 'down' = 'up') {
    let diffDays = dayDifference.days;
    if (dayDifference.months) diffDays = diffDays + (dayDifference.months * 30);
    if (dayDifference.years) diffDays = diffDays + (dayDifference.years * 365);
    let formattedDate = new Date(date.year, date.month, date.day);
    switch (type) {
      case 'up':
        formattedDate.setDate(formattedDate.getDate() + diffDays);
        break;
      case 'down':
        formattedDate.setDate(formattedDate.getDate() - diffDays);
        break;
      default:
        break;
    }
    const newDate: { day: number, month: number, year: number } = {
      day: formattedDate.getDate(),
      month: formattedDate.getMonth() + 1,
      year: formattedDate.getFullYear(),
    }
    return newDate;
  }

}