import {Pipe, PipeTransform} from '@angular/core';
import {SelectItem} from '../../content/content.module';

@Pipe({
    name: 'enumToArray'
})
export class EnumToArrayPipe implements PipeTransform {
    transform(data: Object) {
        let values:SelectItem[] = Object.keys(data)
            .map(key => ({
                key: key, value: data[key]
            }));
        return values;
    }
}
