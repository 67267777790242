<div class="row align-items-center justify-content-center btn-div mb-3">
    <div class="col">
        <div class="row align-items-end justify-content-center">
            <div class="col text-right">

            </div>
        </div>
    </div>
</div>
<app-card [hidHeader]="true" [options]="false" cardClass="msg-card mb-0" blockClass="card-body msg-block">
    <div>
        <h4 class="modal-title titleSetting">
            Configuracion
        </h4>

        <div class="row">
            <div class="col-md-2">
                <article class="logoWraper">
                    <section>
                        <img src="{{ schoolForm.get('school_url_logo').value }}" alt="logo">
                    </section>
                    <section>
                        <input hidden type="file" #uploader (change)="changeSchoolAvatar($event)" [multiple]="false" />
                        <button type="button" class="btn btn-sm btn-glow-light btn-light btnAdd" (click)="uploader.click()"><i
                                class="feather icon-paperclip"></i>Cambiar logo</button>
                    </section>
                </article>
            </div>
            <div class="col-md-10">
                <article class="tabWrapper">
                    <nav>
                        <ul>
                            <li [ngClass]="this.tabActive ===  'school' && 'liActive'" (click)="setTab('school')">
                                ESCUELA
                            </li>
                            <li [ngClass]="this.tabActive === 'customer' && 'liActive'" (click)="setTab('customer')">
                                CLIENTE
                            </li>
                        </ul>
                    </nav>
                    <button
                            *ngIf="this.tabActive === 'customer'"
                            type="button"
                            uiSref="work"
                            class="btn btn-md btn-success btn-save"
                            (click)="updateSchool()"
                            [disabled]="!this.schoolForm.valid && !this.customerForm.valid">
                        <i class="feather icon-save"></i>
                        Guardar Cambios
                    </button>
                </article>
            </div>
        </div>

        <article class="tabBody">
            <section *ngIf="tabActive ===  'school'">
                <form [formGroup]="schoolForm">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="form-label">Nombre</label>
                                <input
                                        type="text"
                                        class="form-control"
                                        formControlName="school_name"
                                        placeholder="Nombre Escuela" required
                                        [ngClass]="{'is-invalid': (schoolForm.get('school_name').touched && schoolForm.get('school_name').hasError('required')) || (schoolForm.get('school_name').touched && !schoolForm.get('school_name').valid)}">
                                <label
                                        *ngIf="(schoolForm.get('school_name').touched && schoolForm.get('school_name').hasError('required')) || (schoolForm.get('school_name').touched && !schoolForm.get('school_name').valid)"
                                        class="error jquery-validation-error small form-text invalid-feedback">
                                    Ingrese un nombre v&aacute;lido.
                                </label>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <form [formGroup]="schoolFormPhones">
                                <article>
                                    <h6 class="modal-title titleSetting addresses">
                                        Telefonos
                                    </h6>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label" for="phone_type">Tipo Tel&eacute;fono</label>
                                                <select class="form-control" name="phone_type" formControlName="phone_type"
                                                        required
                                                        [ngClass]="{'is-invalid': schoolFormPhones.get('phone_type').touched && schoolFormPhones.get('phone_type').hasError('required')}">
                                                    <option value="CELLPHONE">Celular</option>
                                                    <option value="HOME">Casa</option>
                                                    <option value="WORK">Trabajo</option>
                                                    <option value="OTHER">Otro</option>
                                                </select>
                                                <label
                                                        *ngIf="schoolFormPhones.get('phone_type').touched && schoolFormPhones.get('phone_type').hasError('required')"
                                                        id="validation-phone-type-error"
                                                        class="error jquery-validation-error small form-text invalid-feedback">
                                                    Seleccione un tipo de tel&eacute;fono.
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label class="form-control-label" for="phone_number">Tel&eacute;fono</label>
                                                <div class="d-flex">
                                                    <select class="form-control mr-1" id="phone_code"
                                                            formControlName="phone_code" required
                                                            [ngClass]="{'is-invalid': schoolFormPhones.get('phone_code').touched && schoolFormPhones.get('phone_code').hasError('required')}">
                                                        <option *ngFor="let country of countries" [value]="country.code">
                                                            {{country.countryCode}} {{country.code}}</option>
                                                    </select>
                                                    <input type="text" class="form-control" placeholder="00000000"
                                                           id="phone_number" name="phone_number" formControlName="phone_number"
                                                           required maxlength="8"
                                                           [ngClass]="{'is-invalid': schoolFormPhones.get('phone_number').touched && schoolFormPhones.get('phone_number').hasError('required')}">
                                                </div>
                                                <label
                                                        *ngIf="schoolFormPhones.get('phone_number').touched && schoolFormPhones.get('phone_number').hasError('required')"
                                                        id="validation-phone-error"
                                                        class="error jquery-validation-error small form-text invalid-feedback">
                                                    Ingrese un tel&eacute;fono.
                                                </label>
                                                <label
                                                        *ngIf="schoolFormPhones.get('phone_number').touched && schoolFormPhones.get('phone_number').hasError('pattern')"
                                                        id="validation-phone-number-error"
                                                        class="error jquery-validation-error small form-text invalid-feedback">
                                                    ingresar un numero valido.
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <button type="button" uiSref="work" class="btn btn-sm btn-glow-success btn-success"
                                                    style="margin-top: 36px;" (click)="addPhone(this.schoolFormPhones.get('phone_type'),this.schoolFormPhones.get('phone_number'),this.schoolFormPhones.get('phone_code'), 'school')">
                                                Agregar
                                            </button>
                                        </div>
                                    </div>
                                </article>
                                <article>
                                    <h6 class="modal-title titleSetting addresses">
                                        Listado de Telefonos
                                    </h6>
                                    <div class="col-sm-12 centered-content">
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                <tr>
                                                    <th>Tipo</th>
                                                    <th>Tel&eacute;fono</th>
                                                    <th></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <ng-container *ngFor="let phone of phones">
                                                    <tr>
                                                        <td>
                                                            <h6 *ngIf="phone.type.toUpperCase() === 'CELLPHONE'" class="m-0">
                                                                Celuar</h6>
                                                            <h6 *ngIf="phone.type.toUpperCase() === 'HOME'" class="m-0">Casa
                                                            </h6>
                                                            <h6 *ngIf="phone.type.toUpperCase() === 'WORK'" class="m-0">Trabajo
                                                            </h6>
                                                            <h6 *ngIf="phone.type.toUpperCase() === 'OTHER'" class="m-0">Otro
                                                            </h6>
                                                        </td>
                                                        <td>
                                                            <h6 class="m-0">{{phone.number}}</h6>
                                                        </td>
                                                        <td>
                                                            <h6 class="m-0">
                                                                <button type="button" class="btn btn-icon btn-outline-danger"
                                                                        ngbTooltip="Eliminar" (click)="deletePhone(phone, 'school')">
                                                                    <i class="feather icon-trash"></i>
                                                                </button>
                                                            </h6>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </article>
                            </form>

                        </div>
                        <div class="col-md-12">
                            <form [formGroup]="schoolFormAddress">
                                <article>
                                    <h6 class="modal-title titleSetting addresses">
                                        Direcciones
                                    </h6>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label class="form-control-label" for="address_type">Tipo
                                                    Direcci&oacute;n</label>
                                                <select class="form-control" name="address_type" formControlName="address_type"
                                                        required
                                                        [ngClass]="{'is-invalid': schoolFormAddress.get('address_type').touched && schoolFormAddress.get('address_type').hasError('required')}">
                                                    <option value="HOME">Casa</option>
                                                    <option value="WORK">Trabajo</option>
                                                    <option value="OTHER">Otro</option>
                                                </select>
                                                <label
                                                        *ngIf="schoolFormAddress.get('address_type').touched && schoolFormAddress.get('address_type').hasError('required')"
                                                        id="validation-address-type-error"
                                                        class="error jquery-validation-error small form-text invalid-feedback">
                                                    Seleccione un tipo de direcci&oacute;n.
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label" for="address">Direcci&oacute;n</label>
                                                <input type="text" class="form-control"
                                                       placeholder="Ingrese una direcci&oacute;n" name="addres1"
                                                       formControlName="address" required
                                                       [ngClass]="{'is-invalid': schoolFormAddress.get('address').touched && schoolFormAddress.get('address').hasError('required')}">
                                                <label
                                                        *ngIf="schoolFormAddress.get('address').touched && schoolFormAddress.get('address').hasError('required')"
                                                        id="validation-address-error"
                                                        class="error jquery-validation-error small form-text invalid-feedback">
                                                    Ingrese una direcci&oacute;n v&aacute;lida.</label>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <button type="button" uiSref="work" class="btn btn-sm btn-glow-success btn-success"
                                                    style="margin-top: 36px;" (click)="addAddress(this.schoolFormAddress.get('address_type'), this.schoolFormAddress.get('address'), 'school')">
                                                Agregar
                                            </button>
                                        </div>
                                    </div>
                                    <h6 class="modal-title titleSetting addresses">
                                        Listado de direcciones
                                    </h6>
                                    <div class="col-sm-12 centered-content">
                                        <table class="table">
                                            <thead>
                                            <tr>
                                                <th>Tipo</th>
                                                <th>Direcci&oacute;n</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <ng-container *ngFor="let address of addresses">
                                                <tr>
                                                    <td>
                                                        <h6 *ngIf="address.type === 'HOME'" class="m-0">Casa</h6>
                                                        <h6 *ngIf="address.type === 'WORK'" class="m-0">Trabajo</h6>
                                                        <h6 *ngIf="address.type === 'OTHER'" class="m-0">Otro</h6>
                                                    </td>
                                                    <td>
                                                        <h6 class="m-0">{{address.value}}</h6>
                                                    </td>
                                                    <td>
                                                        <h6 class="m-0">
                                                            <button type="button" class="btn btn-icon btn-outline-danger"
                                                                    ngbTooltip="Eliminar" (click)="deleteAddress(address, 'school')">
                                                                <i class="feather icon-trash"></i>
                                                            </button>
                                                        </h6>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                            </tbody>
                                        </table>
                                    </div>
                                </article>

                            </form>
                        </div>
                    </div>
                </form>
            </section>
            <section *ngIf="tabActive ===  'customer'">
                <form [formGroup]="customerForm">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-label">Nit</label>
                                <input
                                        type="text"
                                        class="form-control"
                                        formControlName="customer_tax_number"
                                        placeholder="Nit Cliente" required
                                        [ngClass]="{'is-invalid': (customerForm.get('customer_tax_number').touched && customerForm.get('customer_tax_number').hasError('required')) || (customerForm.get('customer_tax_number').touched && !customerForm.get('customer_tax_number').valid)}">
                                <label
                                        *ngIf="(customerForm.get('customer_tax_number').touched && customerForm.get('customer_tax_number').hasError('required')) || (customerForm.get('customer_tax_number').touched && !customerForm.get('customer_tax_number').valid)"
                                        class="error jquery-validation-error small form-text invalid-feedback">
                                    Ingrese un nit.
                                </label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-label">Nombre</label>
                                <input
                                        type="text"
                                        class="form-control"
                                        formControlName="customer_name"
                                        placeholder="Nombres" required
                                        [ngClass]="{'is-invalid': (customerForm.get('customer_name').touched && customerForm.get('customer_name').hasError('required')) || (customerForm.get('customer_name').touched && !customerForm.get('customer_name').valid)}">
                                <label
                                        *ngIf="(customerForm.get('customer_name').touched && customerForm.get('customer_name').hasError('required')) || (customerForm.get('customer_name').touched && !customerForm.get('customer_name').valid)"
                                        class="error jquery-validation-error small form-text invalid-feedback">
                                    Ingrese un nombre v&aacute;lido.
                                </label>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <form [formGroup]="customerFormPhones">
                                <article>
                                    <h6 class="modal-title titleSetting addresses">
                                        Telefonos
                                    </h6>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label" for="customer_type">Tipo Tel&eacute;fono</label>
                                                <select class="form-control" name="customer_type" formControlName="customer_type"
                                                        required
                                                        [ngClass]="{'is-invalid': customerFormPhones.get('customer_type').touched && customerFormPhones.get('customer_type').hasError('required')}">
                                                    <option value="CELLPHONE">Celular</option>
                                                    <option value="HOME">Casa</option>
                                                    <option value="WORK">Trabajo</option>
                                                    <option value="OTHER">Otro</option>
                                                </select>
                                                <label
                                                        *ngIf="customerFormPhones.get('customer_type').touched && customerFormPhones.get('customer_type').hasError('required')"
                                                        id="validation-customer-type-error"
                                                        class="error jquery-validation-error small form-text invalid-feedback">
                                                    Seleccione un tipo de tel&eacute;fono.
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label class="form-control-label" for="phone_number">Tel&eacute;fono</label>
                                                <div class="d-flex">
                                                    <select class="form-control mr-1" id="customer_code"
                                                            formControlName="customer_code" required
                                                            [ngClass]="{'is-invalid': customerFormPhones.get('customer_code').touched && customerFormPhones.get('customer_code').hasError('required')}">
                                                        <option *ngFor="let country of countries" [value]="country.code">
                                                            {{country.countryCode}} {{country.code}}</option>
                                                    </select>
                                                    <input type="text" class="form-control" placeholder="00000000"
                                                           id="customer_number" name="customer_number" formControlName="customer_number"
                                                           required maxlength="8"
                                                           [ngClass]="{'is-invalid': customerFormPhones.get('customer_number').touched && customerFormPhones.get('customer_number').hasError('required')}">
                                                </div>
                                                <label
                                                        *ngIf="customerFormPhones.get('customer_number').touched && customerFormPhones.get('customer_number').hasError('required')"
                                                        id="validation-customer-error"
                                                        class="error jquery-validation-error small form-text invalid-feedback">
                                                    Ingrese un tel&eacute;fono.
                                                </label>
                                                <label
                                                        *ngIf="customerFormPhones.get('customer_number').touched && customerFormPhones.get('customer_number').hasError('pattern')"
                                                        id="validation-customer-number-error"
                                                        class="error jquery-validation-error small form-text invalid-feedback">
                                                    ingresar un numero valido.
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <button type="button" uiSref="work" class="btn btn-sm btn-glow-success btn-success"
                                                    style="margin-top: 36px;" (click)="addPhone(this.customerFormPhones.get('customer_type'),this.customerFormPhones.get('customer_number'),this.customerFormPhones.get('customer_code'), 'customer' )">
                                                Agregar
                                            </button>
                                        </div>
                                    </div>
                                </article>
                                <article>
                                    <h6 class="modal-title titleSetting addresses">
                                        Listado de Telefonos
                                    </h6>
                                    <div class="col-sm-12 centered-content">
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                <tr>
                                                    <th>Tipo</th>
                                                    <th>Tel&eacute;fono</th>
                                                    <th></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <ng-container *ngFor="let phone of CustomerPhones">
                                                    <tr>
                                                        <td>
                                                            <h6 *ngIf="phone.type.toUpperCase() === 'CELLPHONE'" class="m-0">
                                                                Celuar</h6>
                                                            <h6 *ngIf="phone.type.toUpperCase() === 'HOME'" class="m-0">Casa
                                                            </h6>
                                                            <h6 *ngIf="phone.type.toUpperCase() === 'WORK'" class="m-0">Trabajo
                                                            </h6>
                                                            <h6 *ngIf="phone.type.toUpperCase() === 'OTHER'" class="m-0">Otro
                                                            </h6>
                                                        </td>
                                                        <td>
                                                            <h6 class="m-0">{{phone.number}}</h6>
                                                        </td>
                                                        <td>
                                                            <h6 class="m-0">
                                                                <button type="button" class="btn btn-icon btn-outline-danger"
                                                                        ngbTooltip="Eliminar" (click)="deletePhone(phone, 'customer')">
                                                                    <i class="feather icon-trash"></i>
                                                                </button>
                                                            </h6>
                                                        </td>
                                                    </tr>
                                                </ng-container>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </article>
                            </form>

                        </div>
                        <div class="col-md-12">
                            <form [formGroup]="customerFormAddress">
                                <article>
                                    <h6 class="modal-title titleSetting addresses">
                                        Direcciones
                                    </h6>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label class="form-control-label" for="customer_address_type">Tipo
                                                    Direcci&oacute;n</label>
                                                <select class="form-control" name="customer_address_type" formControlName="customer_address_type"
                                                        required
                                                        [ngClass]="{'is-invalid': customerFormAddress.get('customer_address_type').touched && customerFormAddress.get('customer_address_type').hasError('required')}">
                                                    <option value="HOME">Casa</option>
                                                    <option value="WORK">Trabajo</option>
                                                    <option value="OTHER">Otro</option>
                                                </select>
                                                <label
                                                        *ngIf="customerFormAddress.get('customer_address_type').touched && customerFormAddress.get('customer_address_type').hasError('required')"
                                                        id="validation-customer-address-type-error"
                                                        class="error jquery-validation-error small form-text invalid-feedback">
                                                    Seleccione un tipo de direcci&oacute;n.
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="form-control-label" for="customer_address">Direcci&oacute;n</label>
                                                <input type="text" class="form-control"
                                                       placeholder="Ingrese una direcci&oacute;n" name="customer_address"
                                                       formControlName="customer_address" required
                                                       [ngClass]="{'is-invalid': customerFormAddress.get('customer_address').touched && customerFormAddress.get('customer_address').hasError('required')}">
                                                <label
                                                        *ngIf="customerFormAddress.get('customer_address').touched && customerFormAddress.get('customer_address').hasError('required')"
                                                        id="validation-customer-address-error"
                                                        class="error jquery-validation-error small form-text invalid-feedback">
                                                    Ingrese una direcci&oacute;n v&aacute;lida.</label>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <button type="button" uiSref="work" class="btn btn-sm btn-glow-success btn-success"
                                                    style="margin-top: 36px;" (click)="addAddress(this.customerFormAddress.get('customer_address_type'), this.customerFormAddress.get('customer_address'), 'customer')">
                                                Agregar
                                            </button>
                                        </div>
                                    </div>
                                    <h6 class="modal-title titleSetting addresses">
                                        Listado de direcciones
                                    </h6>
                                    <div class="col-sm-12 centered-content">
                                        <table class="table">
                                            <thead>
                                            <tr>
                                                <th>Tipo</th>
                                                <th>Direcci&oacute;n</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <ng-container *ngFor="let address of CustomerAddresses">
                                                <tr>
                                                    <td>
                                                        <h6 *ngIf="address.type === 'HOME'" class="m-0">Casa</h6>
                                                        <h6 *ngIf="address.type === 'WORK'" class="m-0">Trabajo</h6>
                                                        <h6 *ngIf="address.type === 'OTHER'" class="m-0">Otro</h6>
                                                    </td>
                                                    <td>
                                                        <h6 class="m-0">{{address.value}}</h6>
                                                    </td>
                                                    <td>
                                                        <h6 class="m-0">
                                                            <button type="button" class="btn btn-icon btn-outline-danger"
                                                                    ngbTooltip="Eliminar" (click)="deleteAddress(address, 'customer')">
                                                                <i class="feather icon-trash"></i>
                                                            </button>
                                                        </h6>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                            </tbody>
                                        </table>
                                    </div>
                                </article>

                            </form>
                        </div>
                    </div>
                </form>
            </section>
        </article>

    </div>
</app-card>
<ng2-toasty [position]="position"></ng2-toasty>
