import {Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import { ActivityRequest } from "../../../../api/client/data-contracts";
import { ToastOptions, ToastyService } from "ng2-toasty";
import { StorageService } from "../../../../api/service/storage.service";
import { Service } from "../../../../api/service/Service";
import { FormContentComponent } from "../form-content.component";
import { CustomDateParserFormatter } from "../../../util/dateFormatterService"
import { NgbDateParserFormatter, NgbDateStruct, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import {ActivityType} from '../../../../api/utils/Constants';
import { ScreenLoadingComponent } from '../../../shared/screenLoading/screenLoading.component';

const permittedFiles = [
    'video/mp4',
    'video/quicktime',
    'application/pdf'
];

@Component({
    selector: 'app-form-activities',
    templateUrl: './form-activities.component.html',
    styleUrls: ['./form-activities.component.scss'],
    providers: [
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ]
})
export class FormActivitiesComponent implements OnInit {
    @ViewChild(ScreenLoadingComponent) screenLoading: ScreenLoadingComponent;
    @Input() dataUpdate: {
        id: string,
        title: string,
        description: string,
        type: string,
        start_at: string,
        end_at: string,
        start_time: string,
        end_time: string,
        score: number
    };
    taskType = ActivityType;
    position = 'top-center';
    formInput: ActivityRequest;
    documentAttached: any;
    message = "El video se esta subiendo, no cerrar esta pantalla!";
    form: any;
    isSubmit: boolean;
    isMeet: boolean;
    isTask: boolean;
    isVideo: boolean;
    isDocument: boolean;
    isSubmitSecuity: boolean;
    isUpdate: boolean;
    startDate: NgbDateStruct;
    endDate: NgbDateStruct;
    minStartDate: NgbDateStruct;
    loading: boolean = false;

    constructor(
        @Inject(FormContentComponent) private formContentComponent: FormContentComponent,
        private storageService: StorageService,
        private config: NgbDatepickerConfig,
        private toastyService: ToastyService,
    ) {
        this.isSubmit = false;
        this.isSubmitSecuity = false;
        this.isMeet = false;
        this.isTask = false;
        this.isVideo = false;
        this.isDocument = false;
        const today = new Date();
        this.minStartDate = { year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() };
        const current = new Date();
        config.minDate = { year: current.getFullYear(), month:
                current.getMonth() + 1, day: current.getDate() };
        //config.maxDate = { year: 2099, month: 12, day: 31 };
        config.outsideDays = 'hidden';
    }

    ngOnInit() {
        this.initializeForm();
    }


    public validateContact: (MovingDirection) => boolean = (form: any) => {
        if (!form.valid) {
            this.isSubmit = true;
            return false;
        } else {
            return true;
        }
    }

    changeType(): void {
        switch (this.formInput.type) {
            case 'MEET':
                this.isMeet = true;
                this.isTask = false;
                this.isDocument = false;
                this.isVideo = false;
                break;
            case 'TASK':
                this.isMeet = false;
                this.isTask = true;
                this.isDocument = false;
                this.isVideo = false;
                break;
            case 'WATCH_VIDEO':
                this.isMeet = false;
                this.isTask = false;
                this.isDocument = false;
                this.isVideo = true;
                break;
            case 'READ_DOCUMENT':
                this.isMeet = false;
                this.isTask = false;
                this.isDocument = true;
                this.isVideo = false;
                break;
            default:
                this.isMeet = false;
                this.isTask = false;
                this.isDocument = false;
                this.isVideo = false;
                break;
        }
    }

    selectDocument($event): void {
        this.toastyService.clearAll();
        let toastOptions: ToastOptions = {
            title: "Espere",
            msg: "Guardando...",
            timeout: 5000,
            theme: "default"
        };

        const file = $event.target.files[0];

        this.documentAttached = $event;

        if (!permittedFiles.includes(file.type)) {
            this.toastyService.clearAll();
            this.documentAttached = '';
            const toastOptions: ToastOptions = {
                title: "Error",
                msg: 'Tipo de archivo no permitido.',
                timeout: 5000,
                theme: "default"
            };
            this.toastyService.error(toastOptions);
        }
    }

    uploadDocument(idActivity): void {
        const service = new Service(this.storageService);
        const file = this.documentAttached.target.files[0];

        service.addDocumentActivity(file, this.formInput.title, this.formInput.description, idActivity)
            .then(async () => {
                this.screenLoading.off();
                this.formContentComponent.closeModalFormActivities();
                this.toastyService.clearAll();
                this.documentAttached = '';
                this.isMeet = false;
                this.isTask = false;
                this.isDocument = false;
                this.isVideo = false;
                // tslint:disable-next-line:no-shadowed-variable
                const toastOptions: ToastOptions = {
                    title: "Exito",
                    msg: "Video subido exitosamente!",
                    timeout: 3000,
                    theme: "default"
                };
                this.toastyService.info(toastOptions);
            })
            .catch(reason => {
                this.screenLoading.off();
                this.toastyService.clearAll();
                const toastOptions: ToastOptions = {
                    title: "Error",
                    msg: reason.message,
                    timeout: 5000,
                    theme: "default"
                };
                this.toastyService.error(toastOptions);
            });
    }

    initializeForm() {
        this.formInput = {
            title: '',
            description: '',
            type: '',
            start_at: '',
            end_at: '',
            start_time: '',
            end_time: '',
            score: 0,
        };
    }

    setMessage(): void{
        switch (this.formInput.type) {
            case 'MEET':
                this.message = 'La Reunion se esta creado, no cerrar esta pantalla!';
                break;
            case 'TASK':
                this.message = 'La Tarea se esta creado, no cerrar esta pantalla!';
                break;
            case 'WATCH_VIDEO':
                this.message = 'El Videoo se esta subiendo, no cerrar esta pantalla!';
                break;
            case 'READ_DOCUMENT':
                this.message = 'El Documento se esta subiendo, no cerrar esta pantalla!';
                break;
            default:
                this.message = 'Procesando, no cerrar esta pantalla!';
                break;
        }
    }

    async save(form: any) {
        this.loading = true;
        if (form.valid) {
            this.setMessage();
            this.screenLoading.on();
            this.toastyService.clearAll();
            var toastOptions: ToastOptions = {
                title: "Espere",
                msg: "Guardando registro",
                timeout: 5000,
                theme: "default"
            };
            this.toastyService.wait(toastOptions);
            const service = new Service(this.storageService);
            this.formInput.end_at = [this.endDate.day, this.endDate.month, this.endDate.year].join('-');
            this.formInput.end_time = this.formInput.end_time + ':00';

            if (this.formInput.type === 'MEET'){
                this.formInput.score = 1;
            }

            await service.createActivitie(this.formInput, this.formContentComponent.id).then(value => {
                this.toastyService.clearAll();
                // @ts-ignore
                if (this.formInput.type === 'WATCH_VIDEO' || this.formInput.type === 'READ_DOCUMENT') {
                    this.uploadDocument(value['id']);
                } else {
                    this.screenLoading.off();
                    this.formContentComponent.closeModalFormActivities();
                }

                var toastOptions: ToastOptions = {
                    title: "Exito",
                    msg: "Actividad creada exitosamente.",
                    timeout: 3000,
                    theme: "default"
                };

                this.toastyService.info(toastOptions);
                // @ts-ignore
                this.formContentComponent.updateDataActivities(this.formInput.parent_space_id);

                this.initializeForm();
                this.loading = false;
            }).catch(reason => {
                this.screenLoading.off();
                this.saveError(reason.message);
                this.loading = false;
            });
        }else {
            this.loading = false;
            this.toastyService.clearAll();
            this.toastyService.error({
                title: 'Error',
                msg: 'Completar todos los campos obligatorios!',
                timeout: 5000,
                theme: 'default'
            });
        }
    }

    saveError(error) {
        this.toastyService.clearAll();
        var toastOptions: ToastOptions = {
            title: "Error",
            msg: error,
            timeout: 5000,
            theme: "default"
        };
        this.toastyService.error(toastOptions);
    }

}
