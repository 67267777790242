<app-navigation style="z-index: 10;" class="pcoded-navbar"
  [ngClass]="{'navbar-collapsed' : navCollapsed, 'theme-horizontal': this.dattaConfig['layout'] === 'horizontal', 'mob-open' : navCollapsedMob}"
  (onNavCollapse)="this.navCollapsed = !this.navCollapsed;" (onNavCollapsedMob)="navMobClick()"></app-navigation>
<app-nav-bar class="navbar pcoded-header navbar-expand-lg navbar-light" (onNavCollapsedMob)="navMobClick()">
</app-nav-bar>
<div class="pcoded-main-container">
  <div class="pcoded-wrapper">
    <div class="pcoded-content">
      <div class="pcoded-inner-content">
        <app-breadcrumb style="z-index: 250;"></app-breadcrumb>
        <div class="main-body">
          <div class="page-wrapper">
            <router-outlet></router-outlet>
            <div id="notifier-div">
              <app-uploads-notifier *ngIf="showNotifier"></app-uploads-notifier>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-configuration></app-configuration>
