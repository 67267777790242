import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastOptions, ToastyService } from 'ng2-toasty';
import { ContentAlertCommentRequest, ContentAlertResponse, MediaAsset } from 'src/api/client/data-contracts';
import { Service } from 'src/api/service/Service';
import { StorageService } from 'src/api/service/storage.service';
import { MediaPreviewModalComponent } from 'src/app/theme/shared/components/media-preview-modal/media-preview-modal.component';
import {FILES_NOT_PREVEW} from '../../../api/utils/Constants';

@Component({
  selector: 'app-alert-info',
  templateUrl: './alert-info.component.html',
  styleUrls: ['./alert-info.component.scss']
})
export class AlertInfoComponent implements OnInit, OnDestroy {

  form: FormGroup;
  commentsLog: any = [];
  contentCommentsLog: any = [];
  contentCreator: string;
  contentAlert: {
    alert?: any,
    contentType?: string,
  } = {};
  attachment: MediaAsset;

  @ViewChild('modalConfirmationDownload', { static: true }) modalConfirmationDownload: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public alert: ContentAlertResponse,
    private dialogRef: MatDialogRef<AlertInfoComponent>,
    private storageService: StorageService,
    private toastyService: ToastyService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    ) { }

  ngOnInit() {
    this.buildForm();
    this.renderCommentsLog();
    this.getContentInfo();
  }

  ngOnDestroy(): void {
    this.form.reset();
  }

  getContentInfo() {
    const service = new Service(this.storageService);
    switch (this.alert.content_type) {
      case 'ACTIVITY':
        //@ts-ignore
        service.getActivity(this.alert.content.id, this.alert.created_by.id).then(async activity => {
          this.contentAlert.alert = activity;
          this.contentAlert.contentType = 'Actividad';
          this.renderContentCommentsLog();
          //@ts-ignore
          const owner = await service.getOneUser(activity.created_by as string);
          //@ts-ignore
          this.contentCreator = owner.full_name;
        });
        break;
      case 'MESSAGE':
        //@ts-ignore
        service.getMessageById(this.alert.content.id).then(message => {
          this.contentAlert.alert = message;
          this.contentAlert.contentType = 'Mensaje';
          this.contentCreator = message.sender.name
        });
        break;
      case 'RESOURCE':
        //@ts-ignore
        service.getOneResource(this.alert.content.id).then(async resource => {
          this.contentAlert.alert = resource;
          this.contentAlert.contentType = 'Recurso';
          //@ts-ignore
          const owner = await service.getOneUser(resource.created_by as string);
          //@ts-ignore
          this.contentCreator = owner.full_name;
        });
        break;
    }
  }

  buildForm() {
    this.form = this.fb.group({
      new_comment: [''],
      status: [this.alert.status, [Validators.required]]
    });
  }

  async renderCommentsLog() {
    if (this.alert.comments?.length > 0) {
      const service = new Service(this.storageService);
      this.commentsLog = await Promise.all(
        this.alert.comments.map(async comment => {
          const owner = await service.getOneUser(comment.created_by as string);
          return {
            ...comment,
            //@ts-ignore
            created_by: owner.full_name,
          }
        })
      );
      this.commentsLog.reverse();
    }
    return;
  }

  async renderContentCommentsLog() {
    if (this.contentAlert.alert.comments?.length > 0) {
      const service = new Service(this.storageService);
      this.contentCommentsLog = await Promise.all(
        this.contentAlert.alert.comments.map(async comment => {
          const owner = await service.getOneUser(comment.created_by as string);
          return {
            ...comment,
            //@ts-ignore
            created_by: owner.full_name,
          }
        })
      );
      this.contentCommentsLog.reverse();
    } else {
      this.contentCommentsLog = [];
    }
    return;
  }

  updateAlert() {
    this.toastyService.clearAll();
    var toastOptions: ToastOptions = {
      title: "Espere",
      msg: "Guardando registro",
      timeout: 5000,
      theme: "default"
    };
    this.toastyService.wait(toastOptions);
    const service = new Service(this.storageService);
    let statusChange;
    const status = this.form.get('status').value;
    switch (status) {
      case 'CREATED':
        statusChange = 'Iniciado';
        break;
      case 'PENDING':
        statusChange = 'Pendiente';
        break;
      case 'REVIEW':
        statusChange = 'En revisión';
        break;
      case 'RESOLVED':
        statusChange = 'Resuelto';
        break;
      case 'VOIDED':
        statusChange = 'Desestimado';
        break;
      default:
        break;
    }
    const updateToAlert: ContentAlertCommentRequest = {
      comment: this.form.get('new_comment').value + `\n| Se cambió estado a: ${statusChange}`,
      status,
    }
    service.updateContentAlert(this.alert.id, updateToAlert).then(res => {
      this.toastyService.clearAll();
      var toastOptions: ToastOptions = {
        title: "Exito",
        msg: "Alerta actualizada exitosamente.",
        timeout: 3000,
        theme: "default"
      };
      this.toastyService.info(toastOptions);
      this.closeModal(true);
    }).catch(reason => {
      this.toastyService.clearAll();
      var toastOptions: ToastOptions = {
        title: "Error",
        msg: reason.message,
        timeout: 5000,
        theme: "default"
      };
      this.toastyService.error(toastOptions);
    });
  }

  openFilePreview(attachment: MediaAsset) {
    if( FILES_NOT_PREVEW[attachment.type]){
      this.attachment = attachment;
      // @ts-ignore
      this.modalConfirmationDownload.show();
    }else {
      this.dialog.open(MediaPreviewModalComponent, {
        data: attachment,
        height: '85%',
        width: '75%',
        autoFocus: false,
      }).afterClosed().subscribe(() => {
        //TODO If action needed after closed
      });
    }
  }

  downloadAttachment(attachment: MediaAsset, close: boolean) {
    if ( close ){
      // @ts-ignore
      this.modalConfirmationDownload.hide();
    }
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', attachment.href);
    link.setAttribute('download', `${attachment.file_name == null ? 'archivo' : attachment.file_name}`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  closeModal(updated: boolean = false) {
    this.dialogRef.close(updated);
  }

}
