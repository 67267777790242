<div class="app-modal-header mb-1 border-bottom">
    <h5 class="modal-title mb-2">
        Por favor espere mientras es redirigido...
    </h5>
</div><div class="app-modal-body">
    <div class="card-body d-flex flex-row justify-content-center mt-5">
        <div class="spinner-border text-info" role="status">
            <span class="sr-only"></span>
        </div>
    </div>
</div>